import { appApi } from "../apis/apiSlice";

const creditsApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["STEPPER"],
});

export const stepperApi = creditsApiWithTag.injectEndpoints({
  endpoints: (builder) =>  ({
    getProfileStepper: builder.query<any, any>({
      query: () => ({
        url: '/user/stepper', 
        method: 'GET', 
      })
    }), 
  getProfileStepperWithToken: builder.query<any, any>({
      query: (token) => ({
        url: '/user/stepper', 
        method: 'GET', 
        Authorization: `Token ${token}`
      })
    })
  }), 
});

export const {
  useGetProfileStepperQuery, 
  useLazyGetProfileStepperQuery,
} = stepperApi;
