import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const TabContainer = styled(Box)`
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  @media (max-width: 767px) {
    padding: 0;
    display: block;
  }
`;

export const TabLink = styled(Link)`
  font-weight: 600;

  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  span{
    line-height: 21px;
    font-size: 14px;
    position: relative;
    top: 3px;
    margin-left: 5px;
  }
  &.completed&.active{
   color: #F6911E !important;
   path {
    fill: #f6911e !important;
  }
  }

  &.completed path {
    fill: #01B9AF !important;
  }
  &.active path,
  &:hover path {
    fill: #f6911e;
  }
  @media(max-width: 767px){
    span{
      line-height: 18px;
      font-size: 11px;
    }
  }
`;
