import React from "react";
import { Box, Typography } from "@mui/material";
import { StyledOverLayBlock } from "./styles";

interface SideBannerProps {
  title: string;
  content: string;
}

function SideBanner({ title, content }: SideBannerProps): JSX.Element {
  return (
    <>
        <StyledOverLayBlock sx={{width:'100%',marginTop:"auto"}}>
          <Box textAlign="center" className="info-block"  sx={{color:'primary.contrastText',marginTop:{ xs:'3rem',md:'auto'},mb:6,p:4,pt:5,width:{xs:'100%',md:'calc(100% - 48px)'}}}>
            <Typography variant="h2" sx={{mb:2}}>{title}</Typography>
            <Typography variant="body1" sx={{color:'primary.contrastText'}}>{content}</Typography>
          </Box>
      </StyledOverLayBlock>
    </>
  );
}

export default SideBanner;
