import { Box, Card, CardContent, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../../../types/routes";
import { useState } from "react";
import AlertDialog from "../../AlertDialog";
import { useToast } from "../../../hooks/useToast";
import { useDeletePetMutation } from "../../../redux/pet/petApi";
import { Media } from "../../../config/media";

type PetListCardPropsType = {
  imageUrl: string;
  name: string;
  petId: number;
  scheduleId: number | undefined;
};

const PetListCard: React.FC<PetListCardPropsType> = ({
  imageUrl,
  name,
  petId,
  scheduleId,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [deletePet, { isLoading }] = useDeletePetMutation();

  const handleEditNavigation = () => {
    navigate(`/my-account/edit-pet/${petId}`, {
      state: {
        path: GlobalRoutes.ACCOUNT_MY_PETS,
        refetch: true,
      },
    });
  };

  const handleDelete = async (id: number) => {
    try {
      const res = await deletePet(id);
      //@ts-ignore
      if (res.error) {
        throw new Error("Unable to delete pet at the moment");
      } else {
        toast({
          title: "Pet deleted successfully",
        });
      }
    } catch (err: any) {
      toast({
        title: "Unable to delete pet at the moment",
        severity: "error",
      });
    }
    setShowDialog(false);
  };

  const handleEditScheduleNavigation = () => {
    navigate(
        GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE, 
      {
        state: {
          refetch: true,
          scheduleId, 
          petId: scheduleId ? null : petId,
        },
      }
    );
  };

  return (
    <Box>
      <Paper
        sx={{
          boxShadow: "box-shadow: 0px 4px 13px 0px #AFAFAF4D",
        }}
      >
        <Card sx={{ width: "100%", height: "100%", p: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              onClick={handleEditNavigation}
              sx={{
                textDecoration: "underline",
                fontWeight: "600",
                cursor: "pointer",
                fontStyle: "italic",
              }}
            >
              Edit
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "info.lighter",
              margin: "0 auto",
              width: "134px",
              height: "134px",
              borderRadius: "50%",
            }}
          >
            <Box
              component="img"
              sx={{
                borderRadius: "50%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "top",
              }}
              src={imageUrl}
              onError={(e) => {
                e.currentTarget.src = Media.NoImage;
              }}
            />
          </Box>
          <CardContent sx={{ p: "0 !important" }}>
            <Typography variant="h3" sx={{ textAlign: "center", mt: 2 }}>
              {name}
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                textDecoration: 'underline',
                fontWeight:'600',
                fontStyle:'italic',
                color:'primary.main',
                cursor:'pointer'
              }}
            >
              <Box
                component="span"
                onClick={handleEditScheduleNavigation}
                sx={{
                  textAlign: "center",
                  textDecoration: "underline",
                  fontWeight: "600",
                  fontStyle: "italic",
                  color: "primary.main",
                }}
                className="text-sm"
              >
                {scheduleId ? "Edit" : "Add"} Schedule
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
      {showDialog && (
        <AlertDialog
          open={showDialog}
          handleClose={() => setShowDialog(false)}
          handleCancel={() => setShowDialog(false)}
          handleConfirm={() => handleDelete(petId)}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default PetListCard;
