import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { StyledCurveBlock } from "./styles";
import ChangePasswordForm from "../../../components/auth/ChangePasswordForm";

function ChangePassword() {
  return (
    <StyledCurveBlock>
      <Box
        sx={{
          position: "relative",
          paddingTop: "4rem",
          paddingBottom: { xs: "4rem", lg: "8rem" },
          bgcolor: "secondary.light",
        }}
        className="curve-bg"
      >
        <Container
          fixed
          maxWidth="xl"
          sx={{ position: "relative", zIndex: "1" }}
        >
          <Grid container spacing={{ xs: 3, lg: 15 }}>
            <Grid item xs={12} md={6}>
              <ChangePasswordForm title="Change Password" />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: { xs: "left", md: "right" } }}
            ></Grid>
          </Grid>
        </Container>
      </Box>
    </StyledCurveBlock>
  );
}

export default ChangePassword;
