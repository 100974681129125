import { createSlice } from "@reduxjs/toolkit";
import { IPetDetails } from "../../interfaces/pet";

interface initialState {
  pets: IPetDetails[];
}

const INITIAL_STATE: initialState = {
    pets: [] as IPetDetails[],
  };


  export const userStore = createSlice({
    name: "pets",
    initialState:INITIAL_STATE,
    reducers: {
    
      setPetData: (state, action) => {
        return { ...state.pets, ...action.payload };
      },
    
    },
  })

  export const { setPetData} = userStore.actions;

export default userStore.reducer;