
export const dailyDefaultSchedule: {[key: string]: string} = {
    monday: 'MORNING', 
    tuesday: 'MORNING', 
    wednesday: 'MORNING', 
    thursday: 'MORNING', 
    friday: 'MORNING', 
    saturday: 'MORNING', 
    sunday: 'MORNING',
}

export const weeklyDefaultSchedule: {[key: string]: string} = {
    monday: 'MORNING,EVENING', 
    tuesday: 'AFTERNOON', 
    wednesday: 'MORNING,EVENING', 
    thursday: 'AFTERNOON', 
    friday: 'MORNING,EVENING', 
    saturday: '', 
    sunday: '',
}