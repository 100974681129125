import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAboutBlock = styled(Box)`
.editor-container {
  .shape-right{
    display:none;
  }
}
  .about-img {
    top: 40px;
    object-position: left;
  }
  .about-content {
    z-index: 1;
  }
  .shape-top {
    position: absolute;
    right: 0;
    top: 0;
    width: 130px;
    height: 130px;
  }
  .shape-right {
    width: 279px;
    height: 284px;
    position: absolute;
    bottom: -63px;
    right: 0;
  }
  .about-secondary .shape-top {
    left: 0;
    right: auto;
  }
  .about-secondary {
    background-color: #e6f8f7;
  }

  .about-secondary .about-img {
    object-position: right;
  }

  .about-secondary .shape-right {
    left: 11px;
    right: auto;
    z-index: -1;
    bottom: -41px;
  }
  @media (min-width: 899px) {
    .about-secondary .MuiGrid-item:nth-child(1) {
      order: 2;
    }
    .about-secondary .MuiGrid-item:nth-child(1) {
      order: 1;
    }
  }
  @media (min-width: 1200px) {
    .introsection-style {
      p {
        padding-right: 6rem;
      }
    }
    .about-secondary .shape-top {
      left: 23%;
    }
    .shape-top {
      right: 23%;
    }
  }
  @media (min-width: 1500px) {
    .about-secondary .shape-top {
      left: 37%;
    }
    .shape-top {
      right: 33%;
    }
  }
  @media (max-width: 899px) {
    .about-img {
      height: auto !important;
    }
    .shape-right {
      bottom: -25px !important;
      right: 0 !important;
      width: 168px;
      height: 168px;
      left: auto !important;
    }
    .shape-top {
      width: 78px;
      height: 78px;
      bottom: -54px;
      top: auto;
      left: 8px !important;
      right: auto !important;
    }
  }
`;
