import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import SideBanner from "../../../components/auth/SideBanner";
import { Message } from "../../../config/messages";
import RegisterForm from "../../../components/auth/RegisterForm/layout";
import SideImgBanner from "../../../components/SideImgBanner";
function Register() {
  return (
    <Box className="main">
      <Container fixed maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            className="login-info"
            sx={{ position: { xs: "relative", sm: "static" },minHeight:{lg:'900px'},display:'flex' }}
          >
          <SideImgBanner/>
            <SideBanner
              title={Message.LoginTitle}
              content={Message.RegisterContent}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className="login-wrap"
              sx={{
                paddingLeft: { md: "4rem" },
                paddingTop: { xs: "2rem", sm:"6rem", lg:"7rem" },
                paddingBottom: { xs: "2rem", md: "7rem" },
              }}
            >
              <Typography variant="h1" sx={{ mb: 2 }}>
                {Message.Register}
              </Typography>
              <RegisterForm title={Message.Register} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Register;
