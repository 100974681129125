import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { GlobalRoutes } from "../../../types/routes";
import { useFormik, FormikProps } from "formik";
import { loginSchema } from "../../../validators/schemas/authSchemas";
import { LoadingButton } from "@mui/lab";
import {
  useLoginUserMutation,
} from "../../../redux/auth/authApi";
import { useDispatch, useSelector, } from "react-redux";
import { loginSuccess } from "../../../redux/auth/authStore";
import { ILoginResponseInterface } from "../../../interfaces/auth";
import { Message } from "../../../config/messages";
import SocialButtons from "../../social";
import { openSnackbar } from "../../../redux/snackbar";
import { Stepper } from "../../../config/constants";
import { useLazyGetProfileStepperQuery } from "../../../redux/stepper/stepperApi";
import { setProfileCompleted } from "../../../redux/stepper/stepperStore";

interface LoginFormProps {
  title: string;
}

type MessageType = {
  text: string;
  type: null | "success" | "error" | "info";
};

function LoginForm({ title }: LoginFormProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  // State to manage user responses text (success or errors)
  const [message, setMessage] = useState<MessageType | null>();
  const [fetchStepperData] = useLazyGetProfileStepperQuery();

  const [loginUser, { isLoading }] = useLoginUserMutation();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }: 
  FormikProps<any> = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async ({ email, password }) => {
      try {
        setMessage(null);
        const { data }: ILoginResponseInterface = await loginUser({
          email,
          password,
        }).unwrap();
        if (data?.token) {
          dispatch(
            loginSuccess({
              isAuthenticated: true,
              token: data?.token,
              editable: data?.editable || false, 
            })
          );
          // Opening global toast, after successfully navigation
          dispatch(
            openSnackbar({
              open: true,
              title: Message.LoginSuccessfully,
              autoHideDuration: 10000,
            })
          );
          const stepperData = await fetchStepperData(data?.token);
          const completed = stepperData?.data?.data?.completed;
          if(stepperData) {
            dispatch(setProfileCompleted(completed));
          }
          if(completed < Stepper.MY_CREDIT) {
            handleNavigation(completed);
          } else {
            navigate(GlobalRoutes.HOME);
          }
        }
      } catch (err: any) {
        setMessage({
          text: err?.data?.message || Message.SomethingWrong,
          type: "error",
        });
      }
    },
  });

  function handleNavigation(completedCount: number) {
    let url = GlobalRoutes.EDIT_PROFILE; 
    if(completedCount < Stepper.PROFILE) {
      url = GlobalRoutes.EDIT_PROFILE; 
    } else if(completedCount < Stepper.MY_PETS) {
      url = GlobalRoutes.ACCOUNT_MY_PETS;
    } else if(completedCount < Stepper.SCHEDULE_MESSAGE) {
      url = GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE;
    } 
    navigate(url, {
      state: {
        message: true, 
      },
    });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const closeAlert = () => {
    setMessage(null);
  };

  const [isAutoFill, setIsAutoFill] = useState(false);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          {/* Alert for showing error messages */}
          {(message?.type === "error" || message?.type === "info") && (
            <Collapse in>
              <Alert
                sx={{ my: 2 }}
                variant="filled"
                severity={message.type === "error" ? "error" : "info"}
                onClose={closeAlert}
              >
                {message.text}
              </Alert>
            </Collapse>
          )}
          <FormControl
            sx={{ my: 1.5, width: "100%" }}
            variant="outlined"
            className={isAutoFill ? "autofill-input" : ""}
          >
            <TextField
              id="email"
              InputProps={{
                onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
                  e.animationName === "mui-auto-fill" && setIsAutoFill(true);
                },
                onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
                  e.animationName === "mui-auto-fill-cancel" &&
                  setIsAutoFill(false),
                onFocus: () => setIsAutoFill(false),
              }}
              label="Enter your email"
              variant="outlined"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              color={errors.email ? "error" : "primary"}
              error={!!(touched.email && errors.email)}
              helperText={
                touched.email &&
                errors.email &&
                typeof errors.email === "string" &&
                errors.email
              }
            />
          </FormControl>
          <FormControl
            sx={{ my: 1.5, width: "100%" }}
            variant="outlined"
            className={isAutoFill ? "autofill-input" : ""}
          >
            <InputLabel
              htmlFor="password"
              className={(errors.password && touched.password)? "error" : "primary"}
            >
              Enter your password
            </InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              color={(errors.password && touched.password) ? "error" : "primary"}
              error={!!(touched.password && errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Enter your password"
            />
            {touched.password && errors.password && (
              <FormHelperText error id="accountId-error">
                {errors.password.toString()}
              </FormHelperText>
            )}
          </FormControl>
          <Typography variant="body2" sx={{fontWeight:'600'}}>
          <Link to={GlobalRoutes.FORGOT_PASSWORD}>
          Forgot Password?</Link>
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: "100%", mt: 3, mb: 6 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ ml: 2 }} color="inherit" size={24} />
            ) : (
              `${title}`
            )}
          </LoadingButton>
        </form>
      </Box>

      <Typography variant="body2" textAlign="center" sx={{ mb: 4 }}>
        or {title} with
      </Typography>
      <Box>
        <SocialButtons />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="body2" mr={1}>
          Don’t have an account?{" "}
        </Typography>
   <Typography variant="body2" sx={{fontWeight:'700'}}>
        <Link to={GlobalRoutes.REGISTER} >
        Register Now!</Link>
        </Typography>
      </Box>
    </>
  );
}

export default LoginForm;
