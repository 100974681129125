import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPetDetails = styled(Box)`
  padding-top: 5rem;
  .MuiTypography-body2 {
    font-style: italic;
    color: #131c23;
  }
  h2 {
    text-transform: capitalize;
  }
  .MuiListItemIcon-root {
    min-width: 45px;
    position: relative;
    top: 6px;
  }
  .petCarousel-detail {
    h2 {
      display: block;
    }
  }
  .petCarousel {
    h2 {
      display: none;
    }
  }
  @media (max-width: 899px) {
    .petCarousel-detail {
      order: 2;
      h2 {
        display: none;
      }
    }
    .petCarousel {
      order: 1;
      h2 {
        display: block;
      }
    }
  }
 .MuiListItemIcon-root {
  display: flex;
  align-items: flex-start;
}
  li {
     display: flex;
  }
  img {
    display: inline-block;
    padding-right: 20px;
    vertical-align: sub;
  }
`;
