import React from "react";
import { Box, Typography , Accordion, AccordionSummary,AccordionDetails} from "@mui/material";
import { StyledAccordian } from "./styles";
import { Media } from "../../config/media";
interface IAccordianProps {
  accId: string;
  content: string;
  title: string;
}
function Accordian({title,content,accId}: IAccordianProps) {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };
  return (
    
<StyledAccordian>
<Accordion expanded={expanded === accId } onChange={handleChange(accId)}>
        <AccordionSummary
          aria-controls= {accId + "-content"}
          id= {accId + "-header"}
          sx={{pl:0}}
        >
          <Box sx={{display:'flex'}}> 
            <Box
                component="img"
                alt="The Icon"
                src={Media.AddIcon}
                className="img-style__contain add-cont icon-img"
                sx={{mr:{xs:3,sm:5,md:6,lg:8}}}
              />
                <Box
                component="img"
                alt="The Icon"
                src={Media.SubIcon}
                className="img-style__contain sub-cont icon-img"
                sx={{mr:{xs:3,sm:5,md:6,lg:8}}}
              />
               <Typography variant="body1">  {title}
          </Typography>
              </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2"  sx={{pl:{xs:3,sm:5,md:6,lg:8}}}> {content}</Typography>
        </AccordionDetails>
      </Accordion>
</StyledAccordian>
  );
}

export default Accordian;
