import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import React from "react";
import CustomSnackbar from "../CustomSnackbar";
import { closeSnackbar } from "../../redux/snackbar";


type  CustomToastPropsType = {};

const CustomToast: React.FC<CustomToastPropsType> = () => {
  const { open, title, severity, sx, autoHideDuration } =
    useSelector((state: RootState) => state.snackbar);
  const dispatch = useDispatch();


  const onClose = () => {
    dispatch(closeSnackbar({
      open: false, 
    }));
  }

  return (
      <CustomSnackbar
        open={open}
        onClose={onClose}
        title={title}
        severity={severity}
        autoHideDuration={autoHideDuration}
        sx={sx}
      />
  );
};

export default CustomToast;
