import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledImgContentBlock = styled(Box)`
  .testimonial__img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 1rem;
  }
  .testimonial__content{
    padding-left: 55px;
    position: relative;
    padding-right: 55px;
  }
  .testimonial__quote{
    width: 30px;
    height: 30px;
    position: absolute;
  }
  .testimonial__quote--left{
    top: 8px;
    left: -56px;
  }
  .testimonial__quote--right{
    bottom: 25px;
    right: -60px;
    transform: scale(-1);
  }
  .testimonial .testimonial__img {
    order: 2;
  }
  .testimonial .testimonial__content {
    order: 1;

  }

  @media(min-width:1200px){
    .testimonial__content{
      padding-left: 12rem;
    }
  }

  @media(min-width:900px){
    .testimonial .testimonial__quote--left{
      left: -89px;
    }
    .testimonial .testimonial__content {
      padding-right: 10rem;
      padding-left: 90px;
    }
    .testimonial__quote--left{
      left: -96px;
    }
    .testimonial__quote{
      width: 60px;
      height: 60px;
    }
    .testimonial__content{
      
      padding-left: 10rem;
    }
  }
`;