import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';

interface DataItem {
  id: number;
  name: string | null | undefined;
  order?: number | null;
  timezone?: string | null;
}

type PropsType = {
  query: any;
  setFieldValue: any;
  fieldName: string;
  value: string | undefined;
  valueString: string | undefined;
  setTouched: any;
  touched?: boolean | undefined;
  timezone?: string | undefined;
  setValidZip?: any;
  setFieldError?: any;
};

const otherObject = {
  id: 1,
  name: 'Other',
  order: -1,
};

const SearchableAutocomplete: React.FC<PropsType> = ({
  value,
  setFieldValue,
  fieldName,
  query,
  setTouched,
  valueString,
  timezone,
  setValidZip,
  setFieldError, 
  touched,
}) => {
  const [trigger, { data, isLoading }] = query();
  const [inputValue, setInputValue] = useState<string>('');
  const [defaultValue, setDefaultValue] = useState<DataItem | null>(null);
  const timeout = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [cityStateData, setCityStateData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (data?.data && inputValue) {
      if (!data?.data) {
        setCityStateData([otherObject]);
      } else {
        const fetchedData = data?.data?.slice(0, 200)?.filter(({ id }: any) => id !== 1);
        setCityStateData([...fetchedData, otherObject]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (value || valueString || timezone) {
      const obj = {
        id: Number(value),
        name: Number(value) === 1 ? 'Other' : valueString,
        timezone: timezone,
        order: null,
      };
      setDefaultValue(obj);
      setCityStateData([obj, otherObject]);
      setInputValue(valueString || '');
    }
  }, []);

  useEffect(() => {
    if (!inputValue) {
      setCityStateData([]);
      setFieldValue(fieldName, '');
      setFieldValue('other_state', '');
      return;
    }
    timeout.current = setTimeout(() => {
      setLoading(true);
      if (inputValue && inputValue !== defaultValue?.name) {
        try {
          trigger(inputValue);
        } catch (err: any) {
          // handle error
        }
      }
      setLoading(false);
    }, 100);
    return () => {
      if (timeout && timeout?.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [inputValue]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleChange = (_event: any, newValue: any) => {
    setDefaultValue(newValue);
    if(newValue?.key !== 1 && newValue?.zip_code) {
      setValidZip(newValue.zip_code);
      setFieldValue('zipCode', newValue.zip_code);
    }
    setFieldValue(fieldName, newValue?.id);
  };

  const showLoader = isLoading && loading;

  return (
    <Autocomplete
      fullWidth
      style={{ width: '100%' }}
      sx={{
        '.MuiInputBase-root': {
          padding: 0,
        },
      }}
      freeSolo
      options={cityStateData}
      renderOption={(props, option) => { 
        return <li {...props} key={option?.id}>
          <div>
            <span>{option?.name ? option.name : ''}</span>
          </div>
        </li>
      }}
      getOptionLabel={(option: any) => option?.name.toLowerCase().includes('other') ? 'Other': option.name}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={(options) => options}
      value={defaultValue}
      onChange={handleChange}
      loading={showLoader}
      onBlur={() => setTouched(fieldName, true)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="City and State"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
};

export default SearchableAutocomplete;
