import {
  Box,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useFormik, FormikProps } from "formik";
import { editUserSchema } from "../../../validators/schemas/user";
import { ArrowBack } from "@mui/icons-material";
import { ImageContainer, Label, UploadProfilePic } from "./styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../../../types/routes";
import {
  useFetchNeighbourhoodsQuery,
  useFetchResidencesQuery,
  useFetchUserQuery,
  useLazyFetchStateCitiesQuery,
  useUpdateUserMutation,
} from "../../../redux/user/userApi";
import { convertKeyToSnakeCase, toSnakeCase } from "../../../utils/helper";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { ReactNode, useEffect, useState } from "react";
import { useToast } from "../../../hooks/useToast";
import AboutSection from "../AboutSection";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../redux/user/userStore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RootState } from "../../../redux";
import StepperModal from "../../StepperModal";
import { Stepper } from "../../../config/constants";
import { setProfileCompleted } from "../../../redux/stepper/stepperStore";
import RequiredAsterisk from "../../RequiredAsterisk";
import { Media } from "../../../config/media";
import SearchableAutocomplete from "../../SearchableAutocomplete";

type EditUserFormPropsType = {};
type EditUserFormValuesType = {
  firstName: string;
  lastName: string;
  contactNumber: string;
  gender: string;
  email: string;
  profilePic: string;
  dateOfBirth: any;
  typeOfResidence: string;
  typeOfNeighborhood: string;
  address: string;
  city_state: string;
  zipCode: string;
  subscribed: boolean;
  other_state: string;
};

const EditProfileForm: React.FC<EditUserFormPropsType> = () => {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState<string>("");
  const toast = useToast();
  const dispatch = useDispatch();
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();
  const { data: residences } = useFetchResidencesQuery({});
  let { data: neighbourhoods, isLoading: fetchingNeighbourhoods } =
    useFetchNeighbourhoodsQuery({});
  const { data: userData } = useFetchUserQuery({});
  const [showAboutSection, setShowAboutSection] = useState<boolean>(false);
  // const { data: cityStateData } = useFetchStateCitiesQuery({});
  const { completed } = useSelector((state: RootState) => state.stepper);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [validAge, setValidAge] = useState<boolean>(true);
  const [validZip, setValidZip] = useState<string>('');
  const [isValidZip, setIsValidZip] = useState<boolean>(true);

  useEffect(() => {
    if (userData) {
      //Dispatching user data to the store to be used in the header for representation
      dispatch(setUserData(userData));
      if (userData?.address) {
        setShowAboutSection(false);
      } else {
        setShowAboutSection(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  //@ts-ignore
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError, 
  }: FormikProps<EditUserFormValuesType> = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      profilePic: "",
      gender: "M",
      dateOfBirth: "",
      typeOfResidence: "",
      typeOfNeighborhood: "",
      address: "",
      city_state: "",
      zipCode: "",
      subscribed: false, 
      other_state: '',
    },
    validationSchema: editUserSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (userData) {
      [
        "firstName",
        "lastName",
        "contactNumber",
        "email",
        "profilePic",
        "gender",
        "dateOfBirth",
        "typeOfResidence",
        "typeOfNeighborhood",
        "address",
        "city_state",
        "other_state",
        "zipCode",
        "subscribed"
      ]?.forEach((field: string) => {
        const key = convertKeyToSnakeCase(field as string) as string;
        if (["firstName", "lastName", "email"]?.includes(field)) {
          if (userData?.user[key]) {
            setFieldValue(field, userData?.user[key]);
          }
        } else {
          if (key === "profile_pic" && userData[key]) {
            setImgUrl(userData[key]);
          }
          if (key === "date_of_birth" && userData[key]) {
            setFieldValue(field, dayjs(userData[key]));
          }
          if (userData[key]) {
            setFieldValue(field, userData[key]);
          }
          if (key === 'contact_number' && userData[key]) {
            const val = `(${userData[key].slice(
              0,
              3
            )}) ${userData[key].slice(
              3,
              6
            )}-${userData[key].slice(6)}`;
            setFieldValue(field, val)
          }
          if(key === 'zip_code') {
            setValidZip(userData[key]);
            setFieldValue(field, userData[key]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, neighbourhoods, residences]);

  useEffect(() => {
    if ((touched.zipCode || values?.zipCode) && values?.city_state !== '1') {
      validateZip();
    } else {
      setIsValidZip(true);
      setFieldError('zipCode', '');
    }
    if(validZip === values.zipCode) {
      setFieldTouched('zipCode', false);
      setFieldError('zipCode', '');
    }
  }, [values.zipCode, validZip]);

  const getFormData = (obj: any) => {
    const formData = new FormData();
    Object.keys(obj)?.forEach((key) => {
      formData.append(key, obj[key]);
    });
    return formData;
  };

  async function submitHandler(data: EditUserFormValuesType) {
    if(!validAge) return;
    // Validate zip code
    if(!validateZip()) return;
    setFieldTouched("profilePic", true);
    try {
      let payload = toSnakeCase({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        contactNumber: data.contactNumber.replace(/\D/g, ''),
        profilePic: data.profilePic,
        gender: data.gender,
        dateOfBirth: dayjs(data.dateOfBirth)?.format("YYYY-MM-DD"),
        address: data.address,
        cityState: data.city_state,
        zipCode: data.zipCode,
        typeOfResidence: +data.typeOfResidence,
        typeOfNeighborhood: +data.typeOfNeighborhood,
        subscribed: +data.subscribed
      });
      if (typeof data?.profilePic === "string") {
        //@ts-ignore
        delete payload["profile_pic"];
      }
      if(Number(data.city_state) === 1 && payload) {
        //@ts-ignore
        payload['other_state'] = data.other_state as string;
      } else {
        //@ts-ignore
        payload['other_state'] = '';
      }
      const formData = getFormData(payload);
      const res = await updateUser(formData).unwrap();
      //@ts-ignore
      if (res?.error) {
        throw new Error();
      }
      dispatch(setUserData(res));
      if(completed < Stepper.PROFILE) {
        setShowModal(true);
        dispatch(setProfileCompleted(Stepper.PROFILE));
      }
      setTimeout(() => toast({
        title: "User Profile updated successfully",
        severity: "success",
      }), 800);
    } catch (err: any) {
      toast({
        title: "Unable to update user profile at the moment",
        severity: "error",
      });
    }
  }

  const handleSingleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched("profilePic", true);
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setProfilePicUrl(file);
        setFieldValue("profilePic", file);
      }
    }
  };

  const handleBack = () => {
    navigate(GlobalRoutes.ACCOUNT_PROFILE);
  };

  const setProfilePicUrl = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setImgUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check if the key pressed is an alphabet
    if (/[A-Za-z]/.test(event.target.value)) {
      // Prevent the default action if it's an alphabet
      event.preventDefault();
    } else {
      const maskedVal = formatPhoneNumber(event.target.value);
      setFieldValue('contactNumber', maskedVal);
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };


  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check if the key pressed is an alphabet
    if (/.*[A-Za-z!@#$%^&*(),.?":{}|<>].*/.test(event.target.value)) {
      // Prevent the default action if it's an alphabet
      event.preventDefault();
    } else {
      handleChange(event);
    }
  };

  const handleNext = () => {
    navigate(GlobalRoutes.ACCOUNT_MY_PETS);
  }

  const handleDateChange = (value: any) => {
    const selectedDate = dayjs(value);
    const today = dayjs();
    const age = today.diff(selectedDate, 'year');

    if (age >= 18) {
      setFieldValue(
        "dateOfBirth",
        dayjs(value).format("YYYY-MM-DD")
      );
      setValidAge(true);
    } else {
      setValidAge(false);
    }
  };

  const validateZip = () => {
    if (validZip) {
      if (validZip !== values.zipCode) {
        setIsValidZip(false);
        return false;
      } else {
        setIsValidZip(true);
        setFieldError('zipCode', '');
      }
    } else {
      setIsValidZip(true);
      setFieldError('zipCode', '');
    }
    return true;
  }

  return (
    <Box>
      {userData?.address && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            mb: 2,
          }}
        >
          <ArrowBack onClick={handleBack} sx={{ cursor: 'pointer' }} />
          <span>Go Back</span>
        </Box>
      )}
      {showAboutSection && <AboutSection />}
      <Box component="form" onSubmit={handleSubmit} sx={{}}>
        <Grid container spacing={{ xs: 1, md: 2 }} sx={{ textAlign: 'left' }}>
          <Grid item xs={12} lg={3} sx={{ mb: { xs: 2, sm: 3, md: 4, lg: 5 } }}>
            <ImageContainer src={imgUrl || Media.UserProfilePlaceholder} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={9}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: { xs: 2, sm: 3, md: 4, lg: 5 },
            }}
          >
            <Box
              onChange={handleSingleFileChange}
              component="input"
              id="profilePic"
              type="file"
              accept="image/png,image/jpeg"
              sx={{ display: 'none' }}
              name="profilePic"
            />
            <Box htmlFor="profilePic" component="label">
              <UploadProfilePic className="upload-file">
                <FileUploadIcon />
                <Typography variant="caption">
                  Upload new profile picture
                </Typography>
              </UploadProfilePic>
              {touched.profilePic && errors.profilePic ? (
                <FormHelperText sx={{ mx: '14px', color: '#FA3D3D' }}>
                  {errors?.profilePic}
                </FormHelperText>
              ) : null}
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: { xs: 'center', lg: 'left' },
                mt: 1,
                fontWeight: 300,
              }}
            >
              Upload a profile picture for your pet of minimum 2MB in .jpeg or
              .png <RequiredAsterisk />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Full Name</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="firstName"
                  placeholder="First Name"
                  value={values?.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={
                    Boolean(touched.firstName && errors.firstName) &&
                    errors.firstName
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="lastName"
                  placeholder="Last Name"
                  value={values?.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={
                    Boolean(touched.lastName && errors.lastName) &&
                    errors.lastName
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Gender</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Select
                    value={values?.gender}
                    name="gender"
                    onChange={handleChange}
                    placeholder="Gender"
                    onBlur={handleBlur}
                    error={Boolean(touched.gender && errors.gender)}
                  >
                    <MenuItem selected value="M">
                      Male
                    </MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                  </Select>
                  {!!(touched.gender && errors.gender) && (
                    <FormHelperText error>{errors.gender}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Contact Number</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              name="contactNumber"
              placeholder="Contact Number"
              value={values?.contactNumber}
              onChange={handlePhoneNumberChange}
              onBlur={handleBlur}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={
                Boolean(touched.contactNumber && errors.contactNumber) &&
                errors.contactNumber
              }
            />
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Email</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              placeholder="Email"
              name="email"
              value={values?.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
              error={Boolean(touched.email && errors.email)}
              helperText={
                Boolean(touched.email && errors.email) && errors.email
              }
            />
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Date of Birth</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={['DatePicker', 'DatePicker']}
                sx={{ width: '100%', overflow: 'none' }}
              >
                <DatePicker
                  sx={{ width: '100%', overflow: 'none' }}
                  name="dateOfBirth"
                  value={
                    values?.dateOfBirth ? dayjs(values?.dateOfBirth) : null
                  }
                  onChange={(value) => handleDateChange(value)}
                  slotProps={{
                    textField: {
                      error:
                        Boolean(errors.dateOfBirth && touched.dateOfBirth) ||
                        validAge == false,
                      helperText: Boolean(
                        errors.dateOfBirth && touched.dateOfBirth
                      )
                        ? (errors.dateOfBirth as string)
                        : !validAge &&
                          'You must be 18 years of age or older to use this service',
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Type of Residence</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <Select
              variant="outlined"
              fullWidth
              value={values?.typeOfResidence}
              name="typeOfResidence"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.typeOfResidence && errors.typeOfResidence)}
              displayEmpty
            >
              <MenuItem disabled value="">
                Select Type of Residence
              </MenuItem>
              {residences?.data?.map((residence: any) => (
                <MenuItem value={residence.id} key={residence.id}>
                  {residence.name}
                </MenuItem>
              ))}
            </Select>
            {!!(touched.typeOfResidence && errors.typeOfResidence) && (
              <FormHelperText error>{errors.typeOfResidence}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Type of Neighborhood</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <Select
              fullWidth
              variant="outlined"
              value={values?.typeOfNeighborhood}
              name="typeOfNeighborhood"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                touched.typeOfNeighborhood && errors.typeOfNeighborhood
              )}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Type of Neighborhood
              </MenuItem>
              {fetchingNeighbourhoods ? <CircularProgress size={24} /> : null}
              {!fetchingNeighbourhoods
                ? neighbourhoods?.data?.map(({ id, name }: any) => (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            {!!(touched.typeOfNeighborhood && errors.typeOfNeighborhood) && (
              <FormHelperText error>{errors.typeOfNeighborhood}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Address</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              value={values?.address}
              onChange={handleChange}
              onBlur={handleBlur}
              name="address"
              placeholder="Address"
              error={Boolean(touched.address && errors.address)}
              helperText={
                Boolean(touched.address && errors.address) && errors.address
              }
            />
          </Grid>
          <Grid item xs={12} md={3} className="vertical-center">
            <Label>City and State</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <SearchableAutocomplete
              setTouched={setFieldTouched}
              value={values?.city_state}
              valueString={userData?.city_state_name}
              timezone={userData?.city_state_timezone}
              query={useLazyFetchStateCitiesQuery}
              setFieldValue={setFieldValue}
              touched={touched.city_state}
              fieldName="city_state"
              setValidZip={setValidZip}
              setFieldError={setFieldError}
            />
            {!!(touched.city_state && errors.city_state) && (
              <FormHelperText sx={{ml: '14px'}} error>{errors.city_state}</FormHelperText>
            )}
          </Grid>

          {/* IF OTHER CITY STATE IS SELECTED */}
          {
            Number(values?.city_state) === 1 && (
              <>
                <Grid item xs={12} md={3} className="vertical-center"></Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    name="other_state"
                    value={values.other_state}
                    placeholder="Enter City and State name"
                    onChange={handleChange}
                    error={Boolean(touched.other_state&& errors?.other_state)}
                    helperText={Boolean(touched.other_state && errors.other_state) && errors.other_state}
                  />
                </Grid>
              </>
            )
          }

          <Grid item xs={12} md={3} className="vertical-center">
            <Label>Zip</Label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              name="zipCode"
              placeholder="Zip"
              value={values?.zipCode}
              onChange={handleZipCodeChange}
              error={Boolean(touched.zipCode && errors.zipCode) || !isValidZip}
              helperText={
                (Boolean(touched.zipCode && errors.zipCode) && errors.zipCode) || (!isValidZip && 'Invalid zip code')
              }
              onBlur={(e: any) => {
                if (values?.city_state !== '1') {
                  handleBlur(e);
                    validateZip();
                } else {
                  setIsValidZip(true);  
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={9}>
            <Label>
              <Typography variant="body2" className="subscribe-option">
                <FormControlLabel
                  sx={{ mb: 2, alignItems: 'start' }}
                  control={
                    <Checkbox
                      name="subscribed"
                      checked={values?.subscribed ? true : false}
                      value={values?.subscribed}
                      onChange={() =>
                        setFieldValue('subscribed', values?.subscribed ? 0 : 1)
                      }
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ ml: 3 }}>
                      By subscribing, I agree to receive SMS/MMS messages from MyPetsText which will contain messages from the pet profiles I generate along with security and update messages. I may opt-out of texts by sending STOP, but will have to cancel any subscriptions by logging into MyPetsText.com. <strong>Message and data rates may apply.</strong>
                    </Typography>
                  }
                />
                {Boolean(touched.subscribed && errors.subscribed) && (
                  <FormHelperText error>{errors.subscribed}</FormHelperText>
                )}
              </Typography>
            </Label>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={isUpdatingUser}
              sx={{ mt: 1 }}
            >
              {isUpdatingUser ? (
                <CircularProgress sx={{ ml: 2 }} color="inherit" size={24} />
              ) : (
                `Save Changes`
              )}
            </LoadingButton>
            <LoadingButton
              color="secondary"
              type="submit"
              variant="contained"
              sx={{ mt: 1, ml: 2 }}
              onClick={handleNext}
              disabled={completed < Stepper.PROFILE}
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
        {showModal && (
          <StepperModal
            sectionFilled="my profile"
            handleClose={() => setShowModal(false)}
            show={showModal}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditProfileForm;
