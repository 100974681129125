import React, { useState } from 'react';
import { StyledPricingBlock } from './styles';
import { Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import { pricings } from '../../config/pricing';

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SubscriptionPlans() {
  const [value, setValue] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<string>('Free Alpha Trial');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledPricingBlock component="section">
      <Box className="sec-spacing pricing-sec">
        <Box
          sx={{ display: { xs: 'block', sm: 'none' } }}
          component="img"
          alt="The shape"
          src="/assets/images/shape-blue.svg"
          className="img-style__contain blue-bubble"
        />

        <Box textAlign="center">
          <Typography variant="h2" mb={2}>
            Choose Your Plan
          </Typography>
          <Typography
            variant="body1"
            mb={3}
            sx={{
              maxWidth: { xs: '250px', sm: '100%' },
              marginLeft: 'auto',
              marginRight: 'auto',
              color: 'info.darker',
            }}
          >
            Subscribe to our curated plans and avail extra features!
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Box mb={2}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="pricing-sec__tabslist"
              >
                <Tab
                  label={
                    <Typography variant="body2">
                      Annually
                      <Typography variant="body2" className="badge">
                        -10% to -30%
                      </Typography>
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab label="Monthly" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} className="tab-content">
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['annually']['freeAlphaTrial']}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    value={value}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    data={pricings['annually']['starterPlan']}
                    value={value}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['annually']['roversPlan']}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  value={value}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['annually']['familyPlan']}
                    selectedPlan={selectedPlan}
                 
                   setSelectedPlan={setSelectedPlan}
                value={value}
                />
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}  className="tab-content">
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['monthly']['freeAlphaTrial']}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
               value={value}
               />
                </Grid>
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['monthly']['starterPlan']}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                 value={value}
                 />
                </Grid>
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['monthly']['roversPlan']}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  value={value}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3} mb={3}>
                  <SubscriptionCard
                    data={pricings['monthly']['familyPlan']}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  value={value}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </StyledPricingBlock>
  );
}

export default SubscriptionPlans;
