import { Stepper, scheduleTimes } from "../config/constants";
import { IPetDetails } from "../interfaces/pet";
import { IUserDetails } from "../interfaces/user";
import { GlobalRoutes } from "../types";

export function toSnakeCase(obj: { [x: string]: any } | null) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const snakeCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeCaseKey = key.replace(
        /[A-Z]/g,
        (letter) => `_${letter.toLowerCase()}`
      );
      //@ts-ignore
      snakeCaseObj[snakeCaseKey] = obj[key];
    }
  }
  return snakeCaseObj;
}

export function convertKeyToSnakeCase(key: string) {
  return key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function toCamelCase(obj: { [x: string]: any } | null) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const camelCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase()
      );
      //@ts-ignore
      camelCaseObj[camelCaseKey] = toCamelCase(obj[key]);
    }
  }
  return camelCaseObj;
}

export function isPetData(obj: any): obj is IPetDetails {
  // return 'description' in obj;return 'id' in obj && 'name' in obj && 'salary' in obj;
  if ("description" in obj) {
    return obj.description;
  }
  return false;
}

export function isUserData(obj: any): obj is IUserDetails {
  if ("bio" in obj) {
    return obj.bio;
  }
  return false;
}

export function isUserProfileData(obj: any): obj is IUserDetails {
  if ("profile_picture" in obj?.user_profile) {
    return true;
  }
  return false;
}
export function isUserCreditsData(obj: any): obj is IUserDetails {
  if ("credits" in obj?.user_profile) {
    return obj?.user_profile?.credits;
  }
  return false;
}

export function isPetProfileData(obj: any): obj is IPetDetails {
  if ("profile_image" in obj) {
    return obj.profile_image;
  }
  return false;
}

export const getDaysLeft = (fromDate: any) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth(); // 0-indexed (0 = January, 11 = December)

  // Create a date object for the first day of the next month
  const firstDayOfNextMonth: any = new Date(currentYear, currentMonth + 1, 1);

  // Subtract one day from the first day of the next month to get the last day of the current month
  const lastDayOfCurrentMonth: any = new Date(firstDayOfNextMonth - 1);

  return (
    new Date(lastDayOfCurrentMonth).getDate() -
    new Date(fromDate)?.getDate() +
    1
  ); //(from current day);
};

export const getScheduleMessageTypeCreditVal = (id: number) => {
  if (id === 1) {
    // For Image
    return 2;
  } else if (id === 2) {
    // For Text
    return 1;
  } else if (id === 3) {
    // For Both
    return 3;
  }
};

export function convertTimeRange(startTime: string, endTime: string) {
    function formatTime(time: any) {
        let [hours, minutes] = time.split(':').map(Number);
        let date = new Date();
        date.setHours(hours, minutes, 0);
        
        let options: any = { hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    }

    let formattedStartTime = formatTime(startTime);
    let formattedEndTime = formatTime(endTime);

    return `${formattedStartTime} - ${formattedEndTime}`;
}

  /**
   * This function takes user's profile completed count
   * And navigates user as per completed count 
   * @param completedCount 
   */
  export function getNavigationUrl(completedCount: number) {
    let url = GlobalRoutes.EDIT_PROFILE; 
    if(completedCount < Stepper.PROFILE) {
      url = GlobalRoutes.EDIT_PROFILE; 
    } else if(completedCount < Stepper.MY_PETS) {
      url = GlobalRoutes.ACCOUNT_MY_PETS;
    } else if(completedCount < Stepper.SCHEDULE_MESSAGE) {
      url = GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE;
    } 
    return url;
  }