import { Helmet } from "react-helmet-async";
import TestimonialSlider from "../../components/TestimonialSlider";
import PetEditDetails from "../../components/PetEditDetails";
import { useParams } from "react-router-dom";
import PetsOwnerSlider from "../../components/PetsOwnerSlider";

function PetDetail() {
  const { id } = useParams();
  return (
    <>
      <Helmet>
        <title>My Pets | MypetsText</title>
      </Helmet>
      <PetEditDetails petId={id} />
      <PetsOwnerSlider btntitle="Share your Reviews" ratings={false} maintitle="What The Pet Owners Say:" btngenerate={true} className="testimonial-sec--bg"/>
    </>
  );
}

export default PetDetail;
