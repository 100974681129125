import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getIsAuthenticated } from "../../utils/sessions";
import { GlobalRoutes } from "../../types/routes";
import HelmetTitle from "../../components/Helmet";

function AuthLayout({ children }: { children: JSX.Element }): JSX.Element {
  const navigate = useNavigate();
  const isAuthenticated = getIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated) {
      navigate(GlobalRoutes.HOME);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <HelmetTitle />
      {!isAuthenticated ? children : null}
    </>
  );
}

export default AuthLayout;
