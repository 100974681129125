import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
// import "./styles.scss";

import SideBanner from "../../../components/auth/SideBanner";
import { Message } from "../../../config/messages";
import LoginRegisterForm from "../../../components/auth/LoginForm";
import SideImgBanner from "../../../components/SideImgBanner";
function Login() {
  return (
    <Box className="main">
      <Container fixed maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            className="login-info"
            sx={{display:'flex', position: { xs: "relative", sm: "static" },minHeight:{lg:'900px'}}}
          >
            <SideImgBanner />
            <SideBanner
              title={Message.LoginTitle}
              content={Message.LoginContent}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className="login-wrap"
              sx={{
                paddingLeft: { md: "5rem" },
                paddingTop: { xs: "2rem", sm:"4rem", lg:"7rem" },
                paddingBottom: { xs: "2rem", md: "7rem" },
              }}
            >
              <Typography variant="h1" sx={{ mb: 2 }}>
                {Message.Login}
              </Typography>
              <LoginRegisterForm title={Message.Login} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Login;
