import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
export const StyledPetsSteps = styled(Box)`
  @media (min-width: 900px) {
    padding-top: 11em;
    .steps-wrap {
      background-image: url("/assets/images/paws-line.png");
      background-repeat: no-repeat;
      background-position: center;
    }
  }


`;

export const EditTextContainer = styled("div")`
  .MuiTypography-body1 {
    margin: 0;
    line-height: 1.5;
    font-size: 0.875rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    color: #ffffff;
  }
  .MuiTypography-h2 {
    margin: 0;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1.375rem;
    font-family: Poppins, sans-serif;
    color: #ffffff;
    margin-bottom: 8px;
  }
  @media (min-width: 600px) {
    .MuiTypography-h2 {
      font-size: 1.5625rem;
    }
    .MuiTypography-body1 {
      font-size: 1rem;
    }
  }
  @media (min-width: 900px) {
    .MuiTypography-h2 {
      font-size: 1.75rem;
    }
  }
  @media (min-width: 1200px) {
    .MuiTypography-h2 {
      font-size: 2rem;
    }
    .MuiTypography-body1 {
      font-size: 1.25rem;
    }
  }
`;
