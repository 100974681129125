import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPetDetails = styled(Box)`
  padding-top: 5rem;
  .MuiTypography-body2 {
    font-style: italic;
    color: #131c23;
  }
  h2 {
    text-transform: capitalize;
  }
  .MuiListItem-root {
    align-items: start;
  }
  .MuiListItemIcon-root {
    min-width: 45px;
    position: relative;
    top: 6px;
  }
  .petCarousel-detail {
    h2 {
      display: block;
    }
  }
  .petCarousel {
    h2 {
      display: none;
    }
  }
  @media (max-width: 899px) {
    .petCarousel-detail {
      order: 2;
      h2 {
        display: none;
      }
    }
    .petCarousel {
      order: 1;
      h2 {
        display: block;
      }
    }
  }
  /* Text Edit */
  .MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    & .MuiListItemIcon-root {
      display: flex !important;
      gap: 20px;
    }
    }
    .MuiListItemText-root {
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      min-width: 0;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  .MuiTypography-body1{
    margin: 0;
    line-height: 1.5;
    font-size: 0.875rem;
    font-family: Poppins,sans-serif;
    font-weight: 400;
    display: block;
  }
  @media (min-width: 600px){
    .MuiTypography-body1{
      font-size: 1rem;
    }
  }
  @media (min-width: 1200px){
    .MuiTypography-body1{
      font-size: 1.25rem;
    }
  }
`;