import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

  export const StyledLoginButtons = styled(Box)`
  margin-top:3rem;
  flex-direction: column;
   .social-btn img{
       width: 40px;
       height: 40px;
   }
   .social-btn{
      justify-content: flex-start;
      border-radius: 6px;
      border: 1px solid #AFAFAF;
      margin-bottom: 1.5rem;
      min-width: 310px;
      display: flex;
      padding: 0.65rem 2rem !important;
      padding-right: 0.7rem;
      align-items: center;
   }
   .social-btn:hover{
      -webkit-text-decoration: none;
      text-decoration: none;
      background-color: rgba(246, 145, 30, 0.08);
      border: 1px solid #F6911E;
      cursor:pointer;
   }
  `;