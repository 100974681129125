import React from "react";
import {
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { StyledPetDetails } from "./styles";
import { Media } from "../../config/media";
import PetCarousel from "../PetCarousel";
import TextEdit from "../TextEdit";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

type PetDetailsPropsTypes = {
  page?: string;
  sectionName?: string;
};

function PetDetails({ page, sectionName }: PetDetailsPropsTypes) {
  const { pageContent } = useSelector((state: RootState) => state.textEdit);

  let _html = null; 

  if(pageContent.home.length) {
    const section = pageContent.home.find((content: any) => content.section === 'feature');
    _html = section?.content || null;
  }

  return (
    <StyledPetDetails
    component="section"
      sx={{
        bgcolor: "secondary.light",
        paddingBottom: { xs: "2.5rem", md: "5rem" },
        textAlign: { xs: "center", md: "left" },
      }}
    >
      <Container fixed maxWidth="xl">
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12} className="petCarousel-detail body1-style petlist-style">
            <TextEdit page={page} sectionName={sectionName} _html={_html}>
              <>
                <Typography variant="h1" sx={{ mb: 4, pl: 2 }}>
                  features that make everyday special
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain "
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        Fun images of the same breeds as your pet.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      Personalized messages based on you and your pets profiles.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>Multiple users per account</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      Designed to enhance the human-pet bond
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      Customize your messages to arrive when you most need a
                      little lift in the day.
                    </ListItemText>
                  </ListItem>
                </List>
              </>
            </TextEdit>
          </Grid>
          <Grid item md={6} xs={12} className="petCarousel">
            <Typography variant="h2" sx={{ mb: 4, pl: 2 }}>
              features that make everyday special
            </Typography>
            <PetCarousel />
          </Grid>
        </Grid>
      </Container>
    </StyledPetDetails>
  );
}

export default PetDetails;
