import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { SpaceBetweenContainer } from './styles';
import { useFetchUserCreditsQuery } from '../../../redux/userCredits/userCreditsApi';
import { Helmet } from 'react-helmet-async';
import {
  useCancelSubscriptionMutation,
  useGetSubscriptionDetailQuery,
} from '../../../redux/schedule/scheduleApi';
import { useToast } from '../../../hooks/useToast';
import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
const MyCredits: React.FC = () => {
  const { data: userCredits } = useFetchUserCreditsQuery({});
  const { data: subscriptionData, refetch } = useGetSubscriptionDetailQuery({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const toast = useToast();
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [subscription, setSubsription] = useState<boolean>(false);
  let currentBalance: number = 0;
  if (userCredits?.data) {
    currentBalance =
      (userCredits?.data?.total_purchase || 100) -
        (userCredits?.data?.total_usage || 0) ?? 100;
  }
  useEffect(() => {
    if(subscriptionData?.data) {
      setSubsription(subscriptionData?.data?.subscription);
    }
  }, [subscriptionData?.data?.subscription]);


  const cancelSubscriptionHandler = () => {
    try {
      cancelSubscription({});
      setSubsription(false);
      toast({
        title: 'Subscription cancelled successfully',
      });
      setShowModal(false);
    } catch (err) {
      toast({
        title: 'Unable to cancel subscription at the moment',
      });
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Helmet>
        <title>My Credits | MypetsText</title>
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ textAlign: 'left', mb: { xs: 4, md: 5, lg: 7 } }}>
          <Typography variant="h1">My Credits</Typography>
        </Box>
        <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
          <SpaceBetweenContainer>
            <Typography variant="caption" sx={{ color: '#000', mb: 1 }}>
              Your Current Balance
            </Typography>
            <Typography variant="caption" color="primary" sx={{ mb: 1 }}>
              {currentBalance} credits
            </Typography>
          </SpaceBetweenContainer>
          <SpaceBetweenContainer>
            <Typography variant="caption" sx={{ color: '#000', mb: 1 }}>
              Next Payment Date
            </Typography>
            <Typography variant="caption" sx={{ mb: 1 }} color="primary">
              XX-XX-XX
            </Typography>
          </SpaceBetweenContainer>
        </Stack>
        <Box
          sx={{
            background: '#f9f9f9',
            p: { xs: 2, md: 3, lg: 4 },
            mt: 2,
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              display: { md: 'flex' },
              alignItems: 'center',
              gap: '80px',
            }}
          >
            <Box>
              <Stack direction="column" sx={{ minWidth: '125px' }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: { xs: '24px', md: '26px', lg: '28px' },
                    color: 'primary.dark',
                    lineHeight: '1.2',
                  }}
                >
                  Basic
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: { xs: '48px', md: '58px', lg: '68px' },
                    color: 'secondary.main',
                    lineHeight: '1.5',
                  }}
                >
                  100
                </Typography>
                <Typography variant="body2">credits per user</Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                width: '100%',
                borderLeft: { md: '1px solid #CCCCCC' },
                pl: { md: 2 },
              }}
            >
              <SpaceBetweenContainer>
                <Typography variant="caption" sx={{ color: '#000', mb: 2 }}>
                  Plan Status
                </Typography>
                <Typography variant="caption" sx={{ mb: 2 }} color="primary">
                  Active
                </Typography>
              </SpaceBetweenContainer>
              <SpaceBetweenContainer>
                <Typography variant="caption" sx={{ color: '#000', mb: 2 }}>
                  Valid Until
                </Typography>
                <Typography variant="caption" sx={{ mb: 2 }} color="primary">
                  XX-XX-XXXX
                </Typography>
              </SpaceBetweenContainer>
              <SpaceBetweenContainer>
                <Typography variant="caption" sx={{ color: '#000', mb: 2 }}>
                  Activated On
                </Typography>
                <Typography variant="caption" sx={{ mb: 1 }} color="primary">
                  XX-XX-XXXX
                </Typography>
              </SpaceBetweenContainer>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              gap: { xs: '10px', sm: '80px' },
            }}
          >
            <Tooltip title="coming soon" placement="bottom-start" arrow>
              <Typography
                variant="caption"
                color="primary"
                sx={{
                  textDecoration: 'underline',
                  mt: { xs: 2, sm: 4 },
                  mb: 2,
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
              >
                Buy more credits
              </Typography>
            </Tooltip>
            {subscription ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowModal(true)}
              >
                Cancel Subscription
              </Button>
            ) : (
              <Button variant="contained" color="primary" disabled>
                Enable Subscription
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {showModal && (
        <Dialog
          open={showModal}
          keepMounted
          onClose={() => setShowModal(false)}
          sx={{maxWidth:'550px',mx:'auto'}}
        >
           <DialogTitle>
            <Typography variant="h2" sx={{mb:1}}>
              {'Cancel Subscription'}
            </Typography>
            <Divider/>
          </DialogTitle>
          <DialogContent> 
             <Typography variant="body1">
              {'Are your sure you want to cancel the subscription?'}
            </Typography>
            </DialogContent>
          <DialogActions sx={{ m: 3 }}>
            <Button variant="contained" onClick={() => setShowModal(false)}>
              Disagree
            </Button>
            <Button variant="contained" onClick={cancelSubscriptionHandler}>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MyCredits;
