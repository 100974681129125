import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { TextEditSliceType } from "../redux/textEdit/editorSlice";

export const useContent = (page: string, section: string) => {
  const { pageContent }: TextEditSliceType = useSelector(
    (state: RootState) => state.textEdit
  );
  let _html = null;
  if (pageContent) {
    //@ts-ignore
    if (pageContent[page].length) {
      //@ts-ignore
      const sectionContent = pageContent[page].find(
        (content: any) => content.section === section
      );
      _html = sectionContent?.content || null;
    }
  }
  return _html;
};
