import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTestimonialBlock = styled(Box)`
  .btn-wrap .MuiButton-root {
    margin: 0 1.5rem 1.5rem;
  }

  .testimonial-sec--bg{
    background-repeat: no-repeat;
  }
  .testimonial-sec--bg .testimonial-sec__content > div:last-child {
    bottom: -130px;
  }
  .testimonial-sec--bg .btn-wrap {
    margin-top: 2rem;
  }
  .testimonial-sec--bg > .img-style,
  .testimonial-sec--bg:before {
    display: none;
  }
  .testimonial-sec--bg *:not(.MuiButton-root) {
    color: #fff !important;
  }
  .testimonial-sec--bg {
    background-color: #f6911e;
  }

  .testimonial-sec--bg .testimonial-sec__quote:before {
    top: -20px;
  }

  .testimonial-sec--bg .testimonial-sec__quote:after {
    bottom: 27px;
    top: auto;
  }

  .testimonial-sec--bg .testimonial-sec__content > div:first-child,
  .testimonial-sec.testimonial-sec--bg,
  .testimonial-sec--bg.testimonial-sec .MuiContainer-fixed {
    min-height: auto;
  }

  .testimonial-sec--bg h2 {
    margin-bottom: 3rem;
  }

  .testimonial-sec--bg .testimonial-sec__quote:before,
  .testimonial-sec--bg .testimonial-sec__quote:after {
    background-image: url("/assets/icons/quote-transparent.svg");
  }
  .testimonial-sec {
    position: relative;
    min-height: 626px;
  }
  .testimonial-sec:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
  .testimonial-sec__content .MuiPaper-root {
    background-color: transparent;
  }
  .testimonial-sec__content > div:last-child {
    position: relative;
    bottom: -90px;
  }
  .testimonial-sec__quote {
    font-style: italic;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
    padding-right: 100px;
    font-weight: 500;
    line-height: 1.6;
  }

  .testimonial-sec__quote + h4 {
    color: #f6911e;
    margin-top: 5rem;
    margin-bottom: 0.6rem;
  }

  .testimonial-sec .MuiContainer-fixed {
    position: relative;
    z-index: 2;
    min-height: 400px;
  }

  .testimonial-sec__content > div:first-child {
    min-height: 300px;
  }
  .testimonial-sec__quote:before,
  .testimonial-sec__quote:after {
    background-image: url("/assets/images/icon _quote.svg");
    content: "";
    width: 70px;
    height: 70px;
    top: -50px;
  }
  .testimonial-sec__quote:before {
    left: 0;
  }
  .testimonial-sec__quote:after {
    transform: scale(-1);
    right: 0;
    top: -28px;
  }

  .testimonial-sec__content {
    overflow: visible;
  }
  .rating-list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
  .rating-list li {
    margin: 0 3px;
  }
  .rating-list li img {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 599px) {
    .testimonial-sec__quote {
      padding-left: 50px;
      padding-right: 50px;
    }
    .testimonial-sec__content > div:first-child,
    .testimonial-sec .MuiContainer-fixed {
      min-height: 300px;
    }
    .testimonial-sec__quote:before {
      top: -2px;
      left: 8px;
    }
    .testimonial-sec__quote:after {
      top: auto;
      bottom: 0;
    }
    .testimonial-sec__quote:before,
    .testimonial-sec__quote:after {
      width: 45px;
      height: 45px;
    }
  }
`;
