import { appApi } from "../apis/apiSlice";

const editorApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["EDITOR"],
});

export const editorApi = editorApiWithTag.injectEndpoints({
  endpoints: (builder) =>  ({
    getPageContent: builder.query<any, any>({
      query: (pageName: string) => ({
        url: `/cms/page/${pageName}`, 
        method: 'GET', 
        refetchOnMountOrArgChange: true,
        provideTags: ['EDITOR'], 
      })
    }), 
    updatePageContent: builder.mutation<any, any>({
        query: (body: any) => ({
            url: '/cms/page-content/update/', 
            body,  
            method: 'PATCH', 
        }), 
    })
  }), 
});

export const {
    useGetPageContentQuery, 
    useUpdatePageContentMutation, 
} = editorApi;
