import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TabsSection from "../../components/TabsSection";
import { AccountTabs, Stepper } from "../../config/constants";
import ReactRouterPrompt from "react-router-prompt";
import { MyAccountContainer } from "./styles";
import { Outlet, useLocation } from "react-router-dom";
import { Media } from "../../config/media";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { RootState } from "../../redux";
import { useGetProfileStepperQuery } from "../../redux/stepper/stepperApi";
import { setProfileCompleted } from "../../redux/stepper/stepperStore";
import { getAuthToken } from "../../utils/sessions";

type MyAccountPropsType = {};

const MyAccount: React.FC<MyAccountPropsType> = () => {
  const { pathname } = useLocation();
  const [_, setSelected] = useState<string>("");
  const { userData } = useSelector((state: RootState) => state.user);
  const { completed } = useSelector((state: RootState) => state.stepper);
  const dispatch = useDispatch();
  const { data: stepperData } = useGetProfileStepperQuery({});
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (stepperData) {
      // If stepper data found, set the redux store
      dispatch(setProfileCompleted(stepperData?.data?.completed));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepperData]);

  useEffect(() => {
    if (pathname) {
      Object.keys(AccountTabs).forEach((key) => {
        const value = AccountTabs[key].split(" ")[1] || "";
        if (pathname.includes(value?.toLowerCase())) {
          setSelected(AccountTabs[key]);
        }
      });
    }
  }, [pathname]);

  return (
    <>
      <MyAccountContainer
        sx={{
          bgcolor: "secondary.light",
          textAlign: "center",
          padding: "0",
          p: { xs: 2, sm: 4, md: 5, lg: 6 },
          mt: 0,
          backgroundImage: {
            xs: `url("${Media.PawsTransparentXs}")`,
            sm: `url("${Media.PawsTransparentSm}")`,
          },
          backgroundSize: { xs: "contain", sm: "100% 100%" },
        }}
        ref={containerRef}
      >
        <Container fixed maxWidth="xl">
          <TabsSection />
          <Box component="section">
          {userData ? (
            <Paper
              sx={{
                boxShadow: "0px 0px 18.9px 0px #A7A7A740",
                px: 3,
                pb: 4,
                pt: 5,
                borderRadius: "10px",
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Outlet />
            </Paper>
          ) : (
            <Box sx={{ minHeight: "400px" }}>
              <Loader />
            </Box>
          )}
          </Box>
        </Container>
      </MyAccountContainer>
      <ReactRouterPrompt when={(currentLocation: any) => {
        const locationPath = currentLocation?.nextLocation?.pathname; 
        if(locationPath.includes('/my-account/') || locationPath.includes('/my-account/edit-pet/') || locationPath.includes('/my-account/add-pet') || !getAuthToken()) {
          return false;
        } else if(completed < Stepper.MY_CREDIT) {
          return true;
        }
        return false;
      }}>
        {({ isActive, onConfirm, onCancel }) => {
          return isActive && (
            <>
              <Dialog open maxWidth="xl" sx={{maxWidth:'550px',mx:'auto'}}>
              <DialogTitle>
            <Typography variant="h2" sx={{mb:1}}>
              Confirmation
            </Typography>
            <Divider/>
          </DialogTitle>
                <DialogContent> 
             <Typography variant="body1">
             Your profile isn't completed. Do you really want to leave?
            </Typography>
            </DialogContent>
                <DialogActions>
                  <Button onClick={onCancel} variant="contained">Close</Button>
                  <Button onClick={onConfirm} variant="contained">OK</Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }
        }
      </ReactRouterPrompt>
    </>
  );
};

export default MyAccount;
