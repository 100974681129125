import * as Yup from 'yup';
import { constants } from '../../config/constants';
// import { constants } from '../../constants/constants';

export const registerSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'), 
    lastName: Yup.string().required('Last Name is required'), 
    email: Yup.string()
      .email('Please enter a valid Email')
      .required('Email is required'),
    password: Yup.string()
      .min(constants.passwordMinLen, 'Password must contain atleast 8 characters')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[a-z]/, 'Password must contain lower case letter')
      .matches(/[A-Z]/, 'Password must contain an upper case letter')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain an special character'
      )
      .required('Password is required'),
    });
  


  export const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid Email')
      .required('Email is required'),
      password: Yup.string()
      .required('Password is required'),
  });


  export const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid Email')
      .required('Email is required'),
  });

  export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(constants.passwordMinLen, 'Password must contain atleast 8 characters')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[a-z]/, 'Password must contain lower case letter')
      .matches(/[A-Z]/, 'Password must contain an upper case letter')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain an special character'
      )
      .required('Password is required'),
    confirm_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  export const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(constants.passwordMinLen, 'Password must contain atleast 8 characters')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[a-z]/, 'Password must contain lower case letter')
      .matches(/[A-Z]/, 'Password must contain an upper case letter')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain an special character'
      )
      .required('Password is required'),
    confirm_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });