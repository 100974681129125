import { styled } from "@mui/material";

export const ImageContainer = styled("img")`
  max-width: 200px;
  max-height: 200px;
  width: 11vw;
  height: 11vw;
  object-fit: cover;
  border-radius: 50%;
  @media (max-width: 992px) {
    width: 151px;
    height: 151px;
  }

  @media (max-width: 1200px) {
    margin: 0 auto;
  }
`;

export const Label = styled("label")`
  label span {
    font-size: inherit !important;
  }
  label span + span {
    margin-left: 15px !important;
    text-transform: none;
  }

  .MuiCheckbox-root {
    position: relative;
    top: 4px;
    left: 10px;
  }
`;

export const UploadProfilePic = styled("div")`
  width: 100%;
  border: 1px dashed #676767;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 63px;
  border-radius: 6px;
  gap: 2px;
  &:hover {
    cursor: pointer;
  }
`;
