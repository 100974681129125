import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


  export const StyledOverLayBlock = styled(Box)`
    .info-block{
      border-radius: 20px;
      background-color:rgba(246, 145, 30, .8);
      z-index: 1;
      position: relative;
    }
    p{
      font-weight: 300;
      max-width: 492px;
      margin: 0 auto;
    }

  `;