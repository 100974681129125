import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAccordian = styled(Box)`
.icon-img{
  width: 21px;
}
.MuiPaper-rounded  {
    border-radius: 0  !important;
  }
  @media(max-width:599px){
    .MuiAccordionSummary-content.Mui-expanded{
      margin-bottom: 0 !important;
    }
  }
`;