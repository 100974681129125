import React from "react";
import {
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { StyledPetDetails } from "./styles";
import { Media } from "../../config/media";
import PetCarousel from "../PetCarousel";
import TextEdit from "../TextEdit";
import { useContent } from "../../hooks/useContent";

type FeaturesPetDetailsPropsType = {
  page?: string;
  sectionName?: string;
};

function FeaturesPetDetails({
  page,
  sectionName,
}: FeaturesPetDetailsPropsType) {
  const _html = useContent(page as string, sectionName as string);
  return (
    <StyledPetDetails
    component="section"
      sx={{
        bgcolor: "secondary.light",
        paddingBottom: { xs: "2.5rem", md: "5rem" },
        textAlign: { xs: "center", md: "left" },
      }}
    >
      <Container fixed maxWidth="xl">
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12} className="petCarousel-detail body1-style body2-style">
            <TextEdit page={page} sectionName={sectionName} _html={_html}>
              <>
                <Typography variant="h1" sx={{ mb: 4, pl: 2 }}>
                  features that make everyday special
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain "
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        Personalized Messages: Custom texts based on your
                        preferences.
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        Every message is crafted to reflect your pet’s
                        personality and your preferences, making each text feel
                        truly special.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        Fun Images: Daily images of your pet’s breed
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        Enjoy daily images of pets that look just like yours,
                        adding a visual delight to your messages.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        Family Participation: Multiple users per account
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        Emotional Connection: Designed to enhance your bond with
                        your pet
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Box
                        component="img"
                        alt="The shape"
                        src={Media.TickIcon}
                        className="img-style__contain"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        Texting only. No social media account or app required
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </>
            </TextEdit>
          </Grid>
          <Grid item md={6} xs={12} className="petCarousel">
            <Typography variant="h2" sx={{ mb: 4, pl: 2 }}>
              features that make everyday special
            </Typography>
            <PetCarousel />
          </Grid>
        </Grid>
      </Container>
    </StyledPetDetails>
  );
}

export default FeaturesPetDetails;
