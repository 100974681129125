import React from "react";
import Carousel from "react-material-ui-carousel";
import { StyledTestimonialBlock } from "./styles";
import { Box, Button, Container, Typography } from "@mui/material";
import { Media } from "../../config/media";
interface ITestimonialSliderProps {
  className?: string;
  ratings: boolean;
  maintitle: string;
  btntitle?: string;
  btngenerate?: boolean;
}
function TestimonialSlider({
  className,
  ratings,
  maintitle,
  btntitle,
  btngenerate,
}: ITestimonialSliderProps) {
  var items = [
    {
      description:
      "Receiving texts from my pet has brought so much joy and laughter into our home. It's amazing how lifelike and creative some of the images are.",
      title: "Julie A",
    },
    {
      description:
        "This texting service is a game-changer! My kids love getting funny messages and photos from our cat, Whiskers. It keeps us all entertained.",
      title: "George S",
    },
    {
      description:
        "The personalized texts from my dog Rocky make me feel so connected to him, even when I'm traveling. It's like he's just a text away!",
      title: "Joe M",
    },
  ];
  return (
    <StyledTestimonialBlock component="section">
      <Box
        sx={{ position: "relative", py: 9,      backgroundImage: {
          xs: `url("${Media.PawsOrangeXs}")`,
          sm: `url("${Media.PawsOrangeSm}")`,
        }, }}
        textAlign="center"
        className={`testimonial-sec ${className}`}
      >
        <Box
          component="img"
          alt="blue background"
          src={Media.TestimonialBg}
          className="img-style img-style__cover img-absolute"
        />
        <Container fixed maxWidth="xl">
          <Typography
            variant="h2"
            sx={{ position: "relative", zIndex: "2", mb: 2 }}
          >
            {maintitle}
          </Typography>
          <Carousel
            className="testimonial-sec__content"
            indicatorIconButtonProps={{
              style: {
                cursor: "pointer",
                transition: "200ms",
                padding: 0,
                color: "#FEF4E8",
              },
            }}
            animation="fade"
            activeIndicatorIconButtonProps={{ style: { color: "#F6911E" } }}
          >
            {items.map((item, i) => {
              return (
                <Box key={i}>
                  <p className="testimonial-sec__quote quotes">
                    {item.description}
                  </p>
                  {ratings && (
                    <>
                      <h4>{item.title}</h4>
                      <ul className="rating-list">
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                      </ul>
                    </>
                  )}
                </Box>
              );
            })}
          </Carousel>
          <Box className="btn-wrap">
           
            {btngenerate && (
            <Button color="secondary" variant="contained" href="#text-buttons">
            {btntitle}
          </Button>
            )}
            {btngenerate && (
              <Button
                color="secondary"
                variant="contained"
                href="#text-buttons"
              >
                Generate New Quote
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </StyledTestimonialBlock>
  );
}

export default TestimonialSlider;
