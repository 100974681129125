import { appApi } from "../apis/apiSlice";

const scheduleApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["SCHEDULE"],
});

export const scheduleApi = scheduleApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchScheduleTimes: builder.query<any, any>({
      query: () => ({
        url: "/pet/scheduletimes/",
        method: "GET",
      }),
    }),
    fetchScheduleTypes: builder.query<any, any>({
        query: () => ({
            url: '/pet/scheduletypes/', 
            method: 'GET', 
        }), 
    }), 
    fetchAllSchedules: builder.query<any, any>({
        query: () => ({
            url: '/pet/schedules', 
            method: 'GET', 
        }), 
    }), 
    addNewSchedule: builder.mutation<any, any>({
        query: (data) => ({
            url: '/pet/schedules/', 
            method: 'POST', 
            body: data, 
        }), 
    }), 
    fetchScheduleById: builder.query<any, any>({
        query: (id) => ({
            url: `/pet/schedules/${id}/`, 
            method: 'GET',  
        }), 
    }), 
    updateSchedule: builder.mutation<any, any>({
        query: ({ id, data }) => ({
            url: `/pet/schedules/${id}/`, 
            method: 'PATCH', 
            body: data, 
        }), 
    }), 
    getSubscriptionDetail: builder.query<any, any>({
        query: () => ({
            url: '/pet/schedules/subscription', 
            method: 'GET', 
        }),  
    }), 
    cancelSubscription: builder.mutation<any, any>({
        query: () => ({
            url: '/pet/schedules/subscription', 
            method: 'PATCH', 
            body: {
                status: false, 
            }
        })
    })
  }),
});

export const {
    useFetchScheduleTimesQuery, 
    useFetchScheduleTypesQuery, 
    useAddNewScheduleMutation, 
    useFetchAllSchedulesQuery, 
    useFetchScheduleByIdQuery, 
    useUpdateScheduleMutation, 
    useLazyFetchScheduleByIdQuery,
    useGetSubscriptionDetailQuery, 
    useCancelSubscriptionMutation, 
} = scheduleApi;
