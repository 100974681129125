import { createBrowserRouter, Navigate } from "react-router-dom";
import AppLayout from "../layouts/AppLayout/layout";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import { GlobalRoutes } from "../types";
import PageNotFound from "../components/PageNotFound";

const router = createBrowserRouter([
  {
    path: GlobalRoutes.HOME,
    element: <AppLayout />,
    children: [
      ...publicRoutes,
      ...protectedRoutes,
    ]
  },
  {
    path: '/page-not-found',
    element: <PageNotFound />
  },
  {
    path: '*',
    element: <Navigate to='/page-not-found' />
  }
]);

export default router;
