import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";
import { StyledPetDetailCard } from "./styles";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import LazyLoad, { forceCheck } from "react-lazyload";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Media } from "../../config/media";
interface IPetDetailCardProps {
  imgUrl?: string | null | undefined;
  date: string;
  time: string;
  message?: string | null | undefined;
  handleLikeDislike: (value: any) => void;
  handleComment: (value: any) => void;
  comment: string | null;
  id: number | null | undefined;
  textStatus: null | string;
  imgStatus: null | string;
  commentSend: boolean;
}
function PetDetailCard({
  imgUrl,
  message,
  date,
  time,
  handleComment,
  handleLikeDislike,
  id,
  comment,
  textStatus,
  imgStatus,
  commentSend,
}: IPetDetailCardProps) {
  // State for containing comment
  const [text, setText] = useState<string>(comment ? comment : "");
  const [imgReaction, setImgReaction] = useState<string | null>(null);
  const [textReaction, setTextReaction] = useState<string | null>(null);

  useEffect(() => {
    setImgReaction(imgStatus);
    setTextReaction(textStatus);
  }, [imgStatus, textStatus]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <StyledPetDetailCard
      sx={{
        bgcolor: "secondary.contrastText",
      }}
    >
      <LazyLoad
        debounce={500}
        placeholder={
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={382}
            sx={{
              mb: 2,
              width: "475px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        }
      >
        <Box sx={{ position: "relative", mb: 1 }}>
          {imgUrl ? (
            <>
              <Box className="img-placeholder">
                <Box
                  component="img"
                  src={imgUrl}
                  className="img-style img-style__cover pet-img"
                />
              </Box>
              <Box
                className="status-btn"
                sx={{
                  bgcolor: "primary.contrastText",
                  borderColor: "info.light",
                }}
              >
                <Button
                  sx={{ p: 0, color: "info.light" }}
                  onClick={() => {
                    setImgReaction(imgReaction === '1' ? null : '1');
                    handleLikeDislike({
                      action: "img-1",
                      reaction: imgReaction === '1' ? null : 1,
                      user_prompt_id: id,
                    });
                  }}
                >
                  <ThumbUpOffAltIcon
                    color={imgReaction === "1" ? "primary" : "inherit"}
                  />
                </Button>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ borderColor: "info.light" }}
                />
                <Button
                  sx={{ p: 0, color: "info.light" }}
                  onClick={() => {
                    setImgReaction(imgReaction === "2" ? null : '2');
                    handleLikeDislike({
                      action: "img-1",
                      reaction: imgReaction === '2' ? null : 2,
                      user_prompt_id: id,
                    });
                  }}
                >
                  <ThumbDownOffAltIcon
                    color={imgReaction === "2" ? "primary" : "inherit"}
                  />
                </Button>
              </Box>
            </>
          ) : (
            message?.toLowerCase() !== "no message" && (
              <Box className="img-style img-style__cover pet-img" sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Box sx={{
                  background: 'white',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                  <Box component="img" sx={{
                    borderTopRightRadius: '10px',
                    objectFit: 'contain',
                    borderTopLeftRadius: '10px',
                    width: '80%',
                  }} src={Media.NoMessage} alt="No Message Image" height="100%" />
                  <Box sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    pb: 2,
                    mt: '-20px',
                  }} component="span">SMS Message. No Image Sent.</Box>
                </Box>
              </Box>
            )
          )}
        </Box>
      </LazyLoad>
      {message && message.toLowerCase() !== 'no message' ? (
        <Box sx={{ pb: 1, px: 1 }}>
          <Box className="pet-text" sx={{ mb: 2 }}>
            <Typography component="span">{date}</Typography>
            <Typography component="span">{time}</Typography>
          </Box>
          <Typography
            className="pet-description"
            sx={{ color: "primary.dark" }}
          >
            {message || ""}
          </Typography>
          <Box sx={{ textAlign: "right" }}>
            <Box
              className="status-btn"
              sx={{ bgcolor: "primary.contrastText", mb: 2 }}
            >
              <Button
                sx={{ p: 0, color: "info.light" }}
                onClick={() => {
                  setTextReaction(textReaction === "1" ? null : '1');
                  handleLikeDislike({
                    action: "like-1",
                    reaction: textReaction === '1' ? null : 1,
                    user_prompt_id: id,
                  });
                }}
              >
                <ThumbUpOffAltIcon
                  color={textReaction === "1" ? "primary" : "inherit"}
                />
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: "info.light" }}
              />
              <Button
                sx={{ p: 0, color: "info.light" }}
                onClick={() => {
                  setTextReaction(textReaction === "2" ? null : '2');
                  handleLikeDislike({
                    action: "like-1",
                    reaction: textReaction === '2' ? null : 2,
                    user_prompt_id: id,
                  });
                }}
              >
                <ThumbDownOffAltIcon
                  color={textReaction === "2" ? "primary" : "inherit"}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      ): (
          <Box sx={{ pb: 1, px: 1 }}>
          <Box className="pet-text" sx={{ mb: 2 }}>
            <Typography component="span">{date}</Typography>
            <Typography component="span">{time}</Typography>
          </Box>
          </Box>
      )}
      <Box
        className="comment-wrap"
        sx={{
          borderColor: "info.darker",
          backgroundColor: "primary.contrastText",
        }}
      >
        <TextField
          fullWidth
          id="input-with-sx"
          variant="outlined"
          placeholder="Add your comment..."
          value={text}
          name="text"
          onChange={handleChange}
        />
        <Button
          sx={{ p: 0, color: "primary.main" }}
          onClick={() =>
            handleComment({
              user_prompt_id: id,
              action: "cmm",
              reaction: -1,
              usertext: text,
            })
          }
        >
          {" "}
          {commentSend ? <CheckCircleOutlineIcon className="tick-icon" color="success" /> : <SendIcon />}
        </Button>
      </Box>
    </StyledPetDetailCard>
  );
}

export default PetDetailCard;
