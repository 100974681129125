import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';



export const StyledPricingBlock = styled(Box)`
.pricing-sec__tabslist .MuiTabs-flexContainer {
  border-radius: 2.938rem;
  background-color: rgba(1, 185, 175, .15);
  align-items: center;
  padding: 7px;
}

.pricing-sec__tabslist .MuiTabs-fixed {
  display: flex;
  justify-content: center;
}


.pricing-sec__tabslist .MuiTabs-indicator {
  display: none;
}
.pricing-sec__tabslist  .MuiButtonBase-root {
  min-height: auto;
  font-size: 16px;
  line-height: 21px;
  padding: 7px 20px;
  color: #131C23;
  font-weight: 400;
}

.pricing-sec__tabslist  .MuiButtonBase-root p{
  display: flex;
  align-items: center;
}

.pricing-sec__tabslist .Mui-selected{
  border-radius: 2.9375rem;
  background-color: #01B9AF;
  color: #fff;
  font-weight: 600;
}
.pricing-sec__tabslist .Mui-selected p:not(.badge){
  color: #fff;
  font-weight: 600;
}

.pricing-sec__tabslist .badge{
  border-radius: 13.5px;
  background: #FFF;
  font-weight: 800;
  color: #01B9AF;
  padding: 3px 8px;
  position: relative;
  right: -11px;
}
@media(max-width:599px){
  .tab-content > .MuiBox-root {
    padding: 2rem 0 0;
 }
  .blue-bubble{
    position: relative;
    bottom: -10px;
    left: -7px;
  }
  .pricing-sec{
    padding: 2rem 1rem;
  }
}
`;
