import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { IGoogleUserType } from "../../../interfaces/social";
import { Box, Typography } from "@mui/material";
import { useSocialLoginMutation } from "../../../redux/auth/authApi";
import { Media } from "../../../config/media";
import { ILoginResponseInterface } from "../../../interfaces/auth";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../redux/auth/authStore";
import { GlobalRoutes } from "../../../types/routes";
import { Message } from "../../../config/messages";
import { useToast } from "../../../hooks/useToast";
import { useLazyGetProfileStepperQuery } from "../../../redux/stepper/stepperApi";
import { setProfileCompleted } from "../../../redux/stepper/stepperStore";
import { Stepper } from "../../../config/constants";
import { getNavigationUrl } from "../../../utils/helper";
import Loader from "../../Loader";

function GoogleLoginButton() {
  const [loading, setLoading] = useState(false);

  const [socialLogin] = useSocialLoginMutation();
  const [fetchStepperData] = useLazyGetProfileStepperQuery();
  const toast = useToast();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler = async ({ data }: ILoginResponseInterface) => {
    const userToken = data?.token;
    if (!userToken) return;
    dispatch(
      loginSuccess({
        isAuthenticated: true,
        token: userToken,
      })
    );
    try {
      const stepperRes = await fetchStepperData({});
      const completed = stepperRes?.data?.data?.completed;
      if (stepperRes) {
        dispatch(setProfileCompleted(completed));
        if (completed < Stepper.MY_CREDIT) {
          const url = getNavigationUrl(completed);
          navigate(url, {
            state: {
              message: true,
            },
          });
        } else {
          navigate(GlobalRoutes.HOME);
        }
      }
      setLoading(false);
      toast({
        title: Message.LoginSuccessfully,
      });
    } catch (err) {
      setLoading(false);
      toast({
        title: Message.SomethingWrong,
        severity: 'error',
      });
    }
  };

  const handleSocialGoogle = async (googleUserData: IGoogleUserType) => {
    const name = googleUserData.name.split(' ');
    const payload = {
      email: googleUserData.email,
      first_name: googleUserData?.given_name,
      last_name: googleUserData?.family_name, 
      image: googleUserData?.picture, 
    };
    await socialLogin(payload)
      .unwrap()
      ?.then((res) => {
        loginHandler(res as ILoginResponseInterface);
      })
      ?.catch((error: any) => {
        toast({
          title: Message.SomethingWrong, 
          severity: 'error'
        });
        setLoading(false);
      });
  };
  const responseGoogle = useGoogleLogin({
    scope: "openid profile email",
    onSuccess: async (codeResponse) => {
      setLoading(true);
      const access_token = codeResponse.access_token;
      if (access_token) {
        const url = `${process.env.REACT_APP_GOOGLE_API_URL}`;
        axios({
          method: "get",
          url,
          params: {
            access_token,
            scope: codeResponse.scope,
          },
        })
          .then(function (response) {
            if (response?.data) {
              const googleUserData = response.data;
              handleSocialGoogle(googleUserData);
            } else {
              toast({
                title: Message.SomethingWrong , 
                severity: 'error', 
              });
              setLoading(false);
            }
          })
          .catch((error) => {
              toast({
                title: Message.SomethingWrong , 
                severity: 'error', 
              });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  return (
    <>
    { loading && <Loader /> }
      <LoadingButton
        loading={loading}
        type="submit"
        loadingPosition="start"
        className="social-btn"
        sx={{ bgcolor: "info.lighter", cursor: 'pointer' }}
        onClick={() => responseGoogle()}
      >
        <Box
          component="img"
          className="img-style img-style__contain"
          alt="google-icon"
          src={Media.GoogleLoginButtonIcon}
          mr={2}
        />
        <Typography variant="body2">Continue with Google</Typography>
      </LoadingButton>
    </>
  );
}

export default GoogleLoginButton;
