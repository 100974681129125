import React from "react";
import { Typography } from "@mui/material";

interface IErrorMessageProps {
  message: string;
  type: Boolean;
  show: Boolean;
}
function ErrorMessage({ message, type, show }: IErrorMessageProps) {
  return (
    <>
      <Typography
        textTransform="capitalize"
        display={show ? "block" : "none"}
        textAlign="center"
        variant="h5"
        color={type ? "success" : "error"}
        className="success-text"
      >
        {message}
      </Typography>
    </>
  );
}

export default ErrorMessage;
