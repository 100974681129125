import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { GlobalRoutes } from "../../types";


const PageNotFound: React.FC = () => {
  return (
    <section className="page-not-found-container">
      <div className="error__wrapper">
        <h1>4 <img src="https://c.tenor.com/Nl45qRGQk_0AAAAi/ghost-white.gif" /> 4</h1>
        <p className="frown-it" data-splitting>Page Not Found</p>
        <Link to={GlobalRoutes.HOME}>Back to homepage</Link>
      </div>

    </section>
  );
}

export default PageNotFound;
