import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../../utils/sessions";
import { store } from "..";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URI, //API_URL,
  prepareHeaders: (headers) => {
    headers.set("Accept", "application/json, text/plain, */*");
    // Fetch token from redux store
    const state = store.getState();
    const token = state.auth.token; 
    if(token != null){
      headers.set("Authorization", `Token ${token}`);
    }
    return headers;
  },
});

export const appApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
