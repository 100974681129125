import { Box, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import EditIcon from '@mui/icons-material/Edit';
import { useUpdatePageContentMutation } from '../../redux/textEdit/editorApi';
import { updatePageContent } from '../../redux/textEdit/editorSlice';
import { TextEditContainer } from './styles';

type TextEditPropsType = {
  children?: JSX.Element;
  _html?: any;
  page?: string;
  sectionName?: string;
};

const TextEdit: React.FC<TextEditPropsType> = ({
  children,
  _html,
  page,
  sectionName,
}) => {
  const contentRef = useRef<any>(null);
  const [oldHtml, setOldHtml] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { editing } = useSelector((state: RootState) => state.textEdit);
  const { editable } = useSelector((state: RootState) => state.auth);
  const showEditIcon = editable && editing && !isEditing;
  const [updateContent] = useUpdatePageContentMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (_html) {
      setOldHtml(_html);
      if (contentRef && contentRef.current) {
        contentRef.current.innerHTML = _html;
      }
    } else {
      if (contentRef && contentRef.current) {
        const data = contentRef.current.innerHTML;
        setOldHtml(data);
      }
    }
  }, [_html, children]);

  const onSave = async () => {
    try {
      const payload: any = {
        page_name: page,
        section: sectionName,
        content: contentRef.current.innerHTML,
      };
      const res = await updateContent(payload);
      //@ts-ignore
      const data = res?.data;
      if (data?.data) {
        contentRef.current.innerHTML = data?.data.content;
        dispatch(
          updatePageContent({
            page: data.data.page_name,
            section: data.data.section,
            data: data.data,
          })
        );
      }
    } catch (err: any) {
      // handle error
    }
    setIsEditing(false);
  };

  const onCancel = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.innerHTML = oldHtml;
    }
    setIsEditing(false);
  };

  return (
    <Box>
      <Box className={`relative text-editor ${isEditing ? 'editing' : 'no-editing'
        }`}>
        <TextEditContainer
          className="editor-container"
          contentEditable={editable && editing ? true : false}
          ref={contentRef}
        >
        </TextEditContainer>
        {showEditIcon && (
          <EditIcon className="edit-icon" onClick={() => setIsEditing(true)} />
        )}
      </Box>
      {isEditing && (
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: 'flex',
            gap: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="contained" color="info" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="info" onClick={onSave}>
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TextEdit;
