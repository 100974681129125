import { Box, styled } from "@mui/material";

export const TopContainer = styled("div")`
  display: flex;
  gap: 20px;
`;

export const ImageContainer = styled("img")`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid gray;
  max-width: unset;
`;

export const UserDataContainer = styled(Box)`
  position: relative;
  display: flex;

  gap: 10px;
  h1 {
    font-weight: 600;
    text-transform: capitalize;
  }
  span {
    display: inline-block;
    word-break: break-word;
    font-weight: 500;
    text-align: left;
  }
  .info-container img {
    width: 151px;
    height: 151px;
    object-fit: cover;
    border-radius: 50%;
    object-position: top;
  }
  @media (min-width: 767px) {
    gap: 50px;
    .info-container {
      min-width: 235px;
    }
  }
  @media (max-width: 767px) {
    .info-container img{
      width: auto;
      height: auto;
      aspect-ratio:1/1;
    }
    .info-container,
    .info-container + div {
      width: 50%;
      text-align: left;
    }
  }

  .edit-btn {
    position: absolute;
    top: 0;
    right: -2px;
  }
  @media (max-width: 992px) {
    .edit-btn {
      top: -26px;
    }
  }

  h5 {
    font-weight: 400;
    text-align: left;
  }
`;
