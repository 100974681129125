import { appApi } from "../apis/apiSlice";

const creditsApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["CREDITS"],
});

export const creditsApi = creditsApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserCredits: builder.query<any, any>({
        query: () => ({
            url: '/user/credits', 
            method: 'GET', 
            keepUnusedDataFor: 0,
            refetchOnMountOrArgChange: true,
        })
    }) 
  }),
});

export const {
    useFetchUserCreditsQuery
} = creditsApi;
