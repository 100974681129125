import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSignupBlock = styled(Box)`
  .signup-wrap {
    border-bottom: 1px solid #fff;
    margin-bottom: 3.5rem;
  }
  .profile-img {
    transform: scale(0.8);
  }
  .stepdetail-img {
    width: 50px !important;
    height: 71px !important;
  }
  .step-img {
    position: absolute;
    left: 2px;
    top: 17px;
    width: 39px;
    height: 80px;
    z-index: 1;
  }
  @media (max-width: 599px) {
    .step-img {
      left: 13px;
    }
    .signup-block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
