import { createSlice } from "@reduxjs/toolkit";

interface initialState {
    completed: number;
}

const INITIAL_STATE: initialState = {
    completed: 25,
};

export const stepperStore = createSlice({
  name: "stepper",
  initialState: INITIAL_STATE,
  reducers: {
    setProfileCompleted(state, action) {
      return {...state, completed: action.payload ? action.payload : state.completed};
    } 
  },
});

export const { setProfileCompleted } = stepperStore.actions;

export default stepperStore.reducer;
