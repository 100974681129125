
import { IApiResponse, IChangePassword } from "../../interfaces/auth";
import { CreateUserResponse, IContactData, IResponseData, UpdateUserResponse } from "../../interfaces/user";
import { appApi } from "../apis/apiSlice";

const authApiWithTag = appApi.enhanceEndpoints({
    addTagTypes: ["USERS"]
});

export const authApi = authApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<CreateUserResponse, any>({
      query: (body) => {
        return {
          url: '/user/profiles',
          method: 'PATCH',
          body,
        };
      },
    }),

    updateUser: builder.mutation<UpdateUserResponse, any>({
      query: (body) => ({
        url: 'user/profiles',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['USERS'],
    }),

    changePassword: builder.mutation<IApiResponse, IChangePassword>({
      query: ({confirm_password, password }) => {
        return {
          url: 'user/update-password',
          method: 'POST',
          body: {
           confirm_password,
            password,
          },
        };
      },
    }),

    fetchResidences: builder.query({
      query: () => ({ url: '/user/residences' }),
    }),

    fetchNeighbourhoods: builder.query({
      query: () => ({ url: '/user/neighbourhoodtypes' }),
    }),

    fetchStateCities: builder.query({
      query: (query) => ({
        url: '/user/citystate/' + `?city_name=${query}`,
      }),
    }),

    fetchUser: builder.query<any, any>({
      query: () => ({
        method: 'GET',
        url: '/user/profiles',
        keepUnusedDataFor: 0,
        refetchOnMountOrArgChange: true,
      }),
      providesTags: ['USERS'],
    }),

    fetchUserWithToken: builder.query<any, any>({
      query: (token) => ({
        method: 'GET',
        url: '/user/profiles',
        providesTags: ['USERS'],
        Authorization: `Token ${token}`,
      }),
    }),

    contact: builder.mutation<IResponseData, IContactData>({
      query: ({ name, email, message, type }) => {
        return {
          url: '/contact-us/',
          method: 'POST',
          body: {
            name,
            email,
            message,
            type,
          },
        };
      },
    }),
  }),
});

export const {
    useFetchUserQuery,
    useCreateUserMutation, 
    useLazyFetchUserWithTokenQuery, 
    useUpdateUserMutation,
    useChangePasswordMutation,
    useContactMutation, 
    useFetchResidencesQuery, 
    useFetchNeighbourhoodsQuery, 
    useFetchStateCitiesQuery, 
    useLazyFetchStateCitiesQuery, 
} = authApi;
