import * as Yup from 'yup';

export const PetSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'), 
});

export const petSchema = Yup.object().shape({
    profile_image: Yup.mixed().nullable().required('Profile Image is required'),
    name: Yup.string().required('Name is required'), 
    gender: Yup.string().oneOf(['M', 'F']).required('Gender is required'), 
    pet_type_id: Yup.number().nullable().required('Pet Type is required'), 
    primary_breed_id: Yup.number().nullable().required('Breed is required'), 
    secondary_breed_id: Yup.number().nullable(), 
    color_ids: Yup.string().required('Color is required'), 
    marking_ids: Yup.string().required('Marking is required'), 
    description: Yup.string().required('Description is required'), 
    size: Yup.string().required('Size is required'), 
    unique_characteristics: Yup.string().max(100, "Characteristics can't be longer than 100 characters").nullable(), 
  });