import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const MyAccountContainer = styled("div")`
.MuiPaper-elevation{
 display: block !important;
}
  display: flex;
  align-items: flex-start;
  jusify-content: "flex-start";
  .account-card > div > div {
    width: 100%;
  }
  @media (min-width: 320px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

