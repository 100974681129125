import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


  export const StyledBlockImg = styled(Box)`
  @media(max-width: 599px){
      height: 100% !important;
   img{
      width: calc(100% + 32px);
      max-width: initial;
    }
  }

  `;