import { store } from '../redux';

// Get auth token from store or persist localStorage key
export function getAuthToken() {
  const { auth }: any = store.getState();
  const token = auth.token;
  if (!token) {
    const persist = JSON.parse(localStorage.getItem('persist:root') || '{}');
    if (persist?.auth) {
      const auth = JSON.parse(persist.auth || '{}');
      if (auth.token) {
        return auth.token;
      }
    }
  } else {
    return token;
  }
  removeAuthToken();
  return undefined;
}

export function removeAuthToken() {
    localStorage.removeItem('persist:root');
  }


export function getIsAuthenticated() {
    const { auth } = store.getState();
    const isAuthenticated = auth.isAuthenticated;
    if (!isAuthenticated) {
      const persist = JSON.parse(localStorage.getItem('persist:root') || '{}');
      if (persist?.auth) {
        const auth = JSON.parse(persist.auth || '{}');
        if (auth.isAuthenticated) {
          return auth.isAuthenticated;
        }
      }
    } else {
      return isAuthenticated;
    }
    removeIsAuthenticated();
    return undefined;
  }

  export function removeIsAuthenticated() {
    localStorage.removeItem('persist:root');
  }



export function getUserData() {
  const { user } = store.getState();
  const userData = user;
  if (!userData) {
    const persist = JSON.parse(localStorage.getItem('persist:root') || '{}');
    if (persist?.user) {
      const userDetails = JSON.parse(persist.user || '{}');
      if (userDetails) {
        return userDetails;
      }
    }
  } else {
    return userData;
  }
  removeUserData();
  return undefined;
}

export function removeUserData() {
  localStorage.removeItem('persist:root');
}