import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
interface IModalProps {
    children: JSX.Element;
    btnTitle: string;
  }
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '690px',
    bgcolor: 'secondary.light',
    boxShadow: 24,
    p: 3,
    maxHeight: '90vh',
    overflowY: 'auto',
    borderRadius:'10px',
  };

export default function BasicModal({
    children,btnTitle
  }: IModalProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} sx={{mb:3}}  color="secondary" variant="contained">{btnTitle}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}