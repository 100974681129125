import ImageBanner from "../../components/ImageBanner";
import { Media } from "../../config/media";
import { Helmet } from "react-helmet-async";
import FeaturesPetDetails from "../../components/FeaturesPetDetails";
import GetInTouch from "../../components/GetInTouch";
import { useDispatch, useSelector } from "react-redux";
import { useGetPageContentQuery } from "../../redux/textEdit/editorApi";
import { useEffect } from "react";
import { addFeaturesPageContent } from "../../redux/textEdit/editorSlice";
import { useContent } from "../../hooks/useContent";
import { RootState } from "../../redux";
import { GlobalRoutes } from "../../types";

function Features() {
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('features');
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If features page data found, set it to redux
    if (data) {
      dispatch(addFeaturesPageContent(data?.data.features));
    }
  }, [data, dispatch]);
  return (
    <>
      <Helmet>
        <title>Features | MypetsText</title>
      </Helmet>
      <ImageBanner img={Media.BannerAboutImage} />
      <FeaturesPetDetails page="features" sectionName="feature" />
      {isAuthenticated === false && <GetInTouch
        title="Sign Up Today!"
        subtitle="Create a profile for your pet and start received messages from
your pet. Sorry, only dogs and cats supported."
        btntext="Try It Free"
        page="features"
        sectionName="cta"
        link={GlobalRoutes.PRICING}
      />}
    </>
  );
}

export default Features;
