import { Box, styled } from "@mui/material";

export const DayContainer = styled(Box)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin: 0 auto;
  &.active {
    background: #f6911e;
    color: #fff;
    span {
      color: #fff;
    }
    & p {
      color: #ffffff;
    }
  }
`;

export const CalenderRow = styled(Box)`
  padding-top: 23px !important;
  width: 100%;
  padding-bottom: 23px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #fff;
  
   .MuiCheckbox-root {
    background-color: #fff !important;
   }
  
`;
