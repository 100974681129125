import React, { useEffect, useState } from "react";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import {
  daysArr,
  scheduleTimes,
} from "../../../config/constants";
import { ScheduleTimesType } from "../../../types/schedule";
import { convertTimeRange } from "../../../utils/helper";

type MessageFrequencyPropsType = {
  setFieldValue: any;
  allScheduleTimes: ScheduleTimesType[] | undefined;
  values?: any;
  timezone: any;
};

const DailyMessageFrequency: React.FC<MessageFrequencyPropsType> = ({
  setFieldValue,
  allScheduleTimes, 
  values, 
  timezone,
}) => {
  const [dailyMsgFrequency, setDailyMsgFrequency] = useState<string[]>([]);

  // Initializing state
  useEffect(() => {
    if(values && values?.message_frequency === 'Daily') {
      setDailyMsgFrequency(values['monday']?.split(',') || []);
    } else {
      setDailyMsgFrequency([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  //Setting formik's state
  useEffect(() => {
    if(dailyMsgFrequency) {
      daysArr.forEach((day: string) => {
        setFieldValue(day, dailyMsgFrequency.join(','));
      });
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyMsgFrequency]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { checked, value } = e.target;
    if (checked) {
      if (!dailyMsgFrequency.includes(value)) {
        setDailyMsgFrequency([...dailyMsgFrequency, value]);
      }
    } else {
      if (dailyMsgFrequency.includes(value)) {
        setDailyMsgFrequency(
          dailyMsgFrequency?.filter((f) => f !== value) || []
        );
      }
    }
  };

  return (
    <Box>
      {allScheduleTimes && allScheduleTimes.map(({ id, title, start_time, end_time }: any) => (
        <Stack
          key={id}
          direction="row"
          sx={{
            alignItems: "flex-start",
            gap: "10px",
            mb: 2,
          }}
        >
          <Checkbox
            value={title}
            checked={dailyMsgFrequency.includes(title)}
            onChange={handleChange}
            sx={{ position: "relative", top: "5px" }}
          />
          <Stack direction="column">
            <Typography variant="body1" color="info.darker">
              {title.toLowerCase() === 'latenight' ? "Late Night" : title[0].toUpperCase() + title.slice(1).toLowerCase()}
            </Typography>
              <Typography
                color="info.darker"
                sx={{ fontSize: "1rem !important", lineHeight: "1.695rem" }}
              >
                ({convertTimeRange(start_time, end_time)}){" "}<strong>{timezone}</strong> 
              </Typography>
          </Stack>
        </Stack>
      ))}
    </Box>
  );
};

export default DailyMessageFrequency;
