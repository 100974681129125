import React, { useEffect, useState } from 'react';
import ImageBanner from '../../components/ImageBanner';
import Accordian from '../../components/Accordian';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import { Media } from '../../config/media';
import { Helmet } from 'react-helmet-async';
import GetInTouch from '../../components/GetInTouch';
import { EditTextContainer, StyledPetsSteps } from './styles';
import BubbleCard from './BubbleCard';
import TextEdit from '../../components/TextEdit';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPageContentQuery } from '../../redux/textEdit/editorApi';
import { addHowItWorksPageContent } from '../../redux/textEdit/editorSlice';
import { useContent } from '../../hooks/useContent';
import { useFetchAllFaqsQuery } from '../../redux/faqs/faqApi';
import { useNavigate } from 'react-router-dom';
import { GlobalRoutes } from '../../types';
import { RootState } from '../../redux';

function HowWorks() {
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('howItWorks');
  const { data: faqsData } = useFetchAllFaqsQuery({});
  const navigate = useNavigate();
  const  { isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If home page data found, set it to redux
    if (data) {
      dispatch(addHowItWorksPageContent(data?.data.howItWorks));
    }
  }, [data, dispatch]);

  const handleMoreQuestions = () => {
    navigate(GlobalRoutes.FAQ);
  }

  let _html = useContent('howItWorks', 'direction');


  return (
    <>
      <Helmet>
        <title>How it Works | MypetsText</title>
      </Helmet>
      <ImageBanner img={Media.TestimonialBannerImg} />
      <Box 
      component="section"
        sx={{ bgcolor: 'primary.main', px: 2, paddingTop: '3.5rem' }}
        className="h2-style body1-style text-white"
      >
        <Container fixed maxWidth="xl">
          <Box
            sx={{
              paddingBottom: '3rem',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <TextEdit page="howItWorks" sectionName="direction" _html={_html}>
              <>
                <Typography
                  variant="h2"
                  sx={{ color: 'primary.contrastText', mb: 1 }}
                >
                  How It Works
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'primary.contrastText',
                    lineHeight: '1.6',
                  }}
                >
                  Sign up, customize your pet’s profile, and start receiving
                  daily texts and images. It’s that simple!
                </Typography>
              </>
            </TextEdit>
          </Box>
        </Container>
      </Box>
      <StyledPetsSteps sx={{ px: { md: 5 } }} component="section">
        <Container fixed maxWidth="xl" className="steps-wrap">
          <BubbleCard
            title="Signup for free"
            discription="Get 100 credits free for during our Alpha Test!"
            stepimgurl={Media.StepOne}
            graphicimgurl={Media.WelcomeImage}
          />
          <BubbleCard
            title="Create a profile"
            discription="Tell us about yourself and where you live."
            stepimgurl={Media.StepTwo}
            graphicimgurl={Media.ProfileBubbleImage}
            className="right-one"
          />
          <BubbleCard
            title="Select your pet’s breed & customize preferences"
            discription="Pick from 100s of breeds or mix multiple breeds, colors, and
                    markings."
            stepimgurl={Media.StepThree}
            graphicimgurl={Media.PetsBreedImage}
            className="petsgraphic-bottom"
          />
          <BubbleCard
            title="Schedule frequency & timing of your texts"
            discription="You can schedule your text daily, or weekly, and pick your
                    preferred time ranges."
            stepimgurl={Media.StepFour}
            graphicimgurl={Media.ScheduleFreqImage}
            className="right-one petsgraphic-bottom"
          />
          <BubbleCard
            title="Receive daily texts from your pet"
            discription="You will automatically receive custom text messages as if it
                    was from your pet."
            stepimgurl={Media.StepFive}
            graphicimgurl={Media.ReceiveTextImage}
            className="petsgraphic-bottom"
          />
        </Container>
      </StyledPetsSteps>
      <Box sx={{ mt: 7 }} component="section">
        <Container fixed maxWidth="xl">
          <Box textAlign="center">
            <Typography variant="h1" mb={2}>
              Frequently Asked Questions (FAQs)
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Grid item container spacing={2} justifyContent="center">
                <Grid item sm={9} xs={12}>
                  {faqsData?.data.length &&
                    faqsData?.data.slice(0, 5).map((faq: any, ind: number) => (
                      <Accordian
                        title={faq.question}
                        content={faq.answer}
                        accId={String(ind)}
                      />
                    ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Box sx={{ textAlign: 'center', my: { xs: 5, sm: 6, md: 7, lg: 8 } }}>
        <Button variant="contained" onClick={handleMoreQuestions}>Got More Questions?</Button>
      </Box>
      </Box>

      { isAuthenticated === false && <GetInTouch
        page="howItWorks"
        sectionName="cta"
        title="Sign Up Today!"
        subtitle="Create a profile for your pet and start received messages from
          your pet. Sorry, only dogs and cats supported."
        btntext="Try It Free"
        link={GlobalRoutes.PRICING}
      />}
    </>
  );
}

export default HowWorks;
