import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { StyledBlockImg } from "./styles";
import { Media } from "../../config/media";
import LazyLoad from "react-lazyload";

function SideImgBanner() {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <LazyLoad
      once
      debounce={500}
    >
      <StyledBlockImg
        className="side-img"
        sx={{
          maxWidth: { xs: '100%', md: '50%' },
          position: 'absolute',
          left: '0',
          width: '100%',
          height: { xs: '100%', sm: '30vh', md: '100%' },
        }}
      >

        <Box
          component="img"
          sx={{
            objectPosition: 'top',
            width: '100%',
            height: '100%',
            left: { xs: '-16px', sm: '0' },
            position: { xs: 'absolute', sm: 'static' },
            visibility: loading ? 'hidden' : 'visible',
          }}
          alt={loading ? '' : 'Banner Image'}
          className="img-style img-style__cover"
          src={Media.SideBannerImg}
          loading="eager"
          onLoad={() => setLoading(false)}
        />
      </StyledBlockImg>

    </LazyLoad>
  );
}

export default SideImgBanner;
