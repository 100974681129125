import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ImageBanner from "../../components/ImageBanner";
import GetInTouch from "../../components/GetInTouch";
import { Media } from "../../config/media";
import ShowcasePets from "../../components/ShowcasePets";
import TestimonialSlider from "../../components/TestimonialSlider";

import {
  Box,
  Checkbox,
  TextField,
  Container,
  Typography,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import { getIsAuthenticated } from "../../utils/sessions";
import { useDispatch } from "react-redux";
import { useGetPageContentQuery } from "../../redux/textEdit/editorApi";
import { addMyPetsPageContent } from "../../redux/textEdit/editorSlice";
import { useContent } from "../../hooks/useContent";

const title = "My Pets | Mypets Text";
function MyPets() {
  const isAuthenticated = getIsAuthenticated();
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('mypets');

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If My Pets page data found, set it to redux
    if(data) {
      dispatch(addMyPetsPageContent(data?.data.mypets)); 
    }
  }, [data, dispatch]);
  return (
    <Box>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ShowcasePets isAuthenticated={isAuthenticated} />
      <GetInTouch page="mypets" sectionName="cta" title="Get In Touch With Us!" subtitle="Email us at info@mypetstext.com" btntext="Contact Now"/>
    </Box>
  );
}

export default MyPets;
