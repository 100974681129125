import React from "react";
import { StyledSignupBlock } from "./styles";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Media } from "../../config/media";
import { Link } from "react-router-dom";
import { GlobalRoutes } from "../../types/routes";

function SignupSection() {
  return (
    <StyledSignupBlock>
      <Box sx={{ bgcolor: "primary.main", px: 2, paddingTop: "3.5rem" }}>
        <Box className="signup-wrap">
          <Container fixed maxWidth="xl">
            <Box
              sx={{
                display: { xs: "block", lg: "flex" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  paddingBottom: "3rem",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{ color: "primary.contrastText", mb: 1 }}
                >
                  Sign Up Today!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "primary.contrastText",
                    lineHeight: "1.6",
                    maxWidth: { lg: "345px" },
                  }}
                >
                  Create a profile for your pet and start generating sharable
                  quotes!
                </Typography>
              </Box>
              <Box
                sx={{ marginLeft: "auto", paddingBottom: "3rem" }}
                className="signup-block"
              >
                <Link to={GlobalRoutes.REGISTER} style={{ opacity: 1 }}>
                  <Button
                    variant="outlined"
                    sx={{ minWidth: "176px" }}
                    className="btn-white"
                  >
                   Sign up for Alpha Test
                  </Button>
                  </Link>
              </Box>
            </Box>
          </Container>
        </Box>
        <Container fixed maxWidth="xl">
          <Grid
            item
            container
            spacing={{ xs: 2, lg: 5}}
            justifyContent="center"
          >
            <Grid item sm={6} lg={4}>
              <Grid item container spacing={2} alignItems="start">
                <Grid item xs={2}>
                  <Box
                    sx={{ maxWidth: { xs: "47px", lg: "75px" } }}
                    component="img"
                    alt="signnup"
                    src={Media.SignUpStep}
                    className="img-style img-style__contain stepdetail-img"
                  />
                </Grid>
                <Grid item xs={10} sx={{ position: "relative" }}>
                  <Box
                    sx={{ maxWidth: { xs: "47px", lg: "75px" } }}
                    component="img"
                    alt="step 1"
                    src={Media.StepOne}
                    className="img-style__contain step-img"
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: "2",
                      marginBottom: { xs: "1rem", sm: "2rem", md: "3rem" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ color: "primary.contrastText", mb: 1 }}
                    >
                      Signup for free
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "primary.contrastText",
                        lineHeight: "1.4",
                        maxWidth: "265px",
                      }}
                    >
                     Get 100 credits free for during our Alpha Test!
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} lg={4}>
              <Grid item container spacing={2} alignItems="start">
                <Grid item xs={2}>
                  <Box
                    sx={{ maxWidth: { xs: "47px", lg: "75px" } }}
                    component="img"
                    alt="pets img"
                    src={Media.AddProfileStep}
                    className="img-style img-style__contain stepdetail-img profile-img"
                  />
                </Grid>
                <Grid item xs={10} sx={{ position: "relative" }}>
                  <Box
                    component="img"
                    alt="step 2"
                    src={Media.StepTwo}
                    className="img-style__contain step-img"
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: "2",
                      marginBottom: { xs: "1rem", sm: "2rem", md: "3rem" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ color: "primary.contrastText", mb: 1 }}
                    >
                      Create a profile
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "primary.contrastText",
                        lineHeight: "1.4",
                        maxWidth: "265px",
                      }}
                    >
                     Tell us about yourself and where you live.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} lg={4}>
              <Grid item container spacing={2} alignItems="start">
                <Grid item xs={2}>
                  <Box
                    sx={{ maxWidth: { xs: "47px", lg: "64px" } }}
                    component="img"
                    alt="generate-quotes img"
                    src={Media.AddPetsStep}
                    className="img-style img-style__contain stepdetail-img"
                  />
                </Grid>
                <Grid item xs={10} sx={{ position: "relative" }}>
                  <Box
                    component="img"
                    alt="step 3"
                    src={Media.StepThree}
                    className="img-style__contain step-img"
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: "2",
                      marginBottom: { xs: "1rem", sm: "2rem", md: "3rem" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ color: "primary.contrastText", mb: 1 }}
                    >
                      Select your pet’s breed 
                      & customize preferences
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "primary.contrastText",
                        lineHeight: "1.4",
                        maxWidth: "265px",
                      }}
                    >
                     Pick from 100s of breeds or mix multiple breeds, colors, and markings.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6} lg={4}>
              <Grid item container spacing={2} alignItems="start">
                <Grid item xs={2}>
                  <Box
                    sx={{ maxWidth: { xs: "47px", lg: "64px" } }}
                    component="img"
                    alt="bond img"
                    src={Media.BondStep}
                    className="img-style img-style__contain stepdetail-img"
                  />
                </Grid>
                <Grid item xs={10} sx={{ position: "relative" }}>
                  <Box
                    component="img"
                    alt="step 4"
                    src={Media.StepFour}
                    className="img-style__contain step-img"
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: "2",
                      marginBottom: { xs: "1rem", sm: "2rem", md: "3rem" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ color: "primary.contrastText", mb: 1 }}
                    >
                      Schedule frequency &
timing of your texts
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "primary.contrastText",
                        lineHeight: "1.4",
                        maxWidth: "265px",
                      }}
                    >
                     You can schedule your text daily, or weekly, and pick your preferred time ranges.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6} lg={4}>
              <Grid item container spacing={2} alignItems="start">
                <Grid item xs={2}>
                  <Box
                    sx={{ maxWidth: { xs: "47px", lg: "64px" } }}
                    component="img"
                    alt="gift img"
                    src={Media.ReminderStep}
                    className="img-style img-style__contain stepdetail-img"
                  />
                </Grid>
                <Grid item xs={10} sx={{ position: "relative" }}>
                  <Box
                    component="img"
                    alt="step 5"
                    src={Media.StepFive}
                    className="img-style__contain step-img"
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: "2",
                      marginBottom: { xs: "1rem", sm: "2rem", md: "3rem" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ color: "primary.contrastText", mb: 1 }}
                    >
                      Receive daily texts 
from your pet
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "primary.contrastText",
                        lineHeight: "1.4",
                        maxWidth: "265px",
                      }}
                    >
                      You will automatically receive custom text messages as if it was from your pet.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </StyledSignupBlock>
  );
}

export default SignupSection;
