export enum Media {
  FacebookLoginButtonIcon = "/assets/icons/facebook-blue.svg",
  GoogleLoginButtonIcon = "/assets/icons/Google__G__Logo.svg",
  EngagementImage = "/assets/images/blue-bg.svg",
  AboutGraphiclgImagePrimary = "/assets/images/shape1.svg",
  AboutGraphiclgImageSecondary = "/assets/images/graphic-secondary.svg",
  AboutGraphiclgImagePrimarysm = "/assets/images/shape2.svg",
  AboutGraphiclgImageSecondarysm = "/assets/images/bubble-secondarysm.svg",
  AboutGraphicsmImage = "/assets/images/shape2.svg",
  AboutImage = "/assets/images/About-image.png",
  PetImage = "/assets/images/dog1.png",
  SignUpStep = "/assets/images/signup.svg",
  StepOne = "/assets/icons/1-icon.svg",
  AddPetsStep = "/assets/images/pets.svg",
  AddProfileStep = "/assets/images/profile.svg",
  StepTwo = "/assets/icons/2-icon.svg",
  GenerateQuotesStep = "/assets/images/generate-quotes.svg",
  StepThree = "/assets/icons/3-icon.svg",
  BondStep = "/assets/images/notify.svg",
  StepFour = "/assets/icons/4-icon.svg",
  ReminderStep = "/assets/images/receive.svg",
  StepFive = "/assets/icons/5-icon.svg",
  TickIcon = "/assets/images/tick.svg",
  StarIcon = "/assets/icons/icon _Star.svg",
  CrownImage1 = "/assets/images/crown1.svg",
  CrownImage2 = "/assets/images/crown2.svg",
  CrownImage3 = "/assets/images/crown2.svg",
  StepThreePrimary = "/assets/images/3.svg",
  TestimonialBg = "/assets/images/testimonial.png",
  PawsOrangeXs = "/assets/images/orange-paws-mobile.svg",
  PawsOrangeSm = "/assets/images/orange_paws.svg",
  PawsTransparentXs = "/assets/images/paws.svg",
  PawsTransparentSm = "/assets/images/green-paws-bg-2.svg",
  SideBannerImg = "/assets/images/girl-with-dog.svg",
  BackIcon = "/assets/icons/back.svg",
  BannerImage = "/assets/images/girl-with-dog.svg",
  BannerAboutImage = "/assets/images/about-banner.jpg",
  AboutPetsTextImage = "/assets/images/about-mypetstext.png",
  AboutOurVissionImage = "/assets/images/our-vision.png",
  BannerContactImage = "/assets/images/ContactBanner.png",
  BubbleImg = "/assets/images/bubble-img.svg",
  OurStoryImg = "/assets/images/our-story.png",
  BrowseIcon = "/assets/icons/browse.svg",
  UploadIcon = "/assets/icons/upload-photo.svg",
  PetImages ="assets/images/petImg.jpg",
  AddIcon = "/assets/icons/add.svg",
  SubIcon = "/assets/icons/sub.svg",
  TestimonialBannerImg = "/assets/images/testimonial-banner.png",
  Profile = "/assets/images/profile.png",
  QuotesIcon = "/assets/icons/quote-icon.svg",
  QuotesIconSecondary = "/assets/icons/quote-icon-secondary.svg",
  TopCurve = "/assets/images/top-curve.png",
  DummyUser = "/assets/images/dummy-user.png",
  BannerMypetsImage = "/assets/images/mypets.jpg",
  PetDefaultImage = "/assets/images/404.svg",
  PetPlaceholderImage ="/assets/images/dog-placeholder.png",
  QuestionIconImage ="/assets/icons/question.svg",  
  InfoIconPrimary = "/assets/icons/Info.png",
  Breed = "/assets/images/breed.png", 
  Color = '/assets/images/color.png', 
  Marking = '/assets/images/marking.png', 
  NoImage = '/assets/images/no-image.png', 
  NoImageSvg = '/assets/images/no-image.png', 
  DogPlaceholderImage = '/assets/images/dog-breed.png', 
  CatPlaceholderImage = '/assets/images/cat-breed.jpeg', 
  CarouselPetImage = '/assets/images/pet-img.png', 
  LogoGraphicImage = '/assets/images/logo-graphic.png',
  WelcomeImage = '/assets/images/welcome-img.png',
  BlueBubbleImage = '/assets/images/blue-bubble.png',  
  BubbleImage = '/assets/images/blue-bubble.png',  
  PetsBreedImage = '/assets/images/pets-breed.png',
  PawsLineImage = '/assets/images/paws-line.png',
  ProfileBubbleImage = '/assets/images/profile-bubbleimg.png',
  ScheduleFreqImage = '/assets/images/schedule-frequency.png',
  ReceiveTextImage = '/assets/images/receive-text.png',
  PetImageOne = '/assets/images/petimage.png',
  PetImageTwo = '/assets/images/Pet-img2.png',
  PetPlaceholder = '/assets/images/pet-placeholder.png',
  UserProfilePlaceholder = '/assets/images/profile-placeholder.svg',
  TikTokIcon = '/assets/icons/tiktok.svg', 
  NoMessage = '/assets/images/no-message.jpg',
  PricingBanner = '/assets/images/pricing-banner.png',
  AboutBanner = '/assets/images/about-banner.png',
  FaqBanner = '/assets/images/faq-banner.png',
}
