import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Message } from "../../config/constants";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <Box sx={{ my: 5 }}>
      <Helmet>
        <title> {Message.PrivacyPolicy} | MypetsText </title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <Box>
          <Box>
            <Typography variant="h1" sx={{ my: 3 }}>
              Privacy Policy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              The last update to our Privacy Policy was posted on November 15,
              2023. This document governs the privacy policy of our Website,
              <Link to="/"> Mypetstext.com</Link>. Any capitalized terms not
              defined herein will have the same meaning as where they are
              defined elsewhere on our Website.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Definitions
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              “Non-Personal Information” (NPI) is information that is in no way
              personally identifiable and that is obtained automatically when
              you access our Website with a web browser.
            </Typography>
            <Typography variant="body2">
              “Personally Identifiable Information” (PII) is non-public
              information that is personally identifiable to you and obtained
              for us to provide you with a product or service. PII may include
              information such as your name, address, phone number, credit card
              information, and other related information that you provide to us.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Our Commitment to Your Privacy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              Our Privacy Policy tells you what PII we may collect from you, how
              we may share your PII, and how you can limit our sharing of your
              PII.
            </Typography>
            <Typography variant="body2">
              MY PETS TEXT is committed to the security and privacy of all our
              customers. We take your privacy seriously, and we will work with
              you to ensure that you have an enjoyable online experience.
            </Typography>
            <Typography variant="body2">
              MY PETS TEXT and our affiliates respect your privacy and recognize
              the need for appropriate protection and management of your PII you
              share with us.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Links to Other Websites
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              Our Website may contain links to other websites. You understand
              that these websites are not under our control and are not subject
              to our Privacy Policy. These websites will likely have their own
              privacy policies. We have no responsibility for these websites and
              provide links to these websites solely for your convenience.
            </Typography>
            <Typography variant="body2">
              You acknowledge that your use and access of these websites is
              solely at your own risk. It is your responsibility to check the
              privacy policies of these websites to see how they treat your
              personal information.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Information We Collect
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              Generally, you control the amount and type of information you
              provide to us when using our Website. As a visitor to our Website,
              you can browse our Website to find out more about us. You are not
              required to provide us with any PII as a Visitor.
            </Typography>
            <Typography variant="body2">
              However, if you register as a Member to use our Website, you must
              provide PII in order for us to provide you with various features
              and/or functionality from our Website. As a Member, we collect
              your PII in the following ways:
            </Typography>
            <Typography variant="body2">
              There may be online forms used in our Website. The information you
              enter into these online forms may contain PII.
            </Typography>
            <Typography variant="body2">
              Our Website may offer premium features for which you must pay to
              use. If we were to charge you for the use of these premium
              features, we would need to collect payment source information
              (i.e. credit card) to bill you for using such premium features.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Computer Information Collected
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              When you use our Website, we automatically collect certain
              computer information by the interaction of your mobile phone or
              web browser with our Website. Such information is typically
              considered NPI. We may also collect any of the following:
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Cookies{" "}
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              Our Website uses “Cookies” to identify the areas of our Website
              that you have visited. A Cookie is a small piece of data stored on
              your computer or mobile device by your web browser. We use Cookies
              to personalize the Content that you see on our Website. Most web
              browsers can be set to disable the use of Cookies. However, if you
              disable Cookies, you may not be able to access functionality on
              our Website correctly or at all. We never place PII in Cookies.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Web Beacons
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              We may also use a technology called, web beacons, to collect
              general information about your use of our Website and your use of
              special promotions or newsletters. The information we collect by
              Web Beacons allows us to statistically monitor the number of
              people that open our emails. Our Web Beacons are not used to track
              your activity outside of our Website. We do not link NPI from Web
              Beacons to PII without your permission.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Automatic Information
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              We automatically receive information from your web browser or
              mobile device. This information includes the name of the website
              from which you entered our Website, if any, as well as the name of
              the website to which you’re headed when you leave our website.
              This information also includes the IP address of your
              computer/proxy server that you use to access the Internet, your
              Internet Website provider name, web browser type, type of mobile
              device, and computer operating system. We use all of this
              information to analyze trends among our Users to help improve our
              Website.
            </Typography>
            <Typography variant="body2">
              Google, as a third party vendor, uses cookies to serve ads on our
              site. Google’s use of the DART cookie enables it to serve ads to
              your users based on their visit to your sites and other sites on
              the Internet. Users may opt out of the use of the DART cookie by
              visiting the Google ad and content network privacy policy at the
              following link:
            </Typography>
            <Typography variant="body2">
              <Link
                target="_blank"
                to="http://www.google.com/policies/technologies/ads"
              >
                http://www.google.com/policies/technologies/ads
              </Link>
            </Typography>
            <Typography variant="body2">
              This site uses Google Analytics. This site has implemented
              features based on Display Advertising, including Remarketing,
              Google Display Network Impression Reporting and Google Analytics
              Demographics and Interest Reporting). Third-party vendors,
              including Google, may show our ads on sites across the Internet.
              This site and third-party vendors, including Google, use
              first-party cookies (such as the Google Analytics cookie) and
              third-party cookies (such as the DoubleClick cookie) together to
              inform, optimize, and serve ads based on someone’s past visits to
              this website. This data reports how our ad impressions, other uses
              of ad services, and interactions with these ad impressions and ad
              services are related to visits to your site.
            </Typography>
            <Typography variant="body2">
              You can go to Google’s Ads Settings to opt-out of Google Analytics
              for Display Advertising as well as customize the Google Display
              Network ads you are presented.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              How Information is Used
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              We use PII that you provide for our internal purposes, such as
              offering our services to you, confirming and tracking your order,
              subscription or registration; analyzing statistics and
              demographics; and informing you of new products, services, and
              offers as provided by us and/or our affiliates.
            </Typography>
            <Typography variant="body2">
              Our affiliates may have products, services, or offers about which
              you may be interested in receiving information and they may use
              the PII we have for you to contact you. If you don’t want to
              receive information from our affiliates, you can let us know so
              you’re no longer contacted by our affiliates about their products,
              services, or offers.
            </Typography>
            <Typography variant="body2">
              We may also combine information you give us online or through
              printed forms; publicly available information; and information we
              receive from other parties. We use this combined information for
              your online experience and to let you know about new products,
              services, offers, and other promotion.
            </Typography>
            <Typography variant="body2">
              To give you the best possible service, we may use our affiliates
              to provide contracted services for our business, including, but
              not limited to processing credit cards, hosting our website,
              shipping products to you, or advertising. In many cases, we will
              need to provide some if not all the PII you provide to use so our
              affiliates can perform these services. Our affiliates are only
              authorized to use your PII as provided within this Privacy Policy.
            </Typography>
            <Typography variant="body2">
              We use data from Google’s Interest-based advertising and 3rd-party
              audience data (such as age, gender, and interests) with Google
              Analytics to determine additional web site content, advertising
              content, and other business processes.
            </Typography>
            <Typography variant="body2">
              There are times that we may have to disclose your PII to comply
              with state and federal laws; to assist law enforcement and
              governmental agencies in preventing or investigating fraud or
              other crimes, or in response to a court order. In such instances,
              we will only provide the PII requested and your PII will only be
              used for legal purposes as opposed to marketing.
            </Typography>
            <Typography variant="body2">
              Finally, if we sold or transferred our business, we would transfer
              your PII to the purchaser as appropriate and might also retain a
              copy of your PII for any new business ventures we would start.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5"sx={{ mt: 4,mb:1 }}>
              Changing Your Information
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              You may change your PII at any time using facilities found on our
              Website. If you need assistance with updating your PII or removing
              yourself from our mailing lists, just send us an email with your
              request or contact us using the Contact us information found on
              our Website.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Opting Out of Sharing Information
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              You can always opt out of future unaffiliated third-party
              disclosures of your information. Such opt-out will not affect
              disclosures otherwise permitted by law including but not limited
              to: (i) disclosures to affiliates, (ii) disclosures to third-party
              service providers who provide certain services for our business,
              such as credit card processing, computer system services,
              shipping, data management, or promotional services, (iii)
              disclosures to third parties as necessary to fulfill your
              requests, (iv) disclosures to governmental agencies or law
              enforcement departments or otherwise required to be made under
              applicable law, (v) previously completed disclosures to third
              parties, or (vi) disclosures to third parties in connection with
              subsequent contests or promotions you may choose to enter, or
              third-party offers you may choose to accept.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              “Do Not Track” Settings
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              Some web browsers have settings that enable you to request that
              our Website does not track your movement within our Website. Our
              Website does not obey such settings when transmitted to and
              detected by our Website
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Protecting Your Child’s Privacy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              We follow the Children’s Online Privacy Protection Act (COPPA).
              Even though our Website is not designed for use by anyone under
              the age of 18 (child), we realize that a child may attempt to
              access our Website. We do not knowingly collect PII from a child.
              If you are a parent or guardian and believe your child is using
              our Website, please contact us. We may ask for proof of
              identification before we remove any information to prevent
              malicious removal of account information. If we discover on our
              own that a child is accessing our Website, we will delete the
              information as soon as we discover it, we will not use the
              information for any purpose, and we will not disclose the
              information to third parties. You acknowledge that we do not
              verify the age of our users nor do we have any liability to do so.
              If you are a child, please seek the permission of a parent or
              guardian before accessing our Website.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Our Email Policy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              Our affiliates and we fully comply with the federal CAN-SPAM Act.
              You can always opt out of receipt of further email correspondence
              from us or our affiliates. We will not sell, rent, or trade your
              email address to any unaffiliated third-party without your
              permission.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Our Security Policy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              {" "}
              We have taken steps to build our Website using sophisticated
              encryption and authentication tools to protect the security of
              your PII. When we collect your PII through our Website, we will
              encrypt your PII before it travels over the Internet using
              industry standards as establishing for conducting secure online
              transactions. We also use industry standard technologies such as
              secure routers and fire walls to make sure that your PII is safe.
              Unfortunately, we cannot fully guarantee secure data transmission
              over the Internet because of its nature. Once we receive your PII,
              we have industry standard security measures in place to protect
              against the loss or misuse of your PII, though again, we cannot
              fully guarantee against such loss or misuse. We strongly urge you
              to protect any password you may have for our Website and not share
              it with anyone. You should always log out of our Website when you
              are done using it, especially if you are sharing a computer with
              someone else or are using a computer in a public place.
            </Typography>
          </Stack>
        </Box>

        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Use of Credit Cards
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              You may have to provide a credit card to buy products and services
              from our Website. We use third-party billing services and HAVE NO
              CONTROL over these services. We use our commercially reasonable
              efforts to make sure that your credit card number is kept STRICTLY
              CONFIDENTIAL by using only third-party billing services that use
              industry-standard encryption technology to protect your credit
              card number from unauthorized use. However, you understand and
              agree that we are in no way responsible for any misuse of your
              credit card number.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Changes to Our Privacy Policy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              As necessary to address changes in laws or our business practices,
              we may modify our Privacy Policy, in whole or in part, to address
              these changes. We will typically notify users by some message on
              our Website home page that our Privacy Policy has changed. We will
              also change the “Last Updated” date at the beginning of this
              Privacy Policy. Any changes we make to our Privacy Policy are
              effective as of this Last Updated date and replace any prior
              Privacy Policies. We encourage you to review our Privacy Policy
              periodically to make sure you still agree with it.
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" sx={{ mt: 4,mb:1 }}>
              Questions about Our Privacy Policy
            </Typography>
          </Box>
          <Stack spacing={2}>
            <Typography variant="body2">
              If you have any questions about our Privacy Policy, please contact
              us using the Contact us information found on our Website.
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default PrivacyPolicy;
