import { Box } from "@mui/material";
import EditProfileForm from "../../../components/my-account/EditProfileForm";
import { Helmet } from "react-helmet-async";

const EditProfile = () => {
  return (
    <>
      <Helmet>
        <title>Edit Profile | MypetsText</title>
       </Helmet>
      <Box>
        <EditProfileForm />
      </Box>
    </>
  );
};

export default EditProfile;
