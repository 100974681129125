import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
function HelmetTitle() {
  const location = useLocation();
  const segment = location?.pathname.split("/").pop();
  const title = segment?.replace(/-/g, " ").toLocaleUpperCase();
  return (
    <Helmet>
      <title> {title} | MypetsText </title>
    </Helmet>
  );
}

export default HelmetTitle;
