export default function MuiDataTable(theme: any) {
  return {
    MUIDataTableBodyCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          fontWeight: 800,
        },
      },
    },
  };
}
