import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AlertDialogPropsType = {
  title?: string;
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  handleClose: () => void;
  isLoading?: boolean;
};

const AlertDialog: React.FC<AlertDialogPropsType> = ({
  title = "Are you sure to delete?",
  handleConfirm,
  handleCancel,
  handleClose,
  open,
  isLoading,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>
        {"Are you sure to delete?"}
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" color="error">
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="success"
          onClick={handleConfirm}
        >
          { isLoading && <CircularProgress size={24} color="primary" /> }
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
