import ImageBanner from "../../components/ImageBanner";
import { Media } from "../../config/media";
import { Helmet } from "react-helmet-async";
import FeaturesPetDetails from "../../components/FeaturesPetDetails";
import SubscriptionPlans from "../../components/SubscriptionPlans";
import { useDispatch } from "react-redux";
import { useGetPageContentQuery } from "../../redux/textEdit/editorApi";
import { useEffect } from "react";
import { addPricingPageContent } from "../../redux/textEdit/editorSlice";

function Pricing() {
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('pricing');

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If pricing page data found, set it to redux
    if(data) {
      dispatch(addPricingPageContent(data?.data.pricing)); 
    }
  }, [data, dispatch]);

  return (
    <>
      <Helmet>
        <title>Pricing | MypetsText</title>
      </Helmet>
      <ImageBanner img={Media.PricingBanner} />
      <SubscriptionPlans />
    </>
  );
}

export default Pricing;
