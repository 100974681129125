import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";


type InitialStateType = {
    open: boolean;
    title: string;
    severity?: AlertColor | undefined;
    anchorOrigin?: any; 
    autoHideDuration?: number;
    sx?: any;
}

const initialState: InitialStateType = {
    open: false, 
    title: 'Welcome', 
    severity: 'success',  
    anchorOrigin: { vertical: 'top', horizantal: 'right'}, 
    autoHideDuration: 6000, 
    sx: {}, 
}

const snackbarSlice = createSlice({
    name: 'snackbar', 
    initialState, 
    reducers: {
        closeSnackbar: (state, action) => {
            return { ...state, ...action.payload };
        }, 
        openSnackbar: (state, action) => {
            const { payload } = action; 
            return { ...state, ...payload, severity: payload?.severity ? payload?.severity : 'success',   open: true };
        }, 
    }
});

const { closeSnackbar, openSnackbar } = snackbarSlice.actions;
const snackbarReducer =  snackbarSlice.reducer;
export {
    closeSnackbar, 
    openSnackbar, 
};

export default snackbarReducer;