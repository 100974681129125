interface ResponsiveFontSizesProps {
  sm: number;
  md: number;
  lg: number;
}

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: ResponsiveFontSizesProps) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}
export default function Table(theme: any) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.grey[900],
          borderBottom: '1px solid #E6F8F7',
          borderRight: '1px solid #E6f8f7',
          fontSize: pxToRem(14),
          ...responsiveFontSizes({ sm: 16, md: 18, lg: 20}),
        },
        body:{
          borderBottom: '1px solid #E6F8F7',
          borderRight: '1px solid #E6f8f7',
          color: theme.palette.info.darker,
          fontSize: pxToRem(15),
          ...responsiveFontSizes({ sm: 16, md: 17, lg: 18}),
          padding: '17px 14px 11px',
        }
      },
    },
  };
}
