import React from "react";
import Carousel from "react-material-ui-carousel";
import { StyledPetsBlock } from "./styles";
import { Box, Button, Container, Typography } from "@mui/material";
import { Media } from "../../config/media";
interface IPetsOwnerSliderProps {
  className?: string;
  ratings: boolean;
  maintitle: string;
  btntitle?: string;
  btngenerate?: boolean;
}
function PetsOwnerSlider({
  className,
  ratings,
  maintitle,
  btntitle,
  btngenerate,
}: IPetsOwnerSliderProps) {
  var items = [
    {
      description:
        "In every tail wag and joyful bark, I'm endlessly grateful for the love and joy you continuously bring into my life. 🐾✨",
      title: "Elizabeth H",
    },
    {
      description:
        "Thanks for always sharing your snacks and belly rubs, and for pretending I don't snore louder than a freight train. You're pawsitively amazing! 🐾",
      title: "Elizabeth H",
    },
  ];
  return (
    <StyledPetsBlock>
      <Box
        sx={{ position: "relative", py: 9,      backgroundImage: {
          xs: `url("${Media.PawsOrangeXs}")`,
          sm: `url("${Media.PawsOrangeSm}")`,
        }, }}
        textAlign="center"
        className={`testimonial-sec ${className}`}
      >
        <Box
          component="img"
          alt="blue background"
          src={Media.TestimonialBg}
          className="img-style img-style__cover img-absolute"
        />
        <Container fixed maxWidth="xl">
          <Typography
            variant="h2"
            sx={{ position: "relative", zIndex: "2", mb: 2 }}
          >
            {maintitle}
          </Typography>
          <Carousel
            className="testimonial-sec__content"
            indicatorIconButtonProps={{
              style: {
                cursor: "pointer",
                transition: "200ms",
                padding: 0,
                color: "#FEF4E8",
              },
            }}
            animation="fade"
            activeIndicatorIconButtonProps={{ style: { color: "#F6911E" } }}
          >
            {items.map((item, i) => {
              return (
                <Box key={i}>
                  <p className="testimonial-sec__quote quotes">
                    {item.description}
                  </p>
                  {ratings && (
                    <>
                      <h4>{item.title}</h4>
                      <ul className="rating-list">
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                        <li>
                          <img
                            src={Media.StarIcon}
                            className="img-style__contain"
                            alt="start_icon"
                          />
                        </li>
                      </ul>
                    </>
                  )}
                </Box>
              );
            })}
          </Carousel>
       
        </Container>
      </Box>
    </StyledPetsBlock>
  );
}

export default PetsOwnerSlider;
