import React from "react";
import { Box } from "@mui/material";

interface ImgBannerProps {
  img: string;
}

function ImageBanner({ img }: ImgBannerProps) {
  return (
    <Box component="section">
      <Box
        component="img"
        height={{ xs: "auto", sm: "67vh" }}
        src={img}
        className="img-style img-style__cover"
      />
    </Box>
  );
}

export default ImageBanner;
