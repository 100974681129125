import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FormikProps, useFormik } from 'formik';
import { scheduleMessageSchema } from '../../../validators/schemas/scheduleMessage';
import DailyMessageFrequency from '../DailyMessageFrequency';
import WeeklyMessageFrequency from '../WeeklyMessageFrequency';
import { Media } from '../../../config/media';
import { useFetchAllPetsQuery } from '../../../redux/pet/petApi';
import { PetData } from '../../../interfaces/pet';
import { LoadingButton } from '@mui/lab';
import CustomHelperText from '../../CustomHelperText';
import { useEffect, useState } from 'react';
import {
  useAddNewScheduleMutation,
  useFetchAllSchedulesQuery,
  useFetchScheduleTimesQuery,
  useFetchScheduleTypesQuery,
  useLazyFetchScheduleByIdQuery,
  useUpdateScheduleMutation,
} from '../../../redux/schedule/scheduleApi';
import { Stepper, daysArr, messageTypes } from '../../../config/constants';
import { useToast } from '../../../hooks/useToast';
import { useFetchUserCreditsQuery } from '../../../redux/userCredits/userCreditsApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { setProfileCompleted } from '../../../redux/stepper/stepperStore';
import StepperModal from '../../StepperModal';
import RequiredAsterisk from '../../RequiredAsterisk';
import Divider from '@mui/material/Divider';
import {
  dailyDefaultSchedule,
  weeklyDefaultSchedule,
} from '../../../config/schedule';

type ScheduleMessageFormPropsType = {
  id?: number | string;
  action?: string;
  data?: any;
};

type ScheduleMessageFormType = {
  monday?: string;
  tuesday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
  sunday?: string;
  pet: string | undefined;
  message_schedule_type: number;
  message_frequency: any;
  starts_date: string;
  frequency?: any;
};

const ScheduleMessageForm: React.FC<ScheduleMessageFormPropsType> = ({
  id,
  action = 'create',
  data,
}) => {
  const { state } = useLocation();
  const { data: allPets, refetch } = useFetchAllPetsQuery({});
  const [frequencyData, setFrequencyData] = useState<any>(data);
  const [createSchedule, { isLoading: isCreating }] =
    useAddNewScheduleMutation();
  const [updateSchedule, { isLoading: isUpdating }] =
    useUpdateScheduleMutation();
  const { data: scheduleTimesApiData } = useFetchScheduleTimesQuery({});
  const toast = useToast();
  const [monthlyCreditsNeeded, setMonthlyCreditsNeeded] = useState<number>(0);
  const { data: scheduleMsgTypes } = useFetchScheduleTypesQuery({});
  const { data: userCredits, refetch: refetchUserCredits } =
    useFetchUserCreditsQuery({});

  useEffect(() => {
    refetchUserCredits();
  }, [refetchUserCredits]);

  const [frqErr, setFrqErr] = useState<boolean>(false);
  const navigate = useNavigate();
  const { completed } = useSelector((state: RootState) => state.stepper);
  const [showStepperModal, setShowStepperModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { data: schedules, refetch: refetchAllSchedules } =
    useFetchAllSchedulesQuery({});
  const [scheduleData, setScheduleData] = useState<any>(null);
  const { userData } = useSelector((state: RootState) => state.user);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [trigger, { data: scheduleLazyData }] = useLazyFetchScheduleByIdQuery();
  const [validSchedule, setValidSchedule] = useState<boolean>(false);
  const [setting, setSetting] = useState<boolean>(true);
  const firstSchedule = !schedules?.data?.length && allPets?.data?.length === 1;
  //@ts-ignore
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
    touched,
    errors,
  }: FormikProps<ScheduleMessageFormType> = useFormik({
    initialValues: {
      pet: '',
      message_schedule_type: 3,
      message_frequency: 'Weekly',
      starts_date: format(new Date(), 'yyyy-MM-dd'),
    },
    validationSchema: scheduleMessageSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (data) {
      setFrequencyData(data);
    } else if (scheduleData) {
      setFrequencyData(scheduleData?.data);
    }
  }, [data, scheduleData, values?.pet]);

  useEffect(() => {
    refetch();
    if (state?.scheduleId) {
      trigger(state.scheduleId);
    } else if (state?.petId) {
      setFieldValue('pet', state.petId);
    } else if (schedules) {
      setFieldValue('pet', allPets?.data?.[0]?.id);
    }
  }, [allPets, schedules]);

  useEffect(() => {
    if (scheduleLazyData) {
      setScheduleData(scheduleLazyData);
    }
  }, [scheduleLazyData, values?.pet]);

  // If updating schedule, initializing fomik fields
  useEffect(() => {
    if (data || scheduleData) {
      const schedule = data || scheduleData?.data;
      Object.keys(schedule)?.forEach((key: string) => {
        if (key === 'starts_date') {
          setFieldValue(key, format(new Date(schedule[key]), 'yyyy-MM-dd'));
        } else {
          //@ts-ignore
          setFieldValue(key, schedule[key]);
        }
      });
      setSetting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, scheduleData]);

  useEffect(() => {
    if (values?.pet) {
      let scheduleId = 0;
      schedules?.data?.forEach((schedule: any) => {
        if (schedule.pet === Number(values?.pet)) {
          scheduleId = schedule.id;
        }
      });
      if (scheduleId) {
        trigger(scheduleId);
        setValidSchedule(true);
      } else {
        setSetting(false);
        if (firstSchedule) return;
        setFrequencyData(null);
        // Resetting fields
        const pet = values?.pet;
        resetForm();
        setFieldValue('pet', pet);
        setScheduleData(null);
        setValidSchedule(false);
      }
    }
  }, [values?.pet]);

  useEffect(() => {
    if (
      frequencyData &&
      frequencyData?.message_frequency !== values?.message_frequency &&
      !firstSchedule
    ) {
      daysArr.forEach((day: string) => setFieldValue(day, ''));
    } else if (
      frequencyData &&
      frequencyData?.message_frequency === values?.message_frequency
    ) {
      daysArr.forEach((day: string) =>
        setFieldValue(day, frequencyData[day] || '')
      );
    }
    // Updating monthly credits calculation
    if (action === 'create') {
      setMonthlyCreditsNeeded(0);
    } else {
      if (frequencyData?.message_frequency !== values?.message_frequency) {
        setMonthlyCreditsNeeded(0);
      } else {
        calculateMonthlyCredits();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.message_frequency]);

  // Calculating montyly credits needed
  useEffect(() => {
    calculateMonthlyCredits();
    //Updating message frequency error
    setFrqErr(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.monday,
    values?.tuesday,
    values?.wednesday,
    values?.thursday,
    values?.friday,
    values?.saturday,
    values?.sunday,
    values?.message_schedule_type,
  ]);

  useEffect(() => {
    if (firstSchedule) {
      const obj =
        values?.message_frequency === 'Daily'
          ? dailyDefaultSchedule
          : weeklyDefaultSchedule;
      Object.keys(obj).forEach((key: string) => {
        setFieldValue(key, obj[key]);
      });
    }
  }, [firstSchedule, values.message_frequency]);

  useEffect(() => {
    if (firstSchedule) {
      setFrequencyData({
        ...values,
      });
    }
  }, [values]);

  function calculateMonthlyCredits() {
    let count = 0;
    // Calculation for daily
    if (values?.message_frequency === 'Daily') {
      //@ts-ignore
      const val = values['monday']?.split(',')?.filter((v) => v?.length) || [];
      if (val?.length) {
        count = val?.length;
      }
      if (count) {
        const credits =
          count * getCreditsByMessageType(values?.message_schedule_type) * 30;
        if (credits) {
          setMonthlyCreditsNeeded(credits);
        }
      } else {
        setMonthlyCreditsNeeded(0);
      }
    } else if (values?.message_frequency === 'Weekly') {
      daysArr.forEach((day: string) => {
        //@ts-ignore
        const val = values?.[day]?.split(',')?.filter((v) => v?.length) || [];
        if (val?.length) {
          count += val?.length;
        }
        if (count) {
          let credits =
            count *
            getCreditsByMessageType(values?.message_schedule_type) *
            Math.round(30 / 7);
          if (credits) {
            credits = Math.round(credits);
            setMonthlyCreditsNeeded(credits);
          }
        } else {
          setMonthlyCreditsNeeded(0);
        }
      });
    }
  }

  function getCreditsByMessageType(id: number) {
    if (scheduleMsgTypes?.data) {
      let result: number = 0;
      scheduleMsgTypes?.data.forEach((v: any) => {
        if (v?.id === id) {
          result = v?.credits_required;
          return result;
        }
      });
      return result;
    }
    return 0;
  }

  async function submitHandler(formData: ScheduleMessageFormType) {
    if (hasFrequencyError()) {
      setFrqErr(true);
      return;
    } else {
      setFrqErr(false);
    }
    try {
      let res: any = null;
      if (!(data || scheduleData)) {
        res = await createSchedule(formData);
      } else {
        res = await updateSchedule({
          id: id ? id : scheduleData?.data?.id,
          data: formData,
        });
      }

      //@ts-ignore
      if (res?.error) {
        throw new Error('');
      } else {
        if (res?.data?.data) {
          setFrequencyData(res.data.data);
          setScheduleData(res?.data);
          toast({
            title: `Schedule ${!(data || scheduleData) ? 'created' : 'updated'
              } successfully`,
          });
          if (completed < Stepper.SCHEDULE_MESSAGE) {
            setShowStepperModal(true);
            dispatch(setProfileCompleted(Stepper.SCHEDULE_MESSAGE));
          }
        }
      }
    } catch (err: any) {
      toast({
        title:
          err?.message ||
          `Unable to ${!(data || scheduleData) ? 'create' : 'update'
          } schedule at the moment`,
        severity: 'error',
      });
    }
  }

  const toggleMessaging = async () => {
    try {
      const res = await updateSchedule({
        id: scheduleData?.data?.id,
        data: { status: !frequencyData?.status },
      });
      //@ts-ignore
      if (res?.error) {
        throw new Error('');
      } else {
        //@ts-ignore
        if (res?.data?.data) {
          toast({
            title: `Messaging ${!frequencyData?.status ? 'resumed' : 'stopped'
              } successfully`,
          });
          //@ts-ignore
          setFrequencyData(res?.data?.data);
        }
      }
      setShowModal(false);
    } catch (err: any) {
      toast({
        title:
          err?.message || 'Unable to proceed at the moment, Try again later',
        severity: 'error',
      });
      setShowModal(false);
    }
  };

  const hasFrequencyError = () => {
    let isError = true;
    daysArr.forEach((day: string) => {
      //@ts-ignore
      if (values[day]) {
        isError = false;
        return;
      }
    });
    return isError;
  };

  let total_purchase: number = 100;
  let total_usage: number = 0;
  if (userCredits !== null) {
    total_purchase = userCredits?.data?.total_purchase || 100;
    total_usage = userCredits?.data?.total_usage || 0;
  } else {
    return null;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={{ xs: 1, md: 2 }} alignItems={'center'}>
          <Grid item xs={12} md={3} sx={{ mb: { xs: 0, sm: 1, md: 4, lg: 5 } }}>
            <label>Select your pet</label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9} sx={{ mb: { xs: 0, sm: 3, md: 4, lg: 5 } }}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <Select
                    sx={{ color: 'info.darker' }}
                    displayEmpty
                    value={values?.pet}
                    name="pet"
                    input={<OutlinedInput />}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.pet && errors.pet)}
                  >
                    <MenuItem disabled value="">
                      Select your pet
                    </MenuItem>
                    {allPets?.data &&
                      allPets.data.map(({ name, id }: PetData) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                  {Boolean(touched.pet && errors.pet) && (
                    <CustomHelperText title={errors.pet} />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: { xs: 0, sm: 1 } }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: { xs: 'static', md: 'relative' },
                top: '-14px',
              }}
            >
              <label>Message Type </label>
              <RequiredAsterisk />
              <Tooltip
                title="You can choose both image and text message types."
                placement="top-start"
                arrow
              >
                <Box
                  component="img"
                  alt="info img"
                  src={Media.InfoIconPrimary}
                  className="img-style__contain"
                  sx={{ width: '22px', height: '22px', ml: 1 }}
                />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ mb: { xs: 0, sm: 1 } }}>
            <Grid container spacing={{ lg: 2 }} className="label-wrap">
              {scheduleMsgTypes?.data &&
                scheduleMsgTypes.data.map(
                  ({ id, message_type, credits_required }: any) => (
                    <Grid item xs={6} md={4} sx={{ mb: { xs: 1 } }} key={id}>
                      <Stack direction="column">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values?.message_schedule_type === id}
                              />
                            }
                            label={messageTypes[message_type]}
                            name="message_schedule_type"
                            onBlur={handleBlur}
                            onChange={() =>
                              setFieldValue('message_schedule_type', id)
                            }
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              paddingLeft: {
                                xs: '1.5rem',
                                md: '1.1rem',
                                sm: '.9rem',
                              },
                            }}
                            color="primary"
                          >
                            ({credits_required} credits)
                          </Typography>
                        </FormGroup>
                      </Stack>
                    </Grid>
                  )
                )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} sx={{ mb: { xs: 0, sm: 3 } }}>
            <label>Message Frequency </label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9} sx={{ mb: { xs: 2, sm: 3 } }}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  gap: '40px',
                  width: '100%',
                }}
                name="message_frequency"
                defaultValue="Daily"
                value={values?.message_frequency}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Grid container spacing={2} className="label-wrap">
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="Daily"
                      control={<Radio />}
                      label="Daily"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="Weekly"
                      control={<Radio />}
                      label="Weekly"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          {
            setting ? (
              <Box sx={{
                width: '100%',
                height: "200px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pl: 2,
              }}>
                <CircularProgress sx={{
                }} color='primary' />
              </Box>
            ) : (
              <>

                {values?.message_frequency === 'Daily' && (
                  <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12} lg={9}>
                      <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
                        <Grid item xs={12} md={7} sx={{ mb: 4, pl: 1 }}>
                          <DailyMessageFrequency
                            timezone={userData?.city_state_timezone}
                            allScheduleTimes={scheduleTimesApiData?.data}
                            setFieldValue={setFieldValue}
                            values={
                              frequencyData?.message_frequency === 'Daily' &&
                                scheduleData
                                ? frequencyData
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {values?.message_frequency === 'Weekly' && (
                  <WeeklyMessageFrequency
                    allScheduleTimes={scheduleTimesApiData?.data}
                    timezone={userData?.city_state_timezone}
                    setFieldValue={setFieldValue}
                    values={
                      (frequencyData?.message_frequency === 'Weekly' &&
                        validSchedule) ||
                        firstSchedule
                        ? frequencyData
                        : {}
                    }
                  />
                )}
                {frqErr && (
                  <Grid container spacing={2}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12} lg={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={7} sx={{ mb: 4 }}>
                          <CustomHelperText title="Schedule timing is required" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          <Grid item xs={12} md={3} sx={{ mb: { xs: 0, sm: 1, md: 4, lg: 5 } }}>
            <label> Starts on</label>
            <RequiredAsterisk />
          </Grid>
          <Grid item xs={12} md={9} sx={{ mb: { xs: 2, md: 3, lg: 4 } }}>
            <Grid container spacing={4} alignItems={'center'}>
              <Grid item xs={12} md={6} className="select-date">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ overflow: 'none' }}
                    components={['DatePicker', 'DatePicker']}
                  >
                    <DatePicker
                      sx={{ overflow: 'none' }}
                      name="starts_date"
                      value={
                        values?.starts_date ? dayjs(values?.starts_date) : null
                      }
                      onChange={(value) => {
                        setFieldValue(
                          'starts_date',
                          dayjs(value).format('YYYY-MM-DD')
                        );
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(
                            errors.starts_date && touched.starts_date
                          ),
                          helperText:
                            Boolean(
                              errors.starts_date && touched.starts_date
                            ) && errors.starts_date,
                        },
                      }}
                      minDate={dayjs(new Date())}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                {(data || scheduleData) && (
                  <Button
                    variant="contained"
                    onClick={() => setShowModal(true)}
                    color="primary"
                  >
                    {frequencyData && frequencyData?.status ? 'Stop' : 'Resume'}{' '}
                    messages
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ ml: 0, mt: 2 }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '600',
                    color: '#000',
                    mb: 2,
                    width: '50%',
                  }}
                >
                  Your Credits
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '700',
                    mb: 2,
                    width: '50%',
                    textAlign: 'right',
                  }}
                  color="primary"
                >
                  {total_purchase} credits
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '600',
                    color: '#000',
                    mb: 2,
                    width: '50%',
                  }}
                >
                  Credits Utilized
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '700',
                    mb: 2,
                    width: '50%',
                    textAlign: 'right',
                  }}
                  color="warning.main"
                >
                  {total_usage} credits
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '600',
                    color: '#000',
                    mb: 2,
                    width: '50%',
                  }}
                >
                  Estimated monthly credits needed
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '700',
                    mb: 2,
                    width: '50%',
                    textAlign: 'right',
                  }}
                  color="success.main"
                >
                  {monthlyCreditsNeeded} credits
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: '600',
                    color: '#000',
                    mb: 2,
                    width: '50%',
                  }}
                >
                  Remaining Credits
                </Typography>
                <Stack
                  direction="column"
                  textAlign={'right'}
                  sx={{ width: '50%' }}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: '700' }}
                    color="primary"
                  >
                    {total_purchase - total_usage ?? total_purchase} credits
                  </Typography>
                  <Tooltip title="coming soon" placement="bottom-end">
                    <Link
                      onClick={(e) => e.preventDefault()}
                      href=""
                      className="text-sm"
                      sx={{
                        textDecoration: 'underline !important',
                        fontStyle: 'normal !important',
                      }}
                    >
                      Buy more Credits
                    </Link>
                  </Tooltip>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'right', mt: 4 }}>
          <LoadingButton
            loading={isCreating || isUpdating}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ minWidth: '146px' }}
          >
            {isCreating || isUpdating ? (
              <CircularProgress size={24} />
            ) : !(data || scheduleData) ? (
              'Save'
            ) : (
              'Update'
            )}
          </LoadingButton>
        </Box>
      </Box>
      {/* STEPPER MODAL */}
      {showStepperModal && (
        <StepperModal
          sectionFilled="schedule"
          handleClose={() => setShowStepperModal(false)}
          show={showStepperModal}
        />
      )}
      {showModal && (
        <Dialog
          open={showModal}
          keepMounted
          onClose={() => setShowModal(false)}
          sx={{ maxWidth: '550px', mx: 'auto' }}
        >
          <DialogTitle>
            <Typography variant="h2" sx={{ mb: 1 }}>
              Confirmation
            </Typography>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {`Are you sure you want to ${frequencyData && frequencyData?.status ? 'stop' : 'resume'
                } the messages?`}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ m: 3 }}>
            <Button variant="contained" onClick={() => setShowModal(false)}>
              Disagree
            </Button>
            <Button variant="contained" onClick={toggleMessaging}>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ScheduleMessageForm;
