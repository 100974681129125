import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material';
import { Media } from '../../../config/media';
import { StyledPricingCard } from './styles';
import { PricingItemsType } from '../../../config/pricing';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { useNavigate } from 'react-router-dom';
import { GlobalRoutes } from '../../../types';

type SubscriptionCardProps = {
  data: PricingItemsType;
  selectedPlan: string;
  setSelectedPlan: any;
value:number;
};

function SubscriptionCard({
  data: { title, price, priceSubtitle, features, creditsText, description },
  selectedPlan, 
  setSelectedPlan,
  value
}: SubscriptionCardProps) {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const priceFixed=(price).toFixed(2)
  const calculatedprice=(price * 12).toFixed(2);
  const handleNavigation = () => {
    navigate(GlobalRoutes.REGISTER);
  }

  return (
    <StyledPricingCard>
      <Box
        className={`price-block ${selectedPlan === title ? 'selected' : '' }`}
        sx={{
          backgroundColor: 'primary.contrastText',
          alignItems: 'start',
          cursor: 'pointer', 
        }}
        onClick={() => setSelectedPlan(title)}
      >
        <Typography variant="h2" mb={5}>
          {title}
        </Typography>
      <Typography
      variant="body1"
      sx={{
        ml:0,
        mb: 1,
        fontWeight: '700',
        color: 'secondary.main',
        lineHeight: '1',
        fontSize: {
          xs: '3rem',
          sm: '4rem',
          md: '4rem',
          xl: '4rem',
        },
      }}
    >
      {priceFixed}
     {value===1 || Number(priceFixed)===0.00
     ?
     ""
     :
   
     <Box
  component="span"
  sx={{
    color: 'orange',
    fontSize: '1rem',
    ml: 1,
    width: '15px',
    display: 'inline-block' 
  }}
>
  ${calculatedprice} annually
</Box>  
    }
    </Typography>
           
        <Typography variant="body2" mb={6} className="price-block__sub">
          {priceSubtitle}
        </Typography>
        {features?.length && (
          <List className="price-block__list">
            {features.map((feat: string) => (
              <ListItem>
                <ListItemIcon>
                  <Box
                    component="img"
                    alt="The shape"
                    src={Media.TickIcon}
                    className="img-style__contain "
                  />
                </ListItemIcon>
                <ListItemText>{feat}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
        <Box textAlign="center" sx={{ marginTop: 'auto' }}>
            <Typography
              variant="body1"
              sx={{ mb: 3, color: 'primary.main' }}
              className="selected-plan"
            >
              {creditsText ? creditsText : ''}
            </Typography>
          <Typography
            variant="body2"
            mb={1}
            mt={3}
            sx={{
              display: { xs: 'none', sm: 'block' },
              mb: 1,
              mt: 3,
            }}
            className="price-block__content text-small"
          >
            {description}
          </Typography>
          {!isAuthenticated && <Button
            variant="contained"
            color="secondary"
            className="subscribe"
            sx={{ mt: { xs: 7, sm: 8, md: 9, lg: 10 } }}
            onClick={handleNavigation}
          >
            Sign Up Now
          </Button>}
        </Box>
      </Box>
    </StyledPricingCard>
  );
}

export default SubscriptionCard;
