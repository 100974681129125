import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

  export const StyledEngagementImg= styled(Box)`
  .pet-img{
    z-index: 2;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
 `;


 export const StyledEngagementBlock = styled(Box)`
 max-width: 80%;
 margin: 2rem auto 0;
 height: calc(100% - 225px - 2rem);
    display: flex;
    flex-direction: column;
    .top-engage__text{
        display: inline;
    }
    .top-engage__text::before,.top-engage__text::after{
        width: 20px;
        height: 20px;
        content:'';
    }
    .top-engage__text::after{
        background-image:url("/assets/images/quotes.svg");
        bottom: 6px;
        right: -24px;
    }
    .top-engage__text::before{
        background-image:url("/assets/images/quotes-left.svg");
        top: -3px;
        left: -20px; 
    }
    @media(max-width: 599px){
      .top-engage__text::before,.top-engage__text::after{
        width: 12px;
    }
    }
`;
