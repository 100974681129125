import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useNavigate } from "react-router-dom";
import { Stepper } from "../../config/constants";
import { GlobalRoutes } from "../../types";
import { setProfileCompleted } from "../../redux/stepper/stepperStore";
import { useLazyGetProfileStepperQuery } from "../../redux/stepper/stepperApi";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 19,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E6F8F7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    backgroundColor: theme.palette.mode === "light" ? "#01B9AF" : "#308fe8",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 700,
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: "0px 0px 18.9px 0px #A7A7A740",
  p: 3,
};

type StepperModalPropsType = {
  show: boolean;
  handleClose: any;
  onSuccess?: any;
  completedCount?: number;
  sectionFilled: string;
  onSkip?: any;
};

export default function StepperModal({
  show,
  handleClose,
  onSuccess,
  sectionFilled,
  completedCount,
  onSkip,
}: StepperModalPropsType) {
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const { completed } = useSelector((state: RootState) => state.stepper);
  const [fetchStepperData] = useLazyGetProfileStepperQuery();
  const dispatch = useDispatch();
  //@ts-ignore
  const fullName = `${userData?.user.first_name} ${userData?.user.last_name}`;

  const handleNavigation = () => {
    let url = "";
    let state = null;
    if (completed < Stepper.Profile) {
      url = GlobalRoutes.EDIT_PROFILE;
    } else if (!completedCount || completed < Stepper.MY_PETS) {
      url = GlobalRoutes.ACCOUNT_MY_PETS;
      state = {
        path: GlobalRoutes.ACCOUNT_PROFILE
      }
    } else if (completedCount || completed < Stepper.SCHEDULE_MESSAGE) {
      url = GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE;
    }
    if (onSuccess) {
      onSuccess();
    } else {
      if(state) {
        navigate(url, {
          state, 
        });
      } else {
        navigate(url);
      }
    }
  };

  const onClose = async () => {
    const stepperData = await fetchStepperData({});
    if(stepperData) {
      dispatch(setProfileCompleted(stepperData?.data?.data?.completed));
    }
    if(sectionFilled === 'schedule') {
      navigate(GlobalRoutes.HOME);
    }
    onSkip && onSkip();
    handleClose();
  };

  const handleProfileCompleted = async () => {
    onClose();
    dispatch(setProfileCompleted(Stepper.MY_CREDIT));
    navigate(GlobalRoutes.HOME);
  }

  return (
    <>
      <Modal
        open={show}
        disableEscapeKeyDown
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box sx={style}>
          <Typography
            variant="body1"
            id="modal-modal-description"
            sx={{ mb: 2 }}
          >
            <Typography>Thank You, {fullName}!</Typography>
            {sectionFilled === "schedule" ? (
              <>
                You filled your {sectionFilled} section. Congratulations your
                profile is completed
              </>
            ) : (
              <>
                You filled your {sectionFilled} section. We recommend you to
                fill the other sections as well otherwise your profile will be
                consider as incomplete.
              </>
            )}
          </Typography>
          <Typography sx={{ fontWeight: "600", mb: 1 }} variant="body1">
            Profile Completed:{" "}
            <Typography
              sx={{ color: "secondary.main", fontWeight: "600" }}
              component="span"
            >
              {completedCount || completed}%
            </Typography>
          </Typography>
          <BorderLinearProgress variant="determinate" value={completedCount || completed} />
          <Box sx={{ display: "flex", mt: 4 }}>
            {sectionFilled === "schedule" ? (
              <Button variant="outlined" sx={{ mt: 1 }} onClick={handleProfileCompleted}>
                Close
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  sx={{ mr: 3, mt: 1 }}
                  onClick={handleNavigation}
                >
                  Proceed to next section
                </Button>
                <Button variant="outlined" sx={{ mt: 1 }} onClick={onClose}>
                  Fill out later
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
