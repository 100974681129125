import React, { useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import { forgotPasswordSchema } from '../../../validators/schemas/authSchemas';
import { LoadingButton } from '@mui/lab';
import { useForgotPasswordMutation } from '../../../redux/auth/authApi';
import { toastError } from '../../Toasters';
import { Message } from '../../../config/messages';
import ErrorMessage from '../../ErrorMessage';
import { IResponseInterface } from '../../../interfaces/auth';

interface ForgotPasswordFormProps {
  title: string;
  content: string;
}

function ForgotPasswordForm({
  title,
  content,
}: ForgotPasswordFormProps): JSX.Element {
  const [messageType, setMessageType] = useState<Boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [err, setErr] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }: FormikProps<any> = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async ({ email }, { resetForm }) => {
      setShowAlert(true);
      try {
        const payload = {
          email,
        };
        setLoading(true);
        await forgotPassword(payload)
          .unwrap()
          ?.then((res: IResponseInterface) => {
            resetForm();
            setErr('');
            setSuccessMsg('Password reset link has been sent to your email');
            setLoading(false);
          })
          ?.catch((error) => {
            throw new Error(error?.data?.message || Message.SomethingWrong);
            setLoading(false);
          });
      } catch (err: any) {
        setErr(err?.message || Message.SomethingWrong);
        setSuccessMsg('');
      }
    },
  });

  const { email } = values;
  return (
    <Box>
      {showAlert && (err || successMsg) && (
        <Collapse in>
          <Alert
            sx={{ my: 2 }}
            variant="filled"
            severity={err ? 'error' : successMsg ? 'success' : 'success'}
            onClose={() => setShowAlert(false)}
          >
            {err || successMsg}
          </Alert>
        </Collapse>
      )}

      <Typography variant="body2" sx={{ mb: 3 }}>
        {content}
      </Typography>
      <ErrorMessage message={message} type={messageType} show={!isLoading} />
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ my: 1.5, width: '100%' }} variant="outlined">
          <TextField
            className="login-textfield"
            id="email"
            label="Enter your email"
            variant="outlined"
            name="email"
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
            color={errors.email ? 'error' : 'primary'}
            error={!!(touched.email && errors.email)}
            helperText={
              touched.email &&
              errors.email &&
              typeof errors.email === 'string' &&
              errors.email
            }
          />
        </FormControl>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ width: '100%', mt: 3, mb: 6 }}
        >
          {isLoading ? (
            <CircularProgress sx={{ ml: 2 }} color="inherit" size={24} />
          ) : (
            `${title}`
          )}
        </LoadingButton>
      </form>
    </Box>
  );
}

export default ForgotPasswordForm;
