import { Box, Paper } from '@mui/material';
import { TabContainer, TabLink } from './styles';
import React, { useEffect, useState } from 'react';
import { AccountTabs, AccountTabsLink, Stepper } from '../../config/constants';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { useFetchUserCreditsQuery } from '../../redux/userCredits/userCreditsApi';
import { useGetProfileStepperQuery } from '../../redux/stepper/stepperApi';

type MyAccountPropsType = {};

const TabsSection: React.FC<MyAccountPropsType> = () => {
  const { pathname } = useLocation();
  const [selected, setSelected] = useState<string>('');
  const { data: stepperData } = useGetProfileStepperQuery({});
  const { data: userCredits } = useFetchUserCreditsQuery({});

 let currentBalance: number = 0;
  if(userCredits?.data) {
    currentBalance = (userCredits?.data?.total_purchase || 100) - (userCredits?.data?.total_usage || 0) ?? 100; 
  }

  const completed = stepperData?.data?.completed || 25; 

  useEffect(() => {
    if (pathname) {
      Object.keys(AccountTabs).forEach((key) => {
        const value = AccountTabs[key].split(' ')[1] || '';
        if (pathname.includes(value?.toLowerCase())) {
          setSelected(AccountTabs[key]);
        }
        if(pathname.includes('edit-pet') || pathname.includes('add-pet')) {
          setSelected(AccountTabs['MY_PETS']);
        }
      });
    }
  }, [pathname]);

  const handleTabSelected = (value: string) => {
    setSelected(value);
  };

  const isDisabled = (tabName: string) => {
    switch (tabName) {
      case 'MY_PETS':
        return completed < Stepper.PROFILE;
      case 'SCHEDULE_MESSAGE':
        return completed < Stepper.MY_PETS;
      case 'MY_CREDIT':
        return completed < Stepper.SCHEDULE_MESSAGE;
    }
    return false;
  };

  const getCompletedClass = (name: String) => {
    switch (name) {
      case 'PROFILE':
        return completed >= Stepper.PROFILE ? 'completed': '';
      case 'MY_PETS':
        return completed >= Stepper.MY_PETS ? 'completed' : '';
      case 'SCHEDULE_MESSAGE':
        return completed >= Stepper.SCHEDULE_MESSAGE ? 'completed' : '';
      case 'MY_CREDIT':
        return completed >= Stepper.SCHEDULE_MESSAGE ? 'completed' : '';
    }
    return '';
  };

  return (
    <Box component="section">
      <Paper
        sx={{
          boxShadow: '0px 0px 18.9px 0px #A7A7A740',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
          mb: 3,
          mt:{xs:4,md:0},
        }}
      >
        <TabContainer
          className="tab-list"
          sx={{ py: 3, px: { xs: 2, md: 3, lg: 5 } }}
        >
          {Object.keys(AccountTabs).map((key, ind) => {
            const disabled = isDisabled(key);
            return (
              <Box
                key={ind}
                onClick={() => handleTabSelected(AccountTabs[key])}
                sx={{
                  pointerEvents: disabled ? 'none' : 'all',
                }}
              >
                <Box>
                  <TabLink
                    sx={{
                      fontSize: { sm: 16, md: 21, lg: 24 },
                      p: 1,
                      color: 'info.light',
                      '&:hover': {
                        color: disabled ? 'none' : 'primary.main',
                      },
                      '&.active': {
                        color: 'primary.main',
                      },
                      "&.completed": {
                        color: "secondary.main",
                      },
                    }}
                    className={`${getCompletedClass(key)} ${
                      selected === AccountTabs[key] ? 'active' : ''
                    }`}
                    to={AccountTabsLink[key] as string}
                  >
                    <svg
                      className={getCompletedClass(key)}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.996 3.0318C14.0621 2.1701 11.9014 1.95662 9.83626 2.42322C7.7711 2.88981 5.91209 4.01148 4.53649 5.62093C3.16089 7.23038 2.34241 9.24137 2.2031 11.354C2.0638 13.4666 2.61114 15.5677 3.76349 17.3438C4.91584 19.12 6.61147 20.476 8.59748 21.2098C10.5835 21.9435 12.7535 22.0156 14.7838 21.4153C16.8141 20.815 18.596 19.5744 19.8637 17.8787C21.1314 16.1829 21.817 14.1228 21.8182 12.0056V11.0026C21.8182 10.4001 22.3066 9.9117 22.9091 9.9117C23.5116 9.9117 24 10.4001 24 11.0026V12.0062L24 12.0069C23.9985 14.5946 23.1606 17.1125 21.6112 19.1851C20.0618 21.2576 17.8839 22.7738 15.4024 23.5075C12.9209 24.2412 10.2687 24.1531 7.84136 23.2564C5.41402 22.3596 3.34158 20.7022 1.93315 18.5313C0.524719 16.3605 -0.144251 13.7925 0.0260122 11.2104C0.196275 8.62834 1.19665 6.17046 2.87794 4.20335C4.55922 2.23625 6.83134 0.865327 9.35542 0.295044C11.8795 -0.27524 14.5203 -0.0143271 16.884 1.03887C17.4343 1.28408 17.6817 1.929 17.4365 2.47934C17.1913 3.02967 16.5463 3.27702 15.996 3.0318Z"
                        fill="#AFAFAF"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M23.6803 2.50663C24.1065 2.93245 24.1068 3.62317 23.681 4.04941L12.7719 14.9694C12.5674 15.1742 12.2899 15.2892 12.0004 15.2893C11.711 15.2894 11.4334 15.1744 11.2288 14.9698L7.95606 11.6971C7.53003 11.271 7.53003 10.5803 7.95606 10.1543C8.38208 9.72826 9.07281 9.72826 9.49883 10.1543L11.9998 12.6552L22.1375 2.50741C22.5633 2.08117 23.254 2.08082 23.6803 2.50663Z"
                        fill="#AFAFAF"
                      />
                    </svg>{' '}
                    {AccountTabs[key]}
                    <span>{(key === 'MY_CREDIT' && completed >= Stepper.PROFILE) && ` (${currentBalance} credits)`}</span>
                  </TabLink>
                </Box>
              </Box>
            );
          })}
        </TabContainer>
      </Paper>
    </Box>
  );
};

export default TabsSection;
