import { Box, Stack, Typography, Container } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../../../types";
import PetForm from "../../../components/PetForm";

type AddPetPropsType = {};

const AddPet: React.FC<AddPetPropsType> = () => {
  const navigate = useNavigate();
  const { state } = useLocation(); 

  const handleBackAction = () => {
    if(state?.path === GlobalRoutes.MY_PETS) {
      navigate(GlobalRoutes.MY_PETS);
    } else {
      navigate(GlobalRoutes.ACCOUNT_MY_PETS);
    }
  };

  return (
    <Box >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            pb: 4,
          }}
        >
          <ArrowBackIcon
            onClick={handleBackAction}
            sx={{
              cursor: "pointer",
            }}
          />
          <Typography variant="h1">Tell us a little about your pet</Typography>
        </Stack>
        <Box sx={{textAlign:'left'}}>
          <Typography variant="caption" sx={{ mb: 2, display: "inline-block",lineHeight:'1.5' }}>
            Fill in your pet’s name, type, gender, size and weight, and any
            unique characteristics. Then choose the breed, color, and markings
            that best describe your pet. Not sure? Not to worry, lots of pets
            are mixed breeds or of unknown heritage.{" "}
          </Typography>
          <Typography variant="caption" sx={{ mb: 2, display: "inline-block",lineHeight:'1.5' }}>
            To get started, give us your best guess <Typography variant="caption" sx={{fontStyle:'italic',lineHeight:'1.5'}}>(up to two of each)</Typography> for his
            or her breed, colors and markings. If it’s not a great match, you’re
            welcome to update your pet’s profile at any time.
          </Typography>
        </Box>
        <Box sx={{ mr: { lg: 25 } }}>
          <PetForm edit={false} />
        </Box>
   
    </Box>
  );
};

export default AddPet;
