import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux";
import { logout } from "../redux/auth/authStore";
import { useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../types";
import { Box, CircularProgress } from "@mui/material";
import { setUserData } from "../redux/user/userStore";
import { useFetchUserQuery } from "../redux/user/userApi";
import { removeAuthToken } from "../utils/sessions";
import { useToast } from "../hooks/useToast";

type AuthProviderPropsType = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderPropsType): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const toast = useToast();
  const {
    data: userData,
    error,
    refetch: refetchUserData,
  } = useFetchUserQuery({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      //@ts-ignore
      if (error?.status === 401) {
        dispatch(logout());
        dispatch(setUserData({}));
        removeAuthToken();
        localStorage.clear();
        navigate(GlobalRoutes.LOGIN);
      } else {
        if (!userData) {
          refetchUserData();
        } else {
          dispatch(setUserData(userData));
        }
      }
    } else {
      toast({
        title: 'User is not logged in', 
        severity: 'error'
      });
      navigate(GlobalRoutes.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, token]);

  useEffect(() => {
    if (token && userData) {
      if (isFirstTimeUser()) {
        navigate(GlobalRoutes.EDIT_PROFILE);
      }
      setIsAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userData]);

  function isFirstTimeUser(): boolean {
    if (userData?.address) return false;
    return true;
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Box
      sx={{
        minHeight: "495px",
        position: "relative",
      }}
    >
      <CircularProgress
        sx={{
          top: "50%",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        color="primary"
        size={40}
      />
    </Box>
  );
};

export default AuthProvider;
