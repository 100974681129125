import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../../../types/routes";
import PetListCard from "../../../components/my-account/PetListCard";
import { useFetchAllPetsQuery } from "../../../redux/pet/petApi";
import { useEffect, useState } from "react";
import { useFetchAllSchedulesQuery } from "../../../redux/schedule/scheduleApi";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import PetForm from "../../../components/PetForm";
import { Stepper } from "../../../config/constants";

type PetListPropsType = {};

const PetList: React.FC<PetListPropsType> = () => {
  const navigate = useNavigate();
  const {
    data: pets,
    isLoading,
    refetch: refetchPets,
  } = useFetchAllPetsQuery({});
  const { data: schedules, refetch: refetchAllSchedules } =
    useFetchAllSchedulesQuery({});
  // Mapped scheduled ids with pet ids, for editing schedule
  const [mappedIds, setMappedIds] = useState<any>({});
  const { completed } = useSelector((state: RootState) => state.stepper);

  useEffect(() => {
      refetchPets();
      refetchAllSchedules();
  }, [completed]);

  // Mapping schedule ids with pet ids
  useEffect(() => {
    if (schedules) {
      const mappedIds = {};
      schedules?.data &&
        schedules?.data?.forEach((schedule: any) => {
          //@ts-ignore
          mappedIds[schedule.pet] = schedule.id;
        });
      setMappedIds(mappedIds);
    }
  }, [schedules]);

  const handleAddPetNavigation = () => {
    navigate(GlobalRoutes.ADD_PET);
  };

  return (
    <>
      <Helmet>
        <title>My Pets | MypetsText</title>
      </Helmet>

      <Box sx={{ width: "100%",textAlign:'left' }}>
        {completed < Stepper.MY_PETS ? (
          <Box>

              <Box>
                <Typography
                  variant="caption"
                  sx={{ mb: 2, display: "inline-block", lineHeight: "1.5" }}
                >
                  Fill in your pet’s name, type, gender, size and weight, and
                  any unique characteristics. Then choose the breed, color, and
                  markings that best describe your pet. Not sure? Not to worry,
                  lots of pets are mixed breeds or of unknown heritage.{" "}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ mb: 2, display: "inline-block", lineHeight: "1.5" }}
                >
                  To get started, give us your best guess{" "}
                  <Typography
                    variant="caption"
                    sx={{ fontStyle: "italic", lineHeight: "1.5" }}
                  >
                    (up to two of each)
                  </Typography>{" "}
                  for his or her breed, colors and markings. If it’s not a great
                  match, you’re welcome to update your pet’s profile at any
                  time.
                </Typography>
              </Box>
              <Box sx={{ mr: { lg: 25 } }}>
                <PetForm />
              </Box>
 
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h1" sx={{ mb: 5 }}>
                My Pets List
              </Typography>
              <Button
                variant="contained"
                sx={{ mb: 5 }}
                onClick={handleAddPetNavigation}
              >
                Add a new pet
              </Button>
            </Box>
            <Grid container spacing={2}>
              {pets?.data &&
                pets.data.map(({ id, name, profile_image }: any) => (
                  <Grid item xs={12} md={6} lg={3}>
                    <PetListCard
                      key={id}
                      imageUrl={profile_image}
                      name={name}
                      petId={id}
                      scheduleId={mappedIds[id] || undefined}
                    />
                  </Grid>
                ))}
            </Grid>
            <Box>
              <Button sx={{ mt: 4 }} onClick={() => navigate(GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE)} color="secondary" variant="contained">Next</Button>
            </Box>
            {isLoading && <CircularProgress size={24} />}
          </>
        )}
      </Box>
    </>
  );
};

export default PetList;
