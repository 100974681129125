import { alpha } from '@mui/material/styles';

// SETUP COLORS
export const LIGHT_MODE = {
  GREY: {
    0: '#FFFFFF',
    100: '#E1E1E1',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#131C23',
    700: '#676767', /* gray */
    800: '#131C23', /* body1 */
    900: '#000',
  },

  YELLOW: {
    0: '#FFFFFF',
    100: '#F5EF96',
    200: '#EAE47C',
    300: '#ECDC62',
    400: '#EAD84F',
    500: '#F3EC7C',
    600: '#E6CE18',
    700: '#DAC20F',
    800: '#DEC506',
    900: '#BFAA00',
  },

  PRIMARY: {
    lighter: '#fff0',
    light: '#FEF4E8',
    main: '#F6911E',
    dark: '#131C23',
    darker: '#489ABB',
    contrastText: '#ffffff',
  },

  SECONDARY: {
    lighter: '#fff0',
    light: '#E6F8F7',
    main: '#01B9AF',
    dark: '#05a1e1',
    darker: '#091A7A',
    contrastText: '#F2F2F2',
  },

  INFO: {
    lighter: '#F8F8F8',
    light: '#AFAFAF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#676767',
    contrastText: '#FFFFFF',
  },

  SUCCESS: {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#0D9A1B',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: '#212B36', //GREY[800],
  },

  WARNING: {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FA3D3D',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: '#212B36', //GREY[800],
  },

  ERROR: {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FA3D3D',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#FFFFFF',
  },
};

export const DARK_MODE = {
  GREY: {
    0: '#161C24',
    100: '#212B36',
    200: '#454F5B',
    300: '#637381',
    400: '#919EAB',
    500: '#C4CDD5',
    600: '#DFE3E8',
    700: '#F4F6F8',
    800: '#F9FAFB',
    900: '#FFFFFF',
  },

  PRIMARY: {
    lighter: '#061B64',
    light: '#103996',
    main: '#2065D1',
    dark: '#76B0F1',
    darker: '#D1E9FC',
    contrastText: '#FFFFFF',
  },

  SECONDARY: {
    light: '#05a1e1',
    lighter: '#091A7A',
    main: '#73D2F9',
    dark: '#84A9FF',
    darker: '#D6E4FF',
    contrastText: '#FFFFFF',
  },

  INFO: {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#FFFFFF',
  },

  SUCCESS: {
    darker: '#E9FCD4',
    dark: '#AAF27F',
    main: '#54D62C',
    light: '#229A16',
    lighter: '#08660D',
    contrastText: '#212B36', //GREY[800],
  },

  WARNING: {
    darker: '#FFF7CD',
    dark: '#FFE16A',
    main: '#FFC107',
    light: '#B78103',
    lighter: '#7A4F01',
    contrastText: '#212B36', //GREY[800],
  },

  ERROR: {
    darker: '#FFE7D9',
    dark: '#FFA48D',
    main: '#FF4842',
    light: '#B72136',
    lighter: '#7A0C2E',
    contrastText: '#FFFFFF',
  },
};

export const lightPalette = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: LIGHT_MODE.PRIMARY,
  secondary: LIGHT_MODE.SECONDARY,
  info: LIGHT_MODE.INFO,
  success: LIGHT_MODE.SUCCESS,
  warning: LIGHT_MODE.WARNING,
  error: LIGHT_MODE.ERROR,
  grey: LIGHT_MODE.GREY,
  divider: LIGHT_MODE.GREY[700],
  text: {
    primary: LIGHT_MODE.GREY[800],
    secondary: LIGHT_MODE.GREY[600],
    disabled: LIGHT_MODE.GREY[500],
  },
  
  background: {
    paper: '#FFFFFF',
    default: LIGHT_MODE.GREY[100],
    neutral: LIGHT_MODE.GREY[200],
    main: LIGHT_MODE.PRIMARY.main,
    overlay: 'rgba(0, 0, 0, 0.3)',
  },
  action: {
    active: LIGHT_MODE.GREY[600],
    hover: alpha(LIGHT_MODE.GREY[500], 0.08),
    selected: alpha(LIGHT_MODE.GREY[500], 0.16),
    disabled: alpha(LIGHT_MODE.GREY[500], 0.8),
    disabledBackground: alpha(LIGHT_MODE.GREY[500], 0.24),
    focus: alpha(LIGHT_MODE.GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const darkPalette = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: DARK_MODE.PRIMARY,
  secondary: DARK_MODE.SECONDARY,
  info: DARK_MODE.INFO,
  success: DARK_MODE.SUCCESS,
  warning: DARK_MODE.WARNING,
  error: DARK_MODE.ERROR,
  grey: DARK_MODE.GREY,
  divider: alpha(DARK_MODE.GREY[500], 0.24),
  text: {
    primary: DARK_MODE.GREY[800],
    secondary: DARK_MODE.GREY[600],
    disabled: DARK_MODE.GREY[500],
  },
  background: {
    paper: '#FFFFFF',
    default: DARK_MODE.GREY[100],
    neutral: DARK_MODE.GREY[200],
    main: '#73D2F9',
    overlay: 'rgba(0, 0, 0, 0.3)',
  },
  action: {
    active: DARK_MODE.GREY[600],
    hover: alpha(DARK_MODE.GREY[500], 0.08),
    selected: alpha(DARK_MODE.GREY[500], 0.16),
    disabled: alpha(DARK_MODE.GREY[500], 0.8),
    disabledBackground: alpha(DARK_MODE.GREY[500], 0.24),
    focus: alpha(DARK_MODE.GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};
