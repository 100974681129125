import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { Media } from '../../config/media';
import { GlobalRoutes } from '../../types/routes';
import { Link } from 'react-router-dom';
import { MyPetConstants } from '../../config/constants';
import TextEdit from '../TextEdit';
import { useContent } from '../../hooks/useContent';

interface IGetInTouchProps {
  title: string;
  subtitle?: string;
  btntext: string;
  email?: string;
  page?: string;
  sectionName?: string;
  link?: string;
}
function GetInTouch({
  title,
  subtitle,
  btntext,
  email,
  page,
  link, 
  sectionName,
}: IGetInTouchProps) {
  const _html = useContent(page as string, sectionName as string);

  const sendMail = () => {
    const el = document.createElement('a');
    el.href = `mailto:${MyPetConstants.email}`;
    el.click();
  };
  const validPage = ['home', 'mypets', 'about'].includes(page as string);

  return (
    <Box
    component="section"
      sx={{
        bgcolor: 'primary.main',
        paddingTop: { xs: '2.5rem', sm: '4.5rem' },
        backgroundImage: {
          xs: `url("${Media.PawsOrangeXs}")`,
          sm: `url("${Media.PawsOrangeSm}")`,
        },
        backgroundSize: { xs: 'contain', sm: '100% 100%' },
      }}
    >
      <Container fixed maxWidth="xl">
        <Box
          className="h2-style text-white body1-style"
          sx={{
            display: { xs: 'block', md: 'flex' },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              paddingBottom: { xs: '2.5rem', md: '3.5rem' },
              textAlign: { xs: 'center', md: 'left' },
              maxWidth:{ xs: '100%', md: '75%' },
            }}
          >
            <TextEdit page={page} sectionName={sectionName} _html={_html}>
              <>
                {title && (
                  <Typography
                    variant="h2"
                    sx={{ color: 'primary.contrastText', mb: 1 }}
                  >
                    {title}
                  </Typography>
                )}
              </>
            </TextEdit>
                {validPage && (
                  <Box
                    onClick={sendMail}
                    component="a"
                    className="maillink"
                    sx={{
                      color: 'primary.contrastText',
                      fontStyle: 'normal',
                      textDecoration: 'none',
                      display: 'inline-block',
                      cursor: 'pointer', 
                      ml: 0.7,
                    }}
                  >
                    Email us at info@mypetstext.com 
                  </Box>
                )}
          </Box>
          <Box
            sx={{
              marginLeft: 'auto',
              paddingBottom: { xs: '2rem', md: '3rem' },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Link to={link || GlobalRoutes.CONTACT} className="site-btn">
              <Button variant="outlined" className="btn-white">
                {btntext}
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default GetInTouch;
