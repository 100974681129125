import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledTabBlock } from './styles';
import Accordian from '../../components/Accordian';
import { Media } from '../../config/media';
import { Helmet } from 'react-helmet-async';
import ImageBanner from '../../components/ImageBanner';
import GetInTouch from '../../components/GetInTouch';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPageContentQuery } from '../../redux/textEdit/editorApi';
import { useEffect } from 'react';
import { addFaqPageContent } from '../../redux/textEdit/editorSlice';
import { useFetchAllFaqsQuery } from '../../redux/faqs/faqApi';
import { RootState } from '../../redux';
import { GlobalRoutes } from '../../types';
function Faq() {
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('faq');
  const { data: faqsData } = useFetchAllFaqsQuery({});
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If faq page data found, set it to redux
    if (data) {
      dispatch(addFaqPageContent(data?.data.faq));
    }
  }, [data, dispatch]);

  return (
    <StyledTabBlock>
      <Helmet>
        <title>Faqs | MypetsText</title>
      </Helmet>
      <ImageBanner img={Media.FaqBanner} />
      <Box className="sec-spacing" sx={{ mb: 5 }} component="section">
        <Container fixed maxWidth="xl">
          <Box textAlign="center">
            <Typography variant="h1" mb={2}>
              Frequently Asked Questions (FAQs)
            </Typography>
            <Typography
              variant="body1"
              mb={5}
              sx={{
                maxWidth: { xs: '750px' },
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'info.darker',
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Nibh posuere duis
              vestibulum ac viverra lectus. Congue penatibus nisl morbi
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Grid item container spacing={2} justifyContent="center">
                <Grid item sm={9} xs={12}>
                  {faqsData?.data?.length &&
                    faqsData.data.map((faq: any, ind: number) => (
                      <Accordian
                        title={faq.question}
                        content={faq.answer}
                        accId={String(ind)}
                      />
                    ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      {!isAuthenticated && <GetInTouch
        page="faq"
        sectionName="cta"
        title="Sign Up Today!"
        subtitle="Create a profile for your pet and start generating sharable quotes!"
        btntext="Get Your Pet Texting"
        link={GlobalRoutes.PRICING}
      />}
    </StyledTabBlock>
  );
}

export default Faq;
