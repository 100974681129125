import Lightbox from 'yet-another-react-lightbox';
import Download from "yet-another-react-lightbox/plugins/download";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import 'yet-another-react-lightbox/styles.css';

type PropsType = {
  open: boolean;
  onClose: () => void;
  img: string | undefined;
};

const LightBox: React.FC<PropsType> = ({ open, onClose, img }) => {
  return (
    <Lightbox
      open={open}
      close={onClose}
      slides={[
        {
          src: img as string,
        },
      ]}
      styles={{ container: { backgroundColor: 'rgba(0, 0, 0, .8)' } }}
      plugins={[Download, Fullscreen, Zoom, Captions]}
      render={{
        buttonPrev: () => null,
        buttonNext: () => null,
      }}
    />
  );
};

export default LightBox;
