import { Alert, AlertColor, Snackbar } from "@mui/material";
import { StyledAlertBlock } from "./styles";

type CustomSnackbarPropsType = {
  open: boolean;
  onClose?: () => void;
  title: string;
  anchorOrigin?: any;
  severity?: AlertColor | undefined;
  sx?: any;
  autoHideDuration?: number;
};

const CustomSnackbar = ({
  open,
  onClose,
  title,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  severity = "success",
  sx,
  autoHideDuration = 5000, 
  ...otherProps 
}: CustomSnackbarPropsType) => {
  return (
    <Snackbar autoHideDuration={autoHideDuration} open={open} anchorOrigin={anchorOrigin} {...otherProps} onClose={onClose}>
      <StyledAlertBlock>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%", ...sx }}
      >
        {title as string}
      </Alert>
      </StyledAlertBlock>
    </Snackbar>
  );
};

export default CustomSnackbar;
