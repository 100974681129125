import React, { useEffect } from "react";
import ImageBanner from "../../components/ImageBanner";
import { Box, Container, Grid, Typography } from "@mui/material";
import GetInTouch from "../../components/GetInTouch";
import AboutSection from "../../components/AboutSection";
import { Media } from "../../config/media";
import { Helmet } from "react-helmet-async";
import PetCarousel from "../../components/PetCarousel";
import TextEdit from "../../components/TextEdit";
import { useDispatch } from "react-redux";
import { useGetPageContentQuery } from "../../redux/textEdit/editorApi";
import { addAboutPageContent } from "../../redux/textEdit/editorSlice";
import { useContent } from "../../hooks/useContent";

function About() {
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('about');

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If about page data found, set it to redux
    if(data) {
      dispatch(addAboutPageContent(data?.data.about)); 
    }
  }, [data, dispatch]);

  const _petMessageHtml = useContent('about', 'petmessage');

  return (
    <>
      <Helmet>
        <title>About Us | MypetsText</title>
      </Helmet>
      <ImageBanner img={Media.AboutBanner} />
      <AboutSection
        title="Our Story"
        imgurl={Media.OurStoryImg}
        graphiclg={Media.AboutGraphiclgImagePrimary}
        graphicsm={Media.AboutGraphiclgImagePrimarysm}
        page="about"
        sectionName="story"
      />
      <Box
      component="section"
        sx={{
          my: { xs: 4, sm: 5, md: 6, lg: 7 },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Container fixed maxWidth="xl">
          <Grid item container spacing={2} alignItems="center">
            <Grid item md={6} xs={12}>
              <TextEdit page="about" sectionName="petmessage" _html={_petMessageHtml}>
                <Typography variant="h2">
                  Born from a love of pets and a desire to keep their owners
                  smiling, our app was created to bring joy and strengthen the
                  bond between you and your pet
                </Typography>
              </TextEdit>
            </Grid>
            <Grid item md={6} xs={12}>
              <PetCarousel />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <AboutSection
        title="Our Vision"
        imgurl={Media.AboutOurVissionImage}
        graphiclg={Media.AboutGraphiclgImageSecondary}
        graphicsm={Media.AboutGraphiclgImageSecondarysm}
        className="about-secondary"
        page="about"
        sectionName="vision"
      />
      <GetInTouch
        title="Get In Touch With Us!"
        subtitle="Email us at info@mypetstext.com"
        btntext="Contact Now"
        page="about"
        sectionName="cta"
      />
    </>
  );
}

export default About;
