import { Stepper } from "./constants";

type NavItem = {
    title: string;
    path: string;
    isProtected?: boolean;
    stepperCount?: number
}
type NavItemsType = NavItem[];

export const navItems: NavItemsType = [
    {
        title: 'Home', 
        path: '/', 

    }, 
    {
        title: 'How it Works', 
        path: '/how-works', 
      
    }, 
    {
        title: 'Features',
        path: '/features',
    },
   
    {
        title: 'Pricing', 
        path: '/Pricing'
    },
    {
        title: 'FAQS', 
        path: '/faqs', 
    }, 
    {
        title: 'About Us', 
        path: '/about-us', 
    }, 
    {
        title: 'Contact', 
        path: '/contact-us', 
    }, 
    {
        title: 'Gallery', 
        path: '/pet-gallery', 
        isProtected: true, 
        stepperCount: Stepper.SCHEDULE_MESSAGE, 
    },
    {
        title: 'My Pets', 
        path: '/my-pets', 
        isProtected: true, 
        stepperCount: Stepper.MY_PETS, 
    }
]