import { PetSearchDataType } from "../types/pet"
import { GlobalRoutes } from "../types/routes"

export enum Message{
    LoginTitle = 'Log in or Sign up! It\'s free!',
    LoginContent = 'With your free account, you will be able to generate a few pet-related quotes every month, and you can share these via the showcase completely free of charge!',
    Login = 'Login',
    Register = 'Register',
    Home = 'HOME',
    TermsAndConditions = 'Terms And Conditions',
    PrivacyPolicy = 'Privacy Policy',

}

export const PetCardImages = [
    '/assets/images/1.svg',
    '/assets/images/2.svg',
    '/assets/images/3.svg'
]

export const PetCrown = [
    '/assets/images/crown.svg',
    '/assets/images/crown1.svg',
    '/assets/images/crown.svg'
]
export const constants = {
    passwordMinLen: 8,
}


export const userDetails = {
    'phone_number'  : 'Contact Number',
    'gender'        : 'Gender',
    'email'         : 'Email Address',
    'no_of_pets'    : 'No. of Pets'
}


export const userAdditionalDetails = {
    'interests'         : 'Interests',
    'personality'       : 'Personality',
    'hobbies'           : 'Hobbies',
    'work_environment'  : 'work environment',
    'dob'               : 'D.O.B'
}


export const petDataHeadings = {
    'type'  : 'Kind of a pet',
    'gender': 'Your Pet’s Gender',
    'breed' : 'Your Pet’s Breed',
    'color' : 'Your Pet’s Color'
}

export const petQoutes = [
    'Steal the toys, cuddle the day away, and don\'t forget to enjoy your din-din!',
    'Don\'t let anyone pull the rug out from under your feet—unless it\'s for a game of tug-of-war!',
    'Lead the pack with courage and loyalty, and you\'ll never be led astray.'
]


export const PetTypes = [
    'Dog',
    'Cat',
    'Other'
]


export const Status = {
    'Active' :1 ,
    'Inactive' :0
}

export const QuestionCategories = [
     'General Question',
     'Presale Question',
     'Technical',
     'Others'
]

export const MyPetConstants = {
    'phone'   : '(512) 828-6763',
    'address' : '9600 Great Hills Trail, Suite 150W Austin, Texas, 78759',
    'email'   : 'info@mypetstext.com',
    'fileUpload' : 5242880, // 5MB
}

export const AccountTabs: {
    [key: string]: string;
} = {
    PROFILE: 'My Profile', 
    MY_PETS: 'My Pets', 
    SCHEDULE_MESSAGE: 'Schedule Message', 
    MY_CREDIT: 'My Credits',  
}

export const AccountTabsLink: {
    [key: string]: string;
} = {
    PROFILE: GlobalRoutes.ACCOUNT_PROFILE, 
    MY_PETS: GlobalRoutes.ACCOUNT_MY_PETS, 
    SCHEDULE_MESSAGE: GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE, 
    MY_CREDIT: GlobalRoutes.ACCOUNT_CREDITS, 
}


export const daysInWeek = [
    {
        key: 'M', 
        value: 'Monday', 
    }, 
    {
        key: 'T', 
        value: 'Tuesday', 
    }, 
    {
        key: 'W', 
        value: 'Wednesday', 
    }, 
    {
        key: 'T', 
        value: 'Thursday', 
    }, 
    {
        key: 'F', 
        value: 'Friday', 
    }, 
    {
        key: 'S', 
        value: 'Saturday', 
    }, 
    {
        key: 'S', 
        value: 'Sunday'
    }
];

export const availableScheduleTimings = [
    {
        value: 'Morning', 
        label: '07:00 AM - 12:00 PM',
    }, 
    {
        value: 'Afternoon', 
        label: '12:00 AM - 06:00 PM', 
    }, 
    {
        value: 'Evening', 
        label: '06:00 MM - 12:00 AM'
    }, 
];


export const petTypeById = {
    1: 'Dog', 
    2: 'Cat', 
    3: 'Others', 
}

export const petTypes = [
    {
        key: 1, 
        value: 'Dog', 
    }, 
    {
        key: 2, 
        value: 'Cat'
    }, 
    {
        key: 3, 
        value: 'Others'
    }
]

export const petSearchData: PetSearchDataType = {
  breed: {
    title: "Select your pet's breed",
    searchingFor: "breed",
  },
  color: {
    title: "Select your pet's color",
    searchingFor: "color",
  },
  marking: {
    title: "Select your pet's marking",
    searchingFor: "marking",
  },
};


type WeekDaysObj = {
    [key: string]: string;
}

export const weekDaysObj: WeekDaysObj = {
    'Monday': '', 
    'Tuesday': '', 
    'Wednesday': '', 
    'Thursday': '', 
    'Friday': '', 
    'Saturday': '', 
    'Sunday': '', 
}

export const scheduleTimes: {[key: string]: string;} = {
    'MORNING': 'Morning', 
    'EVENING': 'Evening', 
    'AFTERNOON': 'Afternoon', 
    'LATE NIGHT': 'Late Night'
}


export const messageTypes: {[key: string]: string;} = {
    'IMAGE': 'Image', 
    'TEXT': 'Text', 
    'BOTH': 'Both', 
}

export const daysArr: string[] = [
    'monday', 
    'tuesday', 
    'wednesday', 
    'thursday', 
    'friday', 
    'saturday', 
    'sunday', 
];

export const Stepper: { [key: string]: number; } = {
    PROFILE: 33, 
    MY_PETS: 66, 
    SCHEDULE_MESSAGE: 100, 
    MY_CREDIT: 100,     
}