import { Box, Stack, Typography, Container } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useToast } from "../../../hooks/useToast";
import { useFetchPetByIdQuery } from "../../../redux/pet/petApi";
import { GlobalRoutes } from "../../../types";
import PetForm from "../../../components/PetForm";
import Loader from "../../../components/Loader";

type EditPetPropsType = {};

const EditPet: React.FC<EditPetPropsType> = () => {
  const navigate = useNavigate();
  const { petId } = useParams();
  const { state } = useLocation();
  const { data, isLoading, error, refetch: refetchPet } = useFetchPetByIdQuery(petId);
  const toast = useToast();


  useEffect(() => {
    //@ts-ignore
    if(error && error?.status === 404) {
      toast({
        title: 'Pet not found, Or may be pet is removed', 
        severity: 'warning', 
      });
      navigate(state?.path || GlobalRoutes.ACCOUNT_MY_PETS);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if(state?.refetch) {
      refetchPet();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleBackAction = () => {
    navigate(state?.path || GlobalRoutes.MY_PETS);
  };

  return (
    <Box >
    
        <Stack
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
         
            pb: 4,
          }}
        >
          <ArrowBackIcon
            onClick={handleBackAction}
            sx={{
              cursor: "pointer",
            }}
          />
          <Typography variant="h1">Edit Your Pet Details</Typography>
        </Stack>
        <Box sx={{ mr: { lg: 25 }, }}>
          { data && <PetForm edit path={state?.path || GlobalRoutes.ACCOUNT_MY_PETS} petId={petId} editLoader={isLoading} data={data} action="update" /> }
        </Box>
  
      { isLoading && <Loader />}
    </Box>
  )
};

export default EditPet;
