import { Toolbar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const StyledHeaderNav = styled(Toolbar)`
  .notification-action {
    display: none;
  }
  .MuiSvgIcon-root {
    path {
      color: #f6911e;
    }
  }
  display: flex;

  padding: 19px 0px !important;
  .menu-list a {
    transition: 0.2s;
    font-size: 16px;
  }
  .menu-list a:hover {
    font-weight: 700;
  }

  @media (max-width: 1440px) {
    .logo-wrap {
      max-width: 195px;
    }
  }
  @media (min-width: 992px) {
    justify-content: space-between;
  }
  @media (max-width: 992px) {
    .account-info {
      margin-left: auto;
    }
  }
`;

export const StyledNavItem = styled(NavLink)`
  color: #131c23;
  text-decoration: none;
  margin-right: 1rem;
  @media (min-width: 1200px) {
    margin-right: 1.5rem;
  }
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 599px) {
    margin-right: 1rem;
  }
`;

export const StyledProfileNav = styled(Box)`
  .profile-menu {
    min-width: 230px;
  }
  .profile-menu li {
    padding: 10px 18px;
    letter-spacing: 0.48px;
    color: #343a40;
  }
`;
