import { createSlice } from "@reduxjs/toolkit";

interface initialState {
  isAuthenticated: boolean;
  token: null | string;
  editable: boolean;
  otpEmail?:string,
}

const INITIAL_STATE: initialState = {
  isAuthenticated: localStorage.getItem("access_token") ? true : false,
  token: null,
  otpEmail:'', 
  editable: false, 
};

export const authStore = createSlice({
  name: "auth",
  initialState:INITIAL_STATE,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.editable = action.payload.editable;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.editable = false;
    },
    setOtpEmail: (state, action) => {
      state.otpEmail = action.payload;
    },
  },
});

export const { loginSuccess, logout, setOtpEmail } = authStore.actions;

export default authStore.reducer;
