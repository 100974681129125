import React from "react";
import Carousel from "react-material-ui-carousel";
import { StyledPetCarousel } from "./styles";
import { Box, Button, Container, Typography } from "@mui/material";
import { Media } from "../../config/media";

function PetCarousel() {
  var items = [
    {
      imgurl: Media.CarouselPetImage,
      description:
        "Join us for a tail-wagging good time at the park! 🐾 Let's make new friends and create joyful memories with Percy leading the way!",
    },
    {
      imgurl: Media.PetImages,
      description:
      "You should've seen me chasing a mouse last night; I was on fire until I realized it was just my tail. Ever tried to catch your own tail at 3 AM? Now that's a wild night in the cabin!",
    },
  ];
  return (
    <StyledPetCarousel>
      <Carousel
        className="testimonial-sec__content pet-carousel"
        indicatorIconButtonProps={{
          style: {
            cursor: "pointer",
            transition: "200ms",
            padding: 0,
            color: "#01B9AF33",
          },
        }}
        animation="fade"
        activeIndicatorIconButtonProps={{ style: { color: "#F6911E" } }}
      >
        {items.map((item, i) => {
          return (
            <Box key={i}>
              <Box className="slideWrap">
                <Box
                  component="img"
                  alt="pet image"
                  src={item.imgurl}
                  className="pet-style img-style__cover"
                />

                <Typography
                  variant="body1"
                  sx={{margin:'0 auto !important'}}
                  className="top-engage__text quotes"
                >
                  {item.description}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </StyledPetCarousel>
  );
}

export default PetCarousel;
