import { combineReducers } from "redux";
import { appApi } from "./apis/apiSlice";
import authStore from "./auth/authStore";
import userStore from "./user/userStore";
import petStore from "./pet/petStore";
import snackbarReducer from "./snackbar/snackbarSlice";
import stepperStore from "./stepper/stepperStore";
import textEditReducer from './textEdit/editorSlice';

const rootReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  auth: authStore,
  user: userStore,
  pets: petStore, 
  stepper: stepperStore, 
  snackbar: snackbarReducer,
  textEdit: textEditReducer, 
});

export { rootReducer };
