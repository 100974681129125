import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Skeleton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { Media } from "../../config/media";
import Loader from "../Loader";
import LazyLoad, {forceCheck, forceVisible} from "react-lazyload";
import LightBox from "../Lightbox";

type DetailSearchModalPropsType = {
  isLoading: boolean;
  data: any;
  setFieldValue: any;
  searchingFor: string | undefined;
  title: string | undefined;
  onClose: () => void;
  images: { id: number; reference_image: string; name: string }[];
  setImages: Dispatch<
    SetStateAction<{ id: number; reference_image: string; name: string }[]>
  >;
  selectedData: any;
  petTypeId: number | undefined;
};

const DetailSearchModal: React.FC<DetailSearchModalPropsType> = ({
  searchingFor,
  title = `Select your pet's ${searchingFor}`,
  onClose,
  isLoading,
  data,
  setFieldValue,
  images,
  setImages,
  selectedData,
  petTypeId,
}) => {
  const [ids, setIds] = useState<number[]>([]);
  const [selectedImages, setSelectedImages] =
    useState<{ id: number; reference_image: string; name: string }[]>(images);
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<any>([]);
  const timeoutId = useRef<any>(null);
  const checkboxRef = useRef<any>(null);
  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  const [currentLightboxImg, setCurrentLightboxImg] = useState<string>('');

  useEffect(() => {
    if (data) {
      setResponse(data?.data || []);
    }
  }, [data]);

  useEffect(() => {
    if (selectedData) {
      if (typeof selectedData === "string" && selectedData?.length) {
        setIds(selectedData.split(",").map((item) => +item.trim()));
      } else if (Array.isArray(selectedData)) {
        setIds(selectedData?.filter((i) => i));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeoutId.current);
      }
      setResponse([]);
      setQuery("");
    };
  }, []);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    if (query) {
      setLoading(true);
      timeoutId.current = setTimeout(() => {
        forceVisible();
        const res = data?.data?.filter((d: any) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
        setResponse(res);
        setLoading(false);
      }, 1);
    } else {
      setLoading(true);
      timeoutId.current = setTimeout(() => {
        setResponse(data?.data);
        setLoading(false);
      }, 1);
    }
  }, [data?.data, query]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((prev) => e.target.value);
  };

  const handleClick = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event>,
    id: number,
    reference_image: string | null,
    name: string
  ) => {
    //@ts-ignore
    if (e.target.checked) {
      if (searchingFor === "breed" || searchingFor === "marking") {
        if (ids?.length >= 2) {
          setIds([ids[1], id]);
          setSelectedImages([
            selectedImages[1],
            { id, reference_image: reference_image || "", name },
          ]);
        } else {
          setIds([...ids, id]);
          setSelectedImages([
            ...selectedImages,
            { id, reference_image: reference_image || "", name },
          ]);
        }
      } else {
        if (ids?.length >= 3) {
          setIds([ids[1], ids[2], id]);
          setSelectedImages([
            selectedImages[1],
            selectedImages[2],
            { id, reference_image: reference_image || "", name },
          ]);
        } else {
          setIds([...ids, id]);
          if (reference_image) {
            setSelectedImages([
              ...selectedImages,
              { id, reference_image, name },
            ]);
          } else {
            setSelectedImages([
              ...selectedImages,
              { id, reference_image: "", name },
            ]);
          }
        }
      }
    } else {
      setIds(ids?.filter((i) => i !== id));
      setSelectedImages(selectedImages?.filter((i) => i.id !== id));
    }
  };

  const handleSave = () => {
    if (searchingFor === "breed") {
      setFieldValue("primary_breed_id", ids[0]);
      setFieldValue("secondary_breed_id", ids[1] || '');
    } else {
      let stringIds: string = "";
      ids.forEach((id, index) => {
        // Last item
        if (index === ids?.length - 1) {
          stringIds += `${id}`;
        } else {
          stringIds += `${id},`;
        }
      });
      if (searchingFor === "color") {
        setFieldValue("color_ids", stringIds);
      } else if (searchingFor === "marking") {
        setFieldValue("marking_ids", stringIds);
      }
    }
    setImages(selectedImages);
    onClose && onClose();
  };

  const handleImgClick = (imgUrl: string) => {
    setShowLightbox(true);
    setCurrentLightboxImg(imgUrl);
  }
  

  return (
    <Box>
      <Dialog open onClose={onClose} sx={{ minHeight: "200px " }}>
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "400",
              p: 4,
              boxShadow: "0px 4px 13px 0px #AFAFAF4D",
            }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />
        <DialogContent id="dialog-content" sx={{ bgcolor: "secondary.light" }}>
          {loading && <Loader />}
          <TextField
            fullWidth
            placeholder={`Search for your ${searchingFor}`}
            name="query"
            value={query}
            onChange={handleQueryChange}
            sx={{
              mb: 2,
            }}
          />
          {searchingFor === "breed" && (
            <Typography variant="body1" sx={{ mt: 4, mb: 2 }}>
              <label>Popular mixed {searchingFor}</label>
            </Typography>
          )}
          <Grid container spacing={2} mt={0}>
            {/* Searched Data */}
            {!!response?.length &&
              response.map(
                ({
                  id,
                  reference_image,
                  name,
                  large_reference_image,
                }: {
                  id: number;
                  reference_image: string | null;
                  large_reference_image: string | null;
                  name: string;
                }) => (
                  <Grid xs={12} sm={6} lg={3} paddingLeft={"16px"} key={id}>
                    <LazyLoad
                      debounce={1}
                      key={id}
                      scroll
                      once
                      scrollContainer="#dialog-content"
                      placeholder={
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          height={250}
                          sx={{
                            mb: 2,
                            width: "255px",
                            "@media (max-width: 600px)": {
                              width: "100%",
                            },
                          }}
                        />
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid #E1E1E1",
                          bgcolor: "#fff",
                          borderRadius: "6px",
                          pt: 1,
                          pb: 2,
                          px: 2,
                          mb: 2,
                          cursor: 'pointer', 
                        }}
                      >
                        <FormControlLabel
                          key={id}
                          sx={{
                            width: "100%",
                            m: 0,
                            mb: 2,
                            justifyContent: "space-between",
                            textAlign: "right",
                          }}
                          className="pet-label"
                          control={<Checkbox checked={ids?.includes(id)} ref={checkboxRef} />}
                          label={name}
                          onChange={(e) =>
                            handleClick(e, id, reference_image, name)
                          }
                        />
                        <Box
                          onClick={() => handleImgClick(large_reference_image || '')}
                          component="img"
                          src={
                            reference_image ||
                            (petTypeId === 1
                              ? Media.DogPlaceholderImage
                              : Media.CatPlaceholderImage)
                          }
                          sx={{
                            width: "180px",
                            height: "180px",
                            objectFit: "contain",
                            objectPosition: "top",
                            borderRadius: "13px",
                            marginLeft: { xs: "auto" },
                            marginRight: { xs: "auto" },
                          }}
                        />
                      </Box>
                    </LazyLoad>
                  </Grid>
                )
              )}

            {!response?.length ? (
              <Typography sx={{
                textAlign: 'center', 
                width: '100%'
              }} variant="h1">No Data Found</Typography>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: 6, mx: 2 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            sx={{ borderRadius: 6, ml: 2 }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {
        (showLightbox && searchingFor === 'breed') && (
          <LightBox open={showLightbox} onClose={() => setShowLightbox(false)} img={currentLightboxImg} />
        )
      }
    </Box>
  );
};

export default DetailSearchModal;
