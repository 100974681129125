import { Box, Typography } from "@mui/material";
import ScheduleMessageForm from "../../../components/my-account/ScheduleMessage";
import { Helmet } from "react-helmet-async";

type ScheduleMessagePropsType = {};

const ScheduleMessage: React.FC<ScheduleMessagePropsType> = () => {
  return (
    <>
      <Helmet><title>Schedule Message | MypetsText</title></Helmet>
      <Box>
        <Typography
          variant="h1"
          sx={{ textAlign: "left", mb: { xs: 2, sm: 3, md: 4, lg: 5 } }}
        >
          Schedule Message
        </Typography>
        <Box sx={{ textAlign: "left" }}>
          <ScheduleMessageForm />
        </Box>
      </Box>
    </>
  );
};

export default ScheduleMessage;
