import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBlock = styled(Box)`
  .address {
    max-width: 195px;
    line-height: 1.5;
  }
  a {
    line-height: 1.8;
    color: #000;
    text-decoration: none;
    padding-top: 0;
    padding-bottom: 0;
    &:hover {
      color: #f6911e;
    }
  }

  .copy-right {
    font-size: 0.875rem !important;
    line-height: 2rem !important;
  }

  @media(max-width: 992px){
    .address {
        margin: 0 auto;
      }
  }

  @media (max-width: 599px) {
    .footer-list,
    .footer-list ul,
    .footer-list li:first-of-type {
      padding-top: 0 !important;
    }
  }
`;


