import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { daysArr, daysInWeek } from "../../../config/constants";
import { DayContainer, CalenderRow } from "./styles";
import React, { useEffect, useState } from "react";
import { ScheduleTimesType } from "../../../types/schedule";
import { convertTimeRange } from "../../../utils/helper";

type WeeklyMessageFrequencyPropsType = {
  setFieldValue: any;
  allScheduleTimes: ScheduleTimesType[] | undefined;
  values?: any;
  timezone: any;
};
const WeeklyMessageFrequency: React.FC<WeeklyMessageFrequencyPropsType> = ({
  setFieldValue,
  allScheduleTimes,
  values,
  timezone,
}) => {
  /**
   * Data(State) structure
   *      {
   *          Monday: ['Morning', 'Evening'],
   *          Sunday: ['Morning', 'Evening', 'Afternoon']
   *          Tuesday: ['Morning']
   *      }
   */
  const [weeklyData, setWeeklyData] = useState<any>({});

  // Initializing fields
  useEffect(() => {
    if (values?.message_frequency === "Weekly") {
      const data: any = {};
      daysArr.forEach((day: string) => {
        const genKey = day?.charAt(0).toUpperCase() + day?.slice(1);
        const valueArr = values[day]?.split(",");
        // If values exists
        if (valueArr[0]) {
          data[genKey] = valueArr;
        }
      });
      setWeeklyData(data);
    } else {
      setWeeklyData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  //Setting formik
  useEffect(() => {
    if (weeklyData) {
      Object.keys(weeklyData)?.forEach((key: string) => {
        const val = weeklyData[key].join(",");
        setFieldValue(key.toLowerCase(), val?.length ? val : "");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyData]);

  const handleDayClick = (value: string) => {
    const data = { ...weeklyData };
    if (weeklyData[value]) {
      delete data[value];
      setFieldValue(value.toLowerCase(), "");
    } else {
      data[value] = [];
    }
    setWeeklyData(data);
  };

  const handleDayTimeChange = (dayValue: string, timeValue: string) => {
    if (weeklyData[dayValue]) {
      const data = { ...weeklyData };
      if (weeklyData[dayValue]?.includes(timeValue)) {
        data[dayValue] = data[dayValue]?.filter((v: string) => v !== timeValue);
      } else {
        data[dayValue] = [...data[dayValue], timeValue];
      }
      setWeeklyData({ ...data });
    }
  };

  return (
    <Box sx={{ overflow: "auto" ,pl:1}} >
      <Grid
        container
        sx={{ mb: 4, minWidth: { xs: "800px", md: "auto" } }}
        spacing={3}
        alignItems={"center"}
      >
        <Grid item xs={3} sx={{ pb: { xs: 2, md: 3, lg: 4 } }}>
          <Box sx={{ textAlign: "right" }}>
            <label className="text-sm">
              {" "}
              First, select the day of the week, then choose the time slot you prefer to receive messages:
            </label>
          </Box>
        </Grid>
        <Grid item xs={9} lg={7} sx={{ pb: { xs: 0 } }}>
          <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
            {/* Render days */}
            {daysInWeek?.map(({ key, value }) => (
              <Grid item sx={{ flex: "1" }}>
                <DayContainer
                  className={
                    Object.keys(weeklyData).includes(value) ? "active" : ""
                  }
                  key={value}
                  onClick={() => handleDayClick(value)}
                >
                  <Typography variant="caption" color="info.darker">
                    {key}
                  </Typography>
                </DayContainer>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <React.Fragment>
          {allScheduleTimes?.map(
            ({
              id,
              title: timeValue,
              start_time,
              end_time,
            }: ScheduleTimesType) => {
              return (
                <React.Fragment key={id}>
                  <Grid
                    item
                    xs={3}
                    sx={{ paddingTop: "8px !important", paddingBottom: "8px" }}
                  >
                    <Stack direction="column" textAlign={"right"}>
                      <Typography variant="body1" color="info.darker" sx={{textTransform:'capitalize'}}>
                        {timeValue.toLowerCase() === 'latenight' ? 'Late Night' :  timeValue[0].toUpperCase() + timeValue?.slice(1)?.toLowerCase()}
                      </Typography>
                      <Typography color="info.darker" variant="body2">
                        ({convertTimeRange(
                          start_time,
                          end_time,
                        )}){" "}<strong>{timezone}</strong>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={9} lg={7} sx={{ paddingTop: "0 !important" }}>
                    <Grid container spacing={0} my={0} sx={{ height: "100%" }}>
                      {daysInWeek?.map(({ value: dayValue }) => (
                        <Grid
                          item
                          sx={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <CalenderRow className="calender-time">
                            <Checkbox
                              key={id}
                              onChange={() =>
                                handleDayTimeChange(dayValue, timeValue)
                              }
                              checked={Boolean(
                                weeklyData[dayValue] &&
                                  weeklyData[dayValue]?.includes(timeValue)
                              )}
                              disabled={!Boolean(weeklyData[dayValue])}
                            />
                          </CalenderRow>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            }
          )}
        </React.Fragment>
      </Grid>
    </Box>
  );
};

export default WeeklyMessageFrequency;
