import { CircularProgress } from "@mui/material";


type LoaderPropsType = {
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";
    size?: number;
    sx?: any;
}

const Loader: React.FC<LoaderPropsType> = ({ color = 'primary', size = 50, sx = {} }) => {
    return (
        <CircularProgress color={color} size={size} sx={{
            position: 'fixed', 
            top: '50%', 
            left: '50%', 
            zIndex: 1000, 
            transform: 'transpose(-50%, -50%)', 
            ...sx  
        }} />
    );
}

export default Loader;