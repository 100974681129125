import React from "react";
import { StyledLoginButtons } from "./styles";
import GoogleLoginButton from "./GoogleButton";
import FaceBookLoginButton from "./FacebookButton";

function SocialButtons() {
  return (
    <StyledLoginButtons className="flex-center">
      <GoogleLoginButton />
      <FaceBookLoginButton />
    </StyledLoginButtons>
  );
}

export default SocialButtons;
