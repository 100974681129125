import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import SideBanner from "../../../components/auth/SideBanner";
import { Message } from "../../../config/messages";
import ForgotPasswordForm from "../../../components/auth/ForgotPassword/layout";
import SideImgBanner from "../../../components/SideImgBanner";
function ForgotPassword() {
  return (
    <Box className="main">
      <Container fixed maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            className="login-info"
    
            sx={{ display:'flex',position: { xs: "relative", sm: "static" },minHeight:{lg:'900px'} }}
           
          >
             <SideImgBanner />
            <SideBanner
              title={Message.LoginTitle}
              content={Message.RegisterContent}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className="login-wrap"
              sx={{
                paddingLeft: { md: "4rem" },
                paddingTop: { xs: "2rem", sm:"6rem", lg:"7rem" },
                paddingBottom: { xs: "2rem", md: "7rem" },
              }}
            >
              <Typography variant="h1" sx={{ mb: 2 }}>
                {Message.ForgotPassword}
              </Typography>
              <ForgotPasswordForm
                title={Message.PasswordReset}
                content={Message.ForgotPasswordContent}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ForgotPassword;
