import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledVideoBlock = styled(Box)`
  position: relative;
   .banner-content{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    /*text edit */
    .MuiTypography-body1{
      color: #fff;
      line-height: 1.5;
  
    }
      .MuiButton-contained {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-weight: 600;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: Poppins, sans-serif;
        min-width: 64px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: rgb(1, 185, 175);
        padding: 0.5rem 2.9rem;
        border-radius: 30px;
        border: 2px solid rgba(255, 255, 255, 0);
        text-transform: none;
        box-shadow: none;
        color: rgb(255, 255, 255);
        &:hover{
          background-color: rgba(255, 255, 255, 0);
          box-shadow: none;
          color: rgb(255, 255, 255);
          border-color: rgb(1, 185, 175);
        }
      
    }
}
  
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgb(0 0 0 / 28%);
    z-index: 1;
  }
  .banner-wrap {
    object-fit: cover;
    height: 100%;
    display: block;
    position: absolute;
    width:100%;
    inset:0;
  }

  .banner-content h3 {
    line-height: 1.8;
    font-weight: 400;
    font-style: italic;
  }

  .banner-content p {
    font-style: italic;
    font-weight: 500;
    max-width: 682px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner-content {
    position: relative;
    max-width: 1080px;
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 2;
    margin: 0 auto;
    min-height: calc(95vh - 196px);
  }
  /*text edit */
  @media (min-width: 600px){
    .banner-content .MuiButton-contained  {
      font-size: 1rem;
  }  
  }
  @media (min-width: 1200px){
    .banner-content .MuiButton-contained  {
      font-size: 1.25rem;
  }  
  }
`;