
export default function Button(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
            boxShadow: 'none',
            padding: '0.5rem 2.9rem',
            borderRadius: "30px",
            border: "2px solid",
            borderColor: theme.palette.primary.lighter,
            textTransform:"none",
        },
        containedInherit: {
          color: theme.palette.grey[0],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: "none",
          color: theme.palette.grey[0],
          '&:hover': {
            backgroundColor: theme.palette.primary.lighter,
            boxShadow: "none",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        },
        containedSecondary: {
          boxShadow: "none",
          color: theme.palette.grey[0],
          '&:hover': {
            backgroundColor: theme.palette.secondary.lighter,
            boxShadow: "none",
            color: theme.palette.grey[0],
            borderColor: theme.palette.secondary.main,
          },
        },
        outlinedInherit: {
          border: `2px solid theme.palette.primary.contrastText`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
