import { Box, styled } from "@mui/material";


export const SpaceBetweenContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
        font-weight: 600;
    }
    span+ span{
        font-weight: 700;
    }
    @media(max-width:767px){
      span{
        width: 50%;
        text-align:left;
      }
      span +span{
        text-align:right;
      }
    }
`;