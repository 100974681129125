import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPetCarousel = styled(Box)`
text-align: center;
.pet-carousel{
    div{
        &:nth-child(2),&:nth-child(3){
            height: 262px;
           } 
    }

}
.slideWrap{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-left: 50px;
    padding-right: 50px;
}
.pet-style{
    width: 262px;
    height: 262px;
    border-radius: 20px;
    object-position: top;
    margin: 0 auto 35px;
    padding-right: 0;
}
.top-engage__text{
    display: inline-flex;
    font-style: italic;
     font-weight: 300;
     max-width: 445px;
}
.top-engage__text::before,.top-engage__text::after{
    width: 20px;
    height: 20px;
    content:'';
}
.top-engage__text::after{
    background-image:url("/assets/images/quotes.svg");
    bottom: 6px;
    right: -24px;
}
.top-engage__text::before{
    background-image:url("/assets/images/quotes-left.svg");
    top: -3px;
    left: -20px; 
}
@media(max-width: 599px){
    .pet-carousel{
        div{
            &:nth-child(2),&:nth-child(3){
                height: 200px;
               } 
        }
    
    }
    .pet-style{
        width: 200px;
        height: 200px;
    }
  .top-engage__text::before,.top-engage__text::after{
    width: 12px;
}
`;
