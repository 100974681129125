import { createSlice } from "@reduxjs/toolkit";

interface initialState {
  userData: {
    city_state_timezone: any;
    city_state_name: any;
    other_state: any;
    first_name?: string;
    last_name?: string;
    email?: string;
    id?: number;
    profile_pic?: string;
    gender?: string;
    date_of_birth?: string;
    contact_number?: string;
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    type_of_residence?: string;
    type_of_neighborhood?: string;
    user?: {
      first_name: string;
      last_name: string;
      email: string;
    }
  };
}

const INITIAL_STATE: initialState = {
  userData: {
    city_state_name: undefined,
    other_state: undefined,
    city_state_timezone: undefined
  },
};

export const userStore = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
  },
});

export const { setUserData } = userStore.actions;

export default userStore.reducer;
