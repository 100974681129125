import * as yup from 'yup';

export const scheduleMessageSchema = yup.object().shape({
    pet: yup.string().nullable().required('Pet is required'), 
    message_schedule_type: yup.number().required('Message Type is required'), 
    message_frequency: yup.string().required('Message Frequency is required'), 
    starts_date: yup.mixed().required('Starts on is required'), 
    monday: yup.string(), 
    tuesday: yup.string(), 
    wednesday: yup.string(), 
    thursday: yup.string(), 
    friday: yup.string(), 
    saturday: yup.string(), 
    sunday: yup.string(), 
});