import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProps } from 'formik';
import { resetPasswordSchema } from '../../../validators/schemas/authSchemas';
import { useResetpasswordMutation } from '../../../redux/auth/authApi';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IResetPassword,
  IResetPasswordResponse,
} from '../../../interfaces/auth';

import { toastError } from '../../Toasters';
import { Message } from '../../../config/messages';
import ErrorMessage from '../../ErrorMessage';
import { useToast } from '../../../hooks/useToast';
import { GlobalRoutes } from '../../../types';

interface ResetPasswordFormProps {
  title: string;
}

function ResetPasswordForm({ title }: ResetPasswordFormProps): JSX.Element {
  const [messageType, setMessageType] = useState<Boolean>(false);
  const [message, setMessage] = useState<string>('');
  const toast = useToast();

  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<Boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const { uid, tokenId } = useParams();
  const [resetPassword, { isLoading }] = useResetpasswordMutation();
  const [err, setErr] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!uid && !tokenId) {
      toastError(Message.SomethingWrong);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }: FormikProps<any> = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async ({ password, confirm_password }, { resetForm }) => {
      if (confirm_password !== password) return;
      try {
        const payload: IResetPassword = {
          token: tokenId,
          password,
          uidb64: uid,
        };
        setLoading(true);
        await resetPassword(payload)
          .unwrap()
          ?.then((res: IResetPasswordResponse) => {
            resetForm();
            toast({
              title: 'Password successfully created',
            });
            setLoading(false);
            navigate(GlobalRoutes.LOGIN);
          })
          ?.catch((error: any) => {
            setErr(error?.data?.message || Message.SomethingWrong);
            setLoading(false);
          });
      } catch (err: any) {
        toastError(Message.SomethingWrong);
      }
    },
  });

  const { password, confirm_password } = values;

  return (
    <>
      <Box>
        {showAlert && err && (
          <Collapse in>
            <Alert
              sx={{ my: 2 }}
              variant="filled"
              severity="error"
              onClose={() => setShowAlert(false)}
            >
              {err}
            </Alert>
          </Collapse>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ my: 1.5, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
              color={errors.password ? 'error' : 'primary'}
              error={!!(touched.password && errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {errors.password && (
              <FormHelperText error id="accountId-error">
                {touched.password &&
                  errors.password &&
                  typeof errors.password === 'string' &&
                  errors.password}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ my: 1.5, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirmPassword"
              name="confirm_password"
              value={confirm_password}
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={handleChange}
              onBlur={handleBlur}
              color={errors.email ? 'error' : 'primary'}
              error={!!(touched.confirm_password && errors.confirm_password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
            {errors.confirm_password && (
              <FormHelperText error id="accountId-error">
                {touched.confirm_password &&
                  errors.confirm_password &&
                  typeof errors.confirm_password === 'string' &&
                  errors.confirm_password}
              </FormHelperText>
            )}
          </FormControl>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '100%', mt: 3, mb: 6 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ ml: 2 }} color="inherit" size={24} />
            ) : (
              `${title}`
            )}
          </LoadingButton>
        </form>
      </Box>
    </>
  );
}

export default ResetPasswordForm;
