import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLink = styled(Box)`
  margin-left: auto;
  order: 1;
  .facebook-icon {
    svg {
      top: 5px !important;
      width: 17px;
    }
  }
  .tiktok-icon,
  .threads-icon {
    svg {
      width: 22px;
      top: 4px !important;
    }
  }

  .twitter-icon {
    svg {
      width: 24px;
      top: 4px !important;
    }
  }

  &.primary-icons .social {
    a {
      background-color: #f6911e;
      svg {
        fill: #fff;
      }
    }
  }

  .social a {
    margin-right: 15px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    svg {
      fill: #01b9af;
      font-size: 30px;
      position: relative;
      top: 1px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 599px) {
    order: 1;
    margin-right: auto;
  }
`;
