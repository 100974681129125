import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CloseIcon from "@mui/icons-material/Close";

export const StyledPetDetailCard = styled("div")`
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .img-placeholder p{
    width: 100%;
    top:67%;
    text-align: center;
    padding: 15px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
  }

  .pet-img + .img-placeholder{
   display: none;
  }
  .comment-wrap {
    display: flex;
    alignItems: flex-end;
    border: 1px solid !important;
    position: relative;
    border-radius: 6px;
    height: 37px !important;
    padding: 10px;
    margin-top: auto;
    width: calc(100% - 16px);
    margin-bottom: 8px;
    right: -8px;
   
    fieldset {
      border: 0 !important;
    }
    * {
      height: 100% !important;
    }
    input[type="text"] {
      padding: 0 !important;
    }
  }

  .comment-wrap button {
    position: absolute;
    right: -16px;
    top: -3px;
    transform: rotate(-45deg);
    background-color: transparent;
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .comment-wrap .tick-icon {
    transform: rotate(45deg);
  }
  .pet-img {
    height: 382px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .pet-description {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .status-btn {
    display: inline-flex;
    position: absolute;
    bottom: 13px;
    right: 13px;
    border: 1px solid;
    border-radius: 15px;
    box-shadow: 0px 1px 5px 0px #00000026;
  }
  .pet-description + div .status-btn {
    position: static;
  }
  .status-btn button {
    min-width: 39px;
  }
  .pet-text {
    display: flex;
    justify-content: space-between;
    span{
      font-size: 10px;
      line-height: 12px;
      font-weight: 600;
    }
  }
`;
