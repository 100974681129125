import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StyledEngagementBlock, StyledEngagementImg } from "./styles";
import { Media } from "../../config/media";
import { GlobalRoutes } from "../../types/routes";
import { useFetchAllPetsQuery } from "../../redux/pet/petApi";
import { PetData } from "../../interfaces/pet";
import Loader from "../Loader";

interface IShowcaseProps {
  isAuthenticated: boolean;
}

function ShowcasePets({ isAuthenticated }: IShowcaseProps): JSX.Element {
  const { data: petsList, isLoading, refetch } = useFetchAllPetsQuery({});
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const handleViewMore = (id: number) => {
    navigate(`/my-pets/detail/${id}`, {
      state: { path: GlobalRoutes.MY_PETS },
    });
  };

  const addNewPetHandler = () => {
    navigate(GlobalRoutes.ADD_PET, {
      state: { path: GlobalRoutes.MY_PETS },
    });
  };

  return (
    <>
      <Box
      component="section"
        sx={{
          bgcolor: "secondary.light",
          textAlign: "center",
          paddingTop: "5rem",
          paddingBottom: "3rem",
          backgroundImage: {
            xs: `url("${Media.PawsTransparentXs}")`,
            sm: `url("${Media.PawsTransparentSm}")`,
          },
          backgroundSize: { xs: "contain", sm: "100% 100%" },
        }}
      >
        <Container fixed maxWidth="xl">
          {!isAuthenticated && (
            <Box textAlign="center" mb={2}>
              <Typography variant="h2" mb={2}>
                Showcase Pets
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "800px",
                  margin: "0 auto 1.5rem",
                  color: "info.darker",
                }}
              >
                The showcase is a place where you can view quotes from other pet
                owners. When you create a profile for your pet, you can also add
                your own motivational quotes to the showcase.
              </Typography>
              <Link to="#" color="primary" className="text-underline">
                What’s your pet’s story? Share it with us.
              </Link>
            </Box>
          )}
          <Typography variant="h1" sx={{ marginBottom: "2rem" }}>
            My Pets List
          </Typography>
          <Grid item container spacing={2} justifyContent="center">
            {petsList?.data &&
              petsList.data.map(
                ({ id, profile_image, name, description }: PetData) => (
                  <Grid key={id} item md={4} sm={6} xs={12} mb={4}>
                    <StyledEngagementImg className="top-engage">
                      <Box
                        sx={{ position: "relative", zIndex: "2" }}
                        component="img"
                        src={(profile_image as string) || Media.PetImage}
                        className="img-style img-style__contain pet-img"
                      />
                    </StyledEngagementImg>
                    <StyledEngagementBlock className="top-engage__block">
                      <Typography variant="h2" mb={2}>
                        {name}
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          className="top-engage__text quotes"
                          sx={{ fontStyle: "italic", fontWeight: 300 }}
                        >
                          {description}
                        </Typography>
                      </Box>

                      <Box sx={{ marginTop: "auto" }}>
                        <Typography
                          onClick={() => handleViewMore(id)}
                          color="primary"
                          sx={{
                            display: "inline-block",
                            textTransform: "capitalize",
                            textDecoration: "underline !important",
                            marginTop: "1rem",
                            fontWeight: "600",
                            fontStyle: "italic",
                            cursor: "pointer",
                          }}
                        >
                          view more
                        </Typography>
                      </Box>
                    </StyledEngagementBlock>
                  </Grid>
                )
              )}
          </Grid>
          <Box textAlign="center" mt={4}>
            {!isAuthenticated ? (
              <Button variant="contained" color="primary" href="#text-buttons">
                Try Your Own
              </Button>
            ) : (
              <Button
                onClick={addNewPetHandler}
                variant="contained"
                color="secondary"
              >
                Add New Pet
              </Button>
            )}
          </Box>
        </Container>
      </Box>
      {isLoading && <Loader />}
    </>
  );
}

export default ShowcasePets;
