import { Box, Typography } from "@mui/material";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { Media } from "../../../config/media";
import { useSocialLoginMutation } from "../../../redux/auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ILoginResponseInterface } from "../../../interfaces/auth";
import { loginSuccess } from "../../../redux/auth/authStore";
import { GlobalRoutes } from "../../../types/routes";
import { Message } from "../../../config/messages";
import { LoadingButton } from "@mui/lab";
import { Status, Stepper } from "../../../config/constants";
import { useToast } from "../../../hooks/useToast";
import { useEffect, useState } from "react";
import { useLazyGetProfileStepperQuery } from "../../../redux/stepper/stepperApi";
import { setProfileCompleted } from "../../../redux/stepper/stepperStore";
import { getNavigationUrl } from "../../../utils/helper";
import Loader from "../../Loader";

function FaceBookLoginButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [socialLogin, { isLoading }] = useSocialLoginMutation();
  const [payload, setPayload] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchStepperData] = useLazyGetProfileStepperQuery();

  useEffect(() => {
    if(payload && success) {
      handleSuccess(payload);
    }
  }, [payload, success]);

  const loginHandler = async ({ data }: ILoginResponseInterface) => {
    const userToken = data?.token;
    if (!userToken) return;
    dispatch(
      loginSuccess({
        isAuthenticated: true,
        token: userToken,
      })
    );
    try {
      const stepperRes = await fetchStepperData({});
      const completed = stepperRes?.data?.data?.completed;
      if (stepperRes) {
        dispatch(setProfileCompleted(completed));
        if (completed < Stepper.MY_CREDIT) {
          const url = getNavigationUrl(completed);
          navigate(url, {
            state: {
              message: true,
            }
          });
        } else {
          navigate(GlobalRoutes.HOME);
        }
      }
      setLoading(false);
      toast({
        title: Message.LoginSuccessfully,
      })
      setLoading(false); // User is successfully logged in 
    } catch (err) {
      setLoading(false);
      toast({
        title: Message.SomethingWrong,
        severity: 'error'
      });
    }
  };

  const handleSuccess = async (payload: any) => {
    try {
      setLoading(true);
      await socialLogin(payload)
        .unwrap()
        ?.then((res) => {
          loginHandler(res as ILoginResponseInterface);
        })
        ?.catch((error: any) => {
          setLoading(false);
          toast({
            title: Message.SomethingWrong,
            severity: 'error'
          });
        });
    } catch (err) {
      setLoading(false);
      toast({
        title: 'Unable to login at the moment',
        severity: 'error',
      });
    }
  }

  return (
    <>
    { loading && <Loader />}
      <FacebookLogin
        appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
        onFail={(error) => {
          toast({
            title: Message.SomethingWrong, 
            severity:'error', 
          });
        }}
        onSuccess={(res: any) => {
          if(!res?.accessToken) {
            return;
          }
          setSuccess(true);
        }}
        onProfileSuccess={async (response) => {
          const name = response.name?.split(' ');
          const first_name = name ? name[0] : '';
          const last_name = name ? name[1] : '';
          const image = response?.picture?.data?.url || '';
          const payload = {
            email: response.email,
            first_name,
            last_name,
            image, 
            status_type: Status.Active,
          };
          setPayload(payload);
        }}
        render={({ onClick }) => (
          <LoadingButton
            component="button"
            onClick={onClick}
            className="social-btn"
            sx={{ bgcolor: "info.lighter" }}
            loading={isLoading}
          >
            <Box
              component="img"
              className="img-style img-style__contain"
              alt="google-icon"
              src={Media.FacebookLoginButtonIcon}
              mr={2}
            />
            <Typography variant="body2">Continue with Facebook</Typography>
          </LoadingButton>
        )}
      />
    </>
  );
}

export default FaceBookLoginButton;
