import { useDispatch } from "react-redux"
import { openSnackbar } from "../redux/snackbar";
import { ISnackbar } from "../interfaces/toast";


export const useToast = () => {
    const dispatch = useDispatch();

    return ({ title, ...otherProps }: ISnackbar) => {
        dispatch(openSnackbar({
            title, 
            ...otherProps, 
        }));
    }
}