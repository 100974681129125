import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContactBlock = styled(Box)`
.contact-info{
    padding-top: 4rem;
}
  .contact-info .MuiTypography-caption{
    max-width: 245px;
    display: inline-block;
  }
  @media(max-width:767px){
.contact-info {
  padding-top: 4rem;
    padding-bottom: 4rem;
}
  }
`;


