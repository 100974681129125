import React, { useState } from "react";

import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Link  } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { GlobalRoutes } from "../../../types/routes";
import { useFormik, FormikProps } from "formik";
import { registerSchema } from "../../../validators/schemas/authSchemas";
import LoadingButton from "@mui/lab/LoadingButton";
import { Message } from "../../../config/messages";
import { useRegisterUserMutation } from "../../../redux/auth/authApi";
import SocialButtons from "../../social";
import { useToast } from "../../../hooks/useToast";
import { toSnakeCase } from "../../../utils/helper";
import { IRegister } from "../../../interfaces/auth";
interface RegisterFormProps {
  title: string;
}

type MessageType = {
  text: string;
  type: null | "success" | "error" | "info";
};

type RegisterFormValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function RegisterForm({ title }: RegisterFormProps): JSX.Element {
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  // State to manage user responses text (success or errors)
  const [message, setMessage] = useState<MessageType | null>();
  const toast = useToast();

  const [registerUser, { isLoading }] = useRegisterUserMutation();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }: FormikProps<RegisterFormValuesType> = useFormik({
    initialValues: {
      firstName: '', 
      lastName: '', 
      email: "",
      password: "",
    },
    validationSchema: registerSchema,
    onSubmit: async ({ firstName, lastName, email, password }, { resetForm }) => {
      try {
        setMessage(null);
        const payload = toSnakeCase({
          firstName, 
          lastName, 
          email, 
          password, 
        });
        const res = await registerUser(payload as IRegister).unwrap();
        if (res?.message) {
          toast({
            title: res?.message, 
            severity: 'success', 
          });
          resetForm();
        }
      } catch (err: any) {
        const messageObj: MessageType = {
          type: "error",
          text: "",
        };
        const errors = err?.data?.errors;
        if (errors && Object.keys(errors)?.length) {
          messageObj["text"] = errors?.email
            ? Message.EmailExists
            : Message.SomethingWrong;
        } else {
          messageObj["text"] = Message.SomethingWrong;
        }
        setMessage(messageObj);
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const closeAlert = () => {
    setMessage(null);
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          {/* Alert for showing error messages */}
          {(message?.type === "error" || message?.type === "info") && (
            <Collapse in>
              <Alert
                sx={{ my: 2 }}
                variant="filled"
                severity={message.type === "error" ? "error" : "info"}
                onClose={closeAlert}
              >
                {message.text}
              </Alert>
            </Collapse>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl sx={{ my: 1.5, width: "100%" }}>
                <TextField
                  name="firstName"
                  value={values?.firstName}
                  label="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.firstName && errors.firstName)}
                  helperText={
                    touched.firstName && errors.firstName && errors?.firstName.toString()
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ my: 1.5, width: "100%" }}>
                <TextField
                  name="lastName"
                  value={values?.lastName}
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(touched.lastName && errors.lastName)}
                  helperText={
                    touched.lastName && errors.lastName && errors?.lastName.toString()
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 1.5, width: "100%" }}>
            <TextField
              className="login-textfield"
              type="email"
              label="Enter your email"
              variant="outlined"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.email && errors.email)}
              helperText={
                touched.email &&
                errors.email &&
                typeof errors.email === "string" &&
                errors.email
              }
            />
          </FormControl>
          <FormControl sx={{ my: 1.5, width: "100%" }}>
            <InputLabel
              error={!!(touched.password && errors.password)}
              htmlFor="password"
            >
              Create your password
            </InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              color={errors.password ? "error" : "primary"}
              error={!!(touched.password && errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Create your password"
            />
            {touched.password && errors.password && (
              <FormHelperText error id="accountId-error">
                {errors?.password.toString()}
              </FormHelperText>
            )}
          </FormControl>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: "100%", mt: 3, mb: 6 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ ml: 2 }} color="inherit" size={24} />
            ) : (
              `${title}`
            )}
          </LoadingButton>
        </form>
      </Box>

      <Typography variant="body2" textAlign="center" sx={{ mb: 4 }}>
        or {title} with
      </Typography>
      <Box>
        <SocialButtons />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="body2" mr={1}>
          Already have an account?{" "}
        </Typography>
        
        <Typography variant="body2" sx={{fontWeight:'600'}}>
          <Link to={GlobalRoutes.FORGOT_PASSWORD}>
          Forgot Password?</Link>
          </Typography>
      </Box>
    </>
  );
}

export default RegisterForm;
