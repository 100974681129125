import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTabBlock = styled(Box)`

@media(min-width:1200px){
    .MuiAccordionDetails-root p{
        width: calc(100% - 5px);
        margin-left: auto;
    }
}


 

`;