import React, { useEffect } from "react";
import VideoBanner from "../../components/VideoBanner";
import SignupSection from "../../components/SignupSection";
import TestimonialSlider from "../../components/TestimonialSlider";
import PetDetails from "../../components/PetDetails";
import GetInTouch from "../../components/GetInTouch";
import { Media } from "../../config/media";
import { getIsAuthenticated } from "../../utils/sessions";
import { Helmet } from "react-helmet-async";
import { Message } from "../../config/constants";
import { useGetPageContentQuery } from "../../redux/textEdit/editorApi";
import { useDispatch } from "react-redux";
import { addHomePageContent } from "../../redux/textEdit/editorSlice";
import AboutSection from "../../components/AboutSection";
import { GlobalRoutes } from "../../types";

function Home(): JSX.Element {
  const isAuthenticated = getIsAuthenticated();
  const dispatch = useDispatch();
  const { data, refetch } = useGetPageContentQuery('home');

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // If home page data found, set it to redux
    if (data) {
      dispatch(addHomePageContent(data?.data.home));
    }
  }, [data, dispatch]);

  return (
    <>
      <Helmet>
        <title>Stay Connected with Your Pet - Daily Text Messages from My Pets Text!</title>
      </Helmet>
      <VideoBanner />
      <AboutSection page="home" className1="introsection-style" sectionName="petsText" title="What is myPetsText!" graphiclg={Media.LogoGraphicImage} imgurl={Media.AboutImage} graphicsm={Media.AboutGraphiclgImagePrimarysm}/>
      {!isAuthenticated && <SignupSection />}
      <PetDetails page="home" sectionName="feature" />
      <TestimonialSlider ratings={true} maintitle="What The Pet Owners Say:" />
      <GetInTouch page="home" sectionName="cta" link={GlobalRoutes.PRICING} title="Get In Touch With Us!" subtitle="Email us at info@mypetstext.com" btntext="Join the Alpha Testing Now" />
    </>
  );
}

export default Home;
