
export type PricingItemsType = {
        title: string;
        price: number;
        priceSubtitle: string;
        features: string[]; 
        creditsText: string | null;
        description: string;
}
export type PricingsType = {
    annually: {
        [key: string]: PricingItemsType;
    }
    monthly:{
        [key: string]: PricingItemsType;
    } 
}

export const pricings: PricingsType = {
  annually: {
    freeAlphaTrial: {
      title: 'Free Alpha Trial',
      price: 0.00,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        'Receive 100 free credits. Enough for 1 text and image message every day for 30 days!',
        'Available by invitation only.',
      ],
      creditsText: 'You get 100 free credits.',
      description:
        'Join our Alpha Testing team for 30 days.  Provide us feedback on how to improve our messages and platform.',
    },
    starterPlan: {
      title: 'Starter Plan',
      price: 4.50,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        '30 credits/month',
        'Allows for 3 messages with text and images a week.',
        'Get a boost during the week.',
      ],
      creditsText: null,
      description:
        'Get personalized texts and images from your pet on your phone at least 3 times a week.',
    },
    roversPlan: {
      title: "Rover's Plan",
      price: 6.25,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        '50 credits/month',
        'Hear from your pet 5 days a week',
        "Great for workers that can't be remote",
      ],
      creditsText: null,
      description:
        'Get personalized texts and images from your pet on your phone at least 5 times a week.',
    },
    familyPlan: {
      title: 'Family Plan',
      price: 16.25,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        '150 credits/month',
        'Get 2 messages per day, 5 days a week',
        '4 family members can receive messages simultaneously',
      ],
      creditsText: null,
      description:
        'Share the love with your whole family. Multiple users can receive messages from the same pet.',
    },
  },
  monthly: {
    freeAlphaTrial: {
      title: 'Free Alpha Trial',
      price: 0.00,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        'Receive 100 free credits. Enough for 1 text and image message every day for 30 days!',
        'Available by invitation only.',
      ],
      creditsText: 'You get 100 free credits.',
      description:
        'Join our Alpha Testing team for 30 days.  Provide us feedback on how to improve our messages and platform.',
    },
    starterPlan: {
      title: 'Starter Plan',
      price: 4.95,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        '30 credits/month',
        'Allows for 3 messages with text and images a week.',
        'Get a boost during the week.',
      ],
      creditsText: null,
      description:
        'Get personalized texts and images from your pet on your phone at least 3 times a week.',
    },
    roversPlan: {
      title: "Rover's Plan",
      price: 7.50,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        '50 credits/month',
        'Hear from your pet 5 days a week',
        "Great for workers that can't be remote",
      ],
      creditsText: null,
      description:
        'Get personalized texts and images from your pet on your phone at least 5 times a week.',
    },
    familyPlan: {
      title: 'Family Plan',
      price: 19.50,
      priceSubtitle: 'per user/month, billed annually',
      features: [
        '150 credits/month',
        'Get 2 messages per day, 5 days a week',
        '4 family members can receive messages simultaneously',
      ],
      creditsText: null,
      description:
        'Share the love with your whole family. Multiple users can receive messages from the same pet.',
    },
  },
};
