import { FormHelperText } from "@mui/material";

type CustomHelperTextPropsType = {
  variant?: "error" | "default";
  title: string | undefined;
};
const CustomHelperText: React.FC<CustomHelperTextPropsType> = ({
  variant = "error",
  title,
}) => {
  return (
    <FormHelperText
      sx={{
        mx: "14px",
        color: variant === "error" ? "#FA3D3D" : "inherit",
      }}
    >
      {title || ''}
    </FormHelperText>
  );
};

export default CustomHelperText;
