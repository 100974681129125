import { Box, Typography } from "@mui/material";

const AboutSection: React.FC = () => {
  return (
    <Box sx={{
        textAlign: 'left', 
        py: 2, 
    }}>
        <Box>
          <Typography variant="h1" sx={{ mb: 2 }}>Tell us a little about yourself.</Typography>
          <Typography variant="caption" sx={{ mb: 2, display: "inline-block" }}>
            Let’s get started with some quick profile questions. Just fill in
            your basic subscriber info and pick the residential categories that
            best describe where you live. High rise apartment or two-story
            house? Bustling city or sleepy suburbia? Let us know so we don’t get
            mixed up and show your downtown dog chasing butterflies in the
            backyard!
          </Typography>
          <Typography variant="caption" sx={{ mb: 2, display: "inline-block" }}>
            Your detailed answers will be used only for customising every
            original My Pets Text that you receive. We will never sell or misuse
            your personal information.
          </Typography>
          <Typography variant="caption" sx={{ mb: 2, display: "inline-block" }}>
            *Get ready for a smile, a chuckle and a little extra love as you embark on a daily journey with My Pets Texts.
          </Typography>
        </Box>
    </Box>
  );
};

export default AboutSection;
