import React from "react";
import { Box,FormControl,TextField} from "@mui/material";

import { LoadingButton } from "@mui/lab";
function AddReviewForm() {
  return (
    <form autoComplete="off">
    <Box>

      {/* <FormControl
        sx={{ my: 1.5, width: "100%" }}
        variant="outlined"
      >
        <TextField
          fullWidth
          id="name"
          label="Your Location"
          variant="outlined"
          value={name}
        />
      </FormControl> */}
      <FormControl
        sx={{ my: 1.5, width: "100%" }}
        variant="outlined"
      >
        <TextField
     
          name="message"
          label="Description"
          placeholder="Enter Description"
          multiline
        
        />
      </FormControl>
      <Box sx={{textAlign:'right',mt:2}}>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Submit
                    </LoadingButton>
                  </Box>
    </Box>
  </form>
  );
}

export default AddReviewForm;
