export enum GlobalRoutes {
  HOME = "/",
  ABOUT = "/about-us",
  CONTACT = "/contact-us",
  LOGIN = "/login",
  FAQ = "/faqs",
  ACTIVATE_ACCOUNT = '/activate-account/:uidb64/:token', 
  REGISTER = "/register",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password/:uid/:tokenId",
  USER_PROFILE = "/user-profile",
  CHANGE_PASSWORD = "/change-password",
  BASICDETAILS = "/basic-details",
  TESTIMONIAL = "/testimonials",
  BASIC_DETAILS = "/basic-details",
  PET_DETAILS = "/pet-details/:petId",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_CONDITIONS = "/terms-conditions",
  MY_PETS = "/my-pets",
  ABOUT_YOURSELF = "/about-yourself",
  MY_ACCOUNT = "/my-account",
  CREATE_PROFILE = '/my-account/edit-profile', 
  ACCOUNT_PROFILE = "/my-account/profile",
  ACCOUNT_MY_PETS = "/my-account/pets",
  ACCCOUNT_SCHEDULE_MESSAGE = "/my-account/schedule-message",
  EDIT_SCHEDULE_MESSAGE = "/my-account/edit-schedule-message/:id",
  ADD_SCHEDULE_MESSAGE = "/my-account/add-schedule-message/:petId",
  EDIT_PET = '/my-account/edit-pet/:petId', 
  ACCOUNT_CREDITS = "/my-account/credits",
  EDIT_PROFILE = "/my-account/edit-profile",
  ADD_PET = "/my-account/add-pet",
  FEATURES = '/features', 
  HOWWORKS = '/how-works', 
  PRICING='/pricing',
  PET_GALLERY = '/pet-gallery',
  PET_DETAIL='/my-pets/detail/:id',
}
