import React from "react";
import { Box, Typography} from "@mui/material";
import { StyledImgContentBlock } from "./styles";

interface ITestimonialProps {
  classname?: string,
  imgurl: string,
  quoteimg: string,
  content: string,
  title: string,
  subtitle: string,
}
function ImgContentBlock({imgurl, quoteimg, content, title,subtitle,classname}:ITestimonialProps ) {
  return (
    <StyledImgContentBlock>
     <Box sx={{display:{md:'flex'},paddingBottom:'2rem',textAlign:{xs:'center',md:'left'}}} className={classname}>
         <Box
                component="img"
                alt="The shape"
                src={imgurl}
                className="img-style__contain testimonial__img"
              />
        <Box className="testimonial__content">
           <Box  sx={{display:'flex',position: 'relative'}}>
           <Box
                component="img"
                alt="quotes"
                src={quoteimg}
                className="img-style__contain testimonial__quote testimonial__quote--left"
              />
                <Typography
                sx={{color:'info.darker'}}
                variant="body1"
                mb={2}
              >
              {content}
              </Typography>
               <Box
                component="img"
                alt="quotes"
                src={quoteimg}
                className="img-style__contain testimonial__quote testimonial__quote--right"
              />
           </Box>
           <Typography
                sx={{color:'primary.main'}}
                variant="h3"
              >
              {title}
              </Typography>
              <Typography
               sx={{color:'info.darker'}}
                variant="body1"
                mb={3}
              >
              {subtitle}
              </Typography>
        </Box>
     </Box>
     </StyledImgContentBlock>
  );
}

export default ImgContentBlock;
