/* eslint-disable */
import React, { createContext, useMemo, useState } from 'react';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { darkPalette, DARK_MODE, lightPalette, LIGHT_MODE } from './palette';
import { CssBaseline } from '@mui/material';
import GlobalStyles from './globalStyles';
import typography from './typography';
import useCustomShadows from './customShadows';
import { PaletteOptions } from './interfaces/patellteOptions';
import { CustomShadowsOptions } from './interfaces/customShadowsOptions';
// import AppWrapper from '../helpers/AppWrapper';
import ComponentsOverrides from './overrides';

declare module '@mui/material/styles' {
  interface Theme {
    // breakpoints: BreakpointsOptions;
    breakpoints: any;
    shape: { borderRadius: number };
    customShadows: CustomShadowsOptions;
    palette: PaletteOptions;
    typography: any;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}


export function themeSettings(mode: any): any {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    shape: { borderRadius: 6 },
    customShadows: useCustomShadows(),
    palette: {
      mode: mode,
      ...({...lightPalette})
    },
    typography,
  };
}

interface ThemeProviderProps {
  children: JSX.Element;
}

function ThemeProvider({ children }: ThemeProviderProps) {

  const theme = createTheme(themeSettings('light'));
  theme.components = ComponentsOverrides(theme);

  return (
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
  );
}

export default ThemeProvider;
