import { appApi } from '../apis/apiSlice';

const faqsApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ['FAQS'],
});

export const faqsApi = faqsApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllFaqs: builder.query<any, any>({
      query: () => ({
        url: `/cms/faqs`,
        method: 'GET',
        refetchOnMountOrArgChange: true,
        provideTags: ['FAQS'],
      }),
    }),
  }),
});

export const { useFetchAllFaqsQuery } = faqsApi;
