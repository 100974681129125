import React from "react";
import ImageBanner from "../../components/ImageBanner";
import { Box, 
   Container,
    Typography,
  } from "@mui/material";
import { Media } from "../../config/media";
import ImgContentBlock from "../../components/ImgContentBlock";
import AddReviewForm from "../../components/AddReviewFrom";
import BasicModal from "../../components/Modal";
import { Helmet } from "react-helmet-async";

function Testimonial() {
  return (
    <>
    <Helmet><title>Testimonial | MypetsText</title></Helmet>
      <ImageBanner img={Media.TestimonialBannerImg}/>
      <Box className="sec-spacing">
      <Container fixed maxWidth="xl">
      <Typography
                sx={{textAlign:{xs:'center',md:'left'}}}
                variant="h1"
                mb={4}
              >
              Testimonials  from the pet owners
              </Typography>
              <ImgContentBlock quoteimg={Media.QuotesIcon} imgurl={Media.Profile} title="Kale Williams" subtitle="Lorem Ipsum" content="Lorem ipsum dolor sit amet consectetur. Cras egestas eu maecenas vivamus volutpat scelerisque. A ultrices nulla sed porta varius mollis. Sit sit lectus pellentesque pretium. Pellentesque et a vulputate quam purus leo augue. "/>
          </Container>
          <Box sx={{bgcolor:'secondary.light',position:'relative','&::before': {backgroundImage: 'url("/assets/images/top-curve.png")'},'&::after': {backgroundImage: 'url("/assets/images/bottom-curve.png")'}}} className="curve-sec testimonial-bg">
             <Container fixed maxWidth="xl">
              <ImgContentBlock classname="testimonial" quoteimg={Media.QuotesIconSecondary} imgurl={Media.Profile} title="Juli George" subtitle="Lorem Ipsum" content="Lorem ipsum dolor sit amet consectetur. Cras egestas eu maecenas vivamus volutpat scelerisque. A ultrices nulla sed porta varius mollis. Sit sit lectus pellentesque pretium. Pellentesque et a vulputate quam purus leo augue. "/>
              <ImgContentBlock quoteimg={Media.QuotesIcon} imgurl={Media.Profile} title="Kale Williams" subtitle="Lorem Ipsum" content="Lorem ipsum dolor sit amet consectetur. Cras egestas eu maecenas vivamus volutpat scelerisque. A ultrices nulla sed porta varius mollis. Sit sit lectus pellentesque pretium. Pellentesque et a vulputate quam purus leo augue. "/>
          </Container>
            </Box>
                <Container fixed maxWidth="xl">
              <ImgContentBlock classname="testimonial" quoteimg={Media.QuotesIconSecondary} imgurl={Media.Profile} title="Juli George" subtitle="Lorem Ipsum" content="Lorem ipsum dolor sit amet consectetur. Cras egestas eu maecenas vivamus volutpat scelerisque. A ultrices nulla sed porta varius mollis. Sit sit lectus pellentesque pretium. Pellentesque et a vulputate quam purus leo augue. "/>
             <Box sx={{textAlign:{xs:'center',md:'left'}}}>
                 <BasicModal btnTitle="Add a Review"><AddReviewForm/></BasicModal>
              </Box>
            </Container>
      </Box>
    </>
  );
}

export default Testimonial;
