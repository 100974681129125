import { createSlice } from "@reduxjs/toolkit";

export type ContentItemType = {
  section: string;
  content: string;
  active: boolean;
  created_at?: Date;
  updated_at?: Date;
};

export type TextEditSliceType = {
  editing: boolean;
  pageContent: {
    home: ContentItemType[];
    howItWorks: ContentItemType[];
    mypets: ContentItemType[];
    features: ContentItemType[];
    pricing: ContentItemType[];
    faq: ContentItemType[];
    about: ContentItemType[];
  };
};

const initialState: TextEditSliceType = {
  editing: false,
  pageContent: {
    home: [],
    howItWorks: [],
    mypets: [],
    features: [],
    pricing: [],
    faq: [],
    about: [],
  },
};

const textEditSlice = createSlice({
  name: "textEdit",
  initialState: initialState,
  reducers: {
    toggleEditing(state) {
      state.editing = !state.editing;
    },
    addHomePageContent(state, action) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          home: action.payload,
        },
      };
    },
    addHowItWorksPageContent(state, action) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          howItWorks: action.payload,
        },
      };
    },
    addMyPetsPageContent(state, action) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          mypets: action.payload,
        },
      };
    },
    addFeaturesPageContent(state, action) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          features: action.payload,
        },
      };
    },
    addPricingPageContent(state, action) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          pricing: action.payload,
        },
      };
    },
    addFaqPageContent(state, action) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          faq: action.payload,
        },
      };
    },
    addAboutPageContent(state: any, action: any) {
      return {
        ...state,
        pageContent: {
          ...state.pageContent,
          about: action.payload,
        },
      };
    },
    updatePageContent(state: any, action) {
      const { payload } = action;
      const plainState = JSON.parse(JSON.stringify(state));
      const updatedPageData = plainState.pageContent[payload.page].map((data: any) => {
        if(data.section === payload.section) {
          return {
            ...data, 
            content: payload.data.content, 
          }
        }
        return data;
      });
      state.pageContent[payload.page] = updatedPageData;
    }
  },
});

export const {
  toggleEditing,
  addAboutPageContent,
  addFaqPageContent,
  addHomePageContent,
  addHowItWorksPageContent,
  addFeaturesPageContent, 
  addPricingPageContent, 
  addMyPetsPageContent, 
  updatePageContent, 
} = textEditSlice.actions;
export default textEditSlice.reducer;
