import React from "react";
import ReactDOM from "react-dom/client";
import "./app.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import ThemeProvider from "./theme";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./redux/index";
import { HelmetProvider } from "react-helmet-async";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CustomToast from "./components/CustomToast";
//@ts-ignore
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
  gtmId: 'GTM-5XC4P6QP', 
}
 
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_KEY}`}
    >
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <ThemeProvider>
            <>
              <RouterProvider router={router} />
              {/* Showing MUI snackbar instead of toastify */}
              <CustomToast /> 
              <ToastContainer />
            </>
          </ThemeProvider>
        </HelmetProvider>
        </PersistGate>
      </ReduxProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
