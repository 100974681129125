import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAlertBlock = styled(Box)`
.MuiAlert-message{
    font-size: 22px;
}
.MuiAlert-icon{
    font-size: 30px;
}
.MuiSvgIcon-root{
    width: 1.5em;
    height: 1.5em;
}

.MuiPaper-elevation{
    align-items: center;
}

`;
