import React from "react";
import { GlobalStyles as MUIGlobalStyles } from "@mui/material";

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
     
      }}
    />
  );

  return inputGlobalStyles;
}
