import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { StyledAboutBlock } from "./styles";
import { visuallyHidden } from "@mui/utils";
import { GlobalRoutes } from "../../types/routes";
import { Link } from "react-router-dom";
import TextEdit from "../TextEdit";
import { useContent } from "../../hooks/useContent";
interface IAboutSectionProps {
  className1?: string;
  className?: string;
  imgurl: string;
  graphicsm: string;
  graphiclg: string;
  title: string;
  link?: string;
  readMore?: boolean;
  page?: string;
  sectionName?: string;
}
function AboutSection({
  className,
  title,
  graphiclg,
  graphicsm,
  imgurl,
  link,
  readMore,
  page,
  sectionName,
  className1,
}: IAboutSectionProps) {
  const _html = useContent(page as string, sectionName as string);
  return (
    <StyledAboutBlock component="section">
      <Box
        className={className}
        sx={{
          bgcolor: "primary.light",
          paddingTop: "4rem",
          paddingBottom: { xs: "2.5rem", md: "6rem" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Container fixed maxWidth="xl" className={className1}>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item md={6} xs={12} sx={{ position: "relative" }} className="h2-style">
              <Typography
                variant="h2"
                mb={3}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {title}
              </Typography>
              <Box
                component="img"
                alt="The shape"
                src={graphicsm}
                className="img-style__contain shape-top"
              />
              <Box
                sx={{ position: { xs: "static", md: "absolute" } }}
                component="img"
                alt="About image"
                src={imgurl}
                className="img-style img-style__contain img-absolute about-img"
              />
            </Grid>
            <Grid
              item
              md={6}
              pb={11}
              xs={12}
              sx={{ position: "relative", zIndex: "1" }}
            >
              <TextEdit page={page} sectionName={sectionName} _html={_html}>
                <>
                  <Typography
                    variant="h2"
                    mb={3}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingRight: { xs: "0", xl: "11.4rem" },
                      paddingBottom: { xs: "1rem" },
                      lineHeight: "1.6",
                    }}
                  >
                    We are based in Austin, Texas. We are a small team of
                    developers and designers who love building web applications.
                    We are always looking for new projects to work on. We are
                    based in Austin, Texas. We are a small team of developers
                    and designers who love building web applications. We are
                    always looking for new projects to work on. We are based in
                    Austin, Texas. We are a small team of developers and
                    designers who love building web applications. We are always
                    looking for new projects to work on.
                  </Typography>
                </>
              </TextEdit>
              <Link to={GlobalRoutes.FAQ} className="text-underline">
                {link}
              </Link>
              {readMore && (
                <Box sx={{ mt: { xs: 2, sm: 3, md: 4, lg: 5 } }}>
                  <Link to={GlobalRoutes.ABOUT} style={{ opacity: 1 }}>
                    <Button variant="contained">
                      Read more
                      <Box sx={visuallyHidden}>
                        about how to visually hide elements
                      </Box>
                    </Button>
                  </Link>
                </Box>
              )}
              <Box
                component="img"
                alt="The shape"
                src={graphiclg}
                className="img-style__contain shape-right"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </StyledAboutBlock>
  );
}

export default AboutSection;
