import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { StyledBlock} from "./styles";
import { GlobalRoutes } from "../../../types/routes";
import { MyPetConstants } from "../../../config/constants";
import SocialIcons from "../../../components/SocialIcons";
function Footer() {

  const sendMail = () => {
    const el = document.createElement("a");
    el.href = `mailto:${MyPetConstants.email}`;
    el.click();
  };


  return (
    <StyledBlock
    component="footer"
      sx={{ bgcolor: "primary.light", pt: 7 }}
      className="site-footer"
    >
      <Container fixed maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                paddingRight: { md: "4rem" },
                maxWidth: { xs: "10rem", md: "100%" },
                marginBottom: "1.5rem",
                marginLeft: { xs: "auto" },
                marginRight: { xs: "auto" },
              }}
            >
              <Link to="/">
                <Box
                  sx={{ display: { xs: "none", sm: "block" } }}
                  component="img"
                  className="img-style img-style__contain"
                  alt="The house from the offer."
                  src="/assets/icons/My-Pets-Text-Vertical.svg"
                />
                <Box
                  sx={{ display: { xs: "block", sm: "none" } }}
                  component="img"
                  className="img-style"
                  alt="The house from the offer."
                  src="/assets/icons/My-Pets-Text-Horizontal.svg"
                />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{marginLeft:'auto'}} className="footer-list">
            <Box>
              <Typography
                variant="h4"
                mb={3}
                sx={{
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Quick Links
              </Typography>
              <Box>
                <List
                  sx={{
                    listStylePosition: "inside",
                    py:0,
                  }}
                >
                  <ListItem
                    sx={{
                      display: "list-item",
                      pl: 0,
                      pt:0,
                      pb: "5px",
                      textAlign: { xs: "center", md: "left" },
                      fontSize: {
                        xs: "0.875rem",
                        sm: "1rem",
                        md: "1.0625rem",
                        lg: "1.125rem",
                      },
                    }}
                  >
                    <Link to={GlobalRoutes.TERMS_CONDITIONS}>
                      Terms & Conditions
                    </Link>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      pl: 0,
                      textAlign: { xs: "center", md: "left" },
                      fontSize: {
                        xs: "0.875rem",
                        sm: "1rem",
                        md: "1.0625rem",
                        lg: "1.125rem",
                      },
                    }}
                  >
                    <Link to={GlobalRoutes.PRIVACY_POLICY}>Privacy Policy</Link>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      pl: 0,
                      textAlign: { xs: "center", md: "left" },
                      fontSize: {
                        xs: "0.875rem",
                        sm: "1rem",
                        md: "1.0625rem",
                        lg: "1.125rem",
                      },
                    }}
                  >
                    <Link to={GlobalRoutes.CONTACT}>Contact Us</Link>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Typography
                variant="h4"
                mb={3}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                Reach us at
              </Typography>
              <Box>
                <Typography
                  variant="caption"
                  className="address"
                  sx={{
                    display: "block",
                    whiteSpace: "pre-wrap",
                    mb: 1,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                    <Box component="a" href={`tel:${MyPetConstants.phone}`}>{MyPetConstants.phone}</Box>
                   <br />
                  {MyPetConstants.address}
                </Typography>
                <Box onClick={sendMail} sx={{mt:2}}>
                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                      display: "block",
                      cursor: 'pointer'
                    }}
                  >
                    {MyPetConstants.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 6,
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            className="copy-right"
            pb={2}
            sx={{ order: { xs: "2", sm: "1" } }}
          >
            Copyright &#169;Nehmedia, Inc. {new Date().getFullYear()}.
          </Typography>
        <SocialIcons className="primary-icons"/>
        </Box>
      </Container>
    </StyledBlock>
  );
}

export default Footer;
